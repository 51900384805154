import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import {
  RightPanelModal,
  ModalContainer,
  ModalOverlay,
  OverlayContainer
} from "app/NativeComponents/common";
import {
  completePopSidePanel,
  popAllSidePanels,
  popSidePanel,
  completepopAllSidePanels,
  startRedirect,
  buildSidePanels,
  isMobilePhoneCheck,
  openUrl
} from "app/NativeActions";

import ListLibrary from "app/DealMachineCore/components/ListLibrary";
import EditList from "app/DealMachineCore/components/ListLibrary/EditList";
import NewList from "app/DealMachineCore/components/ListLibrary/NewList";
import ViewListHistoryItem from "app/DealMachineCore/components/ListLibrary/EditList/ViewListHistoryItem";

import ManuallyAddProperty from "app/DealMachineCore/components/ManuallyAddProperty";
import AdvancedFilters from "app/DealMachineCore/components/AdvancedFilters";
import LeadStatuses from "app/DealMachineCore/components/LeadStatuses";
import Lists from "app/DealMachineCore/components/Lists";
import Tags from "app/DealMachineCore/components/Tags";
import LeadOptions from "app/DealMachineCore/components/LeadOptions";
import LeadOwnerModal from "app/DealMachineCore/components/LeadOwnerModal";

import Tasks from "app/DealMachineCore/components/Tasks";
import TaskCreate from "app/DealMachineCore/components/Tasks/TaskCreate";
import TaskEdit from "app/DealMachineCore/components/Tasks/TaskEdit";

import SkipTraceModal from "app/DealMachineCore/components/SkipTraceModal";
import StartMailerCampaignModal from "app/DealMachineCore/components/StartMailerCampaignModal";
import ImportList from "app/DealMachineCore/components/ImportList";
import ExportLeads from "app/DealMachineCore/components/ExportLeads";
import ListBuilder from "app/DealMachineCore/components/ListBuilder";
import ListStackSettings from "app/DealMachineCore/components/ListStackSettings";
import LevelSettings from "app/DealMachineCore/components/ListStackSettings/LevelSettings";
import Settings from "app/DealMachineCore/components/Settings";
import HelpCenter from "app/DealMachineCore/components/HelpCenter";
import EditProfile from "app/DealMachineCore/components/Settings/EditProfile";
import EditBasicProfileInformation from "app/DealMachineCore/components/Settings/EditProfile/EditBasicProfileInformation";
import EditUserPhoneNumber from "app/DealMachineCore/components/Settings/EditProfile/EditUserPhoneNumber";
import EditUserEmailAddress from "app/DealMachineCore/components/Settings/EditProfile/EditUserEmailAddress";
import EditUserPassword from "app/DealMachineCore/components/Settings/EditProfile/EditUserPassword";
import VerifyEmail from "app/DealMachineCore/components/LockOutPages/VerifyEmail";
import VerifyUseDifferentEmail from "app/DealMachineCore/components/LockOutPages/VerifyEmail/ChangeEmail";
import VerifyPhone from "app/DealMachineCore/components/LockOutPages/VerifyPhone";
import VerifyUseDifferentPhone from "app/DealMachineCore/components/LockOutPages/VerifyPhone/ChangePhone";
import AddMarketingCredits from "app/DealMachineCore/components/Settings/AddMarketingCredits";
import MailQueue from "app/DealMachineCore/components/MailQueue";
import PlanSettings from "app/DealMachineCore/components/Settings/PlanSettings";
import DialerLicenses from "app/DealMachineCore/components/Settings/PlanSettings/DialerLicenses";
import BuyDialerLicense from "app/DealMachineCore/components/Settings/PlanSettings/DialerLicenses/BuyDialerLicense";
import BuyVoicemailLicense from "app/DealMachineCore/components/Settings/PlanSettings/DialerLicenses/BuyVoicemailLicense";
import EditDialerLicense from "app/DealMachineCore/components/Settings/PlanSettings/DialerLicenses/EditDialerLicense";

import TrainingVideosBeta from "app/DealMachineCore/components/Settings/MoreSettings/TrainingVideos";

import BillingSettings from "app/DealMachineCore/components/Settings/BillingSettings";
import MarketingInvoice from "app/DealMachineCore/components/Settings/BillingSettings/MarketingCredits";
import AppSettings from "app/DealMachineCore/components/Settings/AppSettings";
import BasicAutomation from "app/DealMachineCore/components/Settings/AppSettings/BasicAutomation";
import DialerSettings from "app/DealMachineCore/components/Settings/AppSettings/DialerSettings";
import CallTracking from "app/DealMachineCore/components/Settings/AppSettings/CallTracking";
import FollowUpQueue from "app/DealMachineCore/components/Dialer/FollowUpQueue";
import API from "app/DealMachineCore/components/Settings/AppSettings/API";
import CustomStatuses from "app/DealMachineCore/components/Settings/AppSettings/CustomStatuses";
import NewLeadStatus from "app/DealMachineCore/components/Settings/AppSettings/CustomStatuses/NewLeadStatus";
import CustomFields from "app/DealMachineCore/components/Settings/AppSettings/CustomFields";
import NewCustomFieldGroup from "app/DealMachineCore/components/Settings/AppSettings/CustomFields/NewCustomFieldGroup";
import NotificationSettings from "app/DealMachineCore/components/Settings/AppSettings/Notifications";

import AccountActivity from "app/DealMachineCore/components/AccountActivity";
import ActivityPropertyList from "app/DealMachineCore/components/ActivityPropertyList";
import MoreSettings from "app/DealMachineCore/components/Settings/MoreSettings";
import EnterPromo from "app/DealMachineCore/components/Settings/MoreSettings/EnterPromo";

import Team from "app/DealMachineCore/components/Settings/Team";
import InviteByEmail from "app/DealMachineCore/components/Settings/Team/InviteByEmail";
import TeamMemberPage from "app/DealMachineCore/components/Settings/Team/TeamMemberPage";

import ManageLeadTags from "app/DealMachineCore/components/Settings/AppSettings/ManageLeadTags";
import NewTag from "app/DealMachineCore/components/Settings/AppSettings/ManageLeadTags/NewTag";

import ManageAutoReload from "app/DealMachineCore/components/Settings/BillingSettings/ManageAutoReload";
import ManagePaymentMethods from "app/DealMachineCore/components/Settings/BillingSettings/ManagePaymentMethods";
import CardOnFile from "app/DealMachineCore/components/Settings/BillingSettings/ManagePaymentMethods/CardOnFile";

import Invoices from "app/DealMachineCore/components/Settings/BillingSettings/Invoices";
import MarketingCredits from "app/DealMachineCore/components/Settings/BillingSettings/MarketingCredits";
import RecruitDrivers from "app/DealMachineCore/components/Settings/Team/RecruitDrivers";
import PhotoGallery from "app/DealMachineCore/components/PhotoGallery";
import Property from "app/DealMachineCore/components/Property";
import Contact from "app/DealMachineCore/components/Contact";
import Company from "app/DealMachineCore/components/Company";
import RealEstatePortfolio from "app/DealMachineCore/components/Contact/RealEstatePortfolio";

import Highlights from "app/DealMachineCore/components/MainMap/MapSidePanel/Highlights";
import SavedSearches from "app/DealMachineCore/components/MainMap/MapSidePanel/SavedSearches";
import SavedSearch from "app/DealMachineCore/components/MainMap/MapSidePanel/SavedSearches/SavedSearch";

import Street from "app/DealMachineCore/components/MainMap/MapSidePanel/Street";
import Routes from "app/DealMachineCore/components/MainMap/MapSidePanel/Routes";
import MapOptions from "app/DealMachineCore/components/MainMap/MapSidePanel/MapOptions";
import ViewRoute from "app/DealMachineCore/components/MainMap/MapSidePanel/Routes/ViewRoute";

import EditOwnerMailingAddress from "app/DealMachineCore/components/Property/OwnerInfo/MailingAddresses/EditOwnerMailingAddress";
import EditOwnerName from "app/DealMachineCore/components/Property/OwnerInfo/MailingAddresses/EditOwnerName";
import EditMailingAddress from "app/DealMachineCore/components/Property/OwnerInfo/MailingAddresses/EditMailingAddress";
import EditPhoneNumber from "app/DealMachineCore/components/Property/OwnerInfo/PhoneNumbers/EditPhoneNumber";
import EditEmailAddress from "app/DealMachineCore/components/Property/OwnerInfo/EmailAddresses/EditEmailAddress";

import AddMailingAddress from "app/DealMachineCore/components/Property/OwnerInfo/MailingAddresses/AddMailingAddress";
import AddPhoneNumber from "app/DealMachineCore/components/Property/OwnerInfo/PhoneNumbers/AddPhoneNumber";
import AddEmailAddress from "app/DealMachineCore/components/Property/OwnerInfo/EmailAddresses/AddEmailAddress";

import AddNote from "app/DealMachineCore/components/Property/Activity/AddNote";
import EditNote from "app/DealMachineCore/components/Property/Activity/EditNote";

import FilterComps from "app/DealMachineCore/components/Property/Comps/FilterComps";
import CompsDefaultSettings from "app/DealMachineCore/components/Property/Comps/CompsDefaultSettings";
import ExpandCompsProperties from "app/DealMachineCore/components/Property/Comps/ExpandCompsProperties";
import EditPropertyAddressDisplay from "app/DealMachineCore/components/Property/EditPropertyAddressDisplay";

import ReviewAllLeadDataUpdates from "app/DealMachineCore/components/ReviewAllLeadDataUpdates";
import ViewLeadDataUpdates from "app/DealMachineCore/components/ReviewAllLeadDataUpdates/ViewLeadDataUpdates";

import UnlockFeatureModal from "app/DealMachineCore/components/UpsellModal/UnlockFeatureModal";
import IAPPaywall from "app/NativeComponents/components/IAPPaywall";

import FixCardForMarketingCredits from "app/DealMachineCore/components/LockOutPages/FixCardForMarketingCredits";
import AcceptTerms from "app/DealMachineCore/components/LockOutPages/AcceptTerms";
import AcceptAffiliateTerms from "app/DealMachineCore/components/LockOutPages/AcceptAffiliateTerms";
import ShareAndEarn from "app/DealMachineCore/components/ShareAndEarn";
import LinkPropertyWithData from "app/DealMachineCore/components/LinkPropertyWithData";

import FilterMailCenter from "app/DealMachineCore/components/Mail/MailCenter/FilterMailCenter";
import TrackMail from "app/DealMachineCore/components/Mail/TrackMail";

import NewMailSequence from "app/DealMachineCore/components/Mail/MailSequences/NewMailSequence";
import ViewMailSequence from "app/DealMachineCore/components/Mail/MailSequences/ViewMailSequence";

import PostcardDesignerWidget from "app/DealMachineCore/components/Mail/PostcardDesigner/PostcardDesignerWidget";
import ImportDesign from "app/DealMachineCore/components/Mail/PostcardDesigner/PostcardDesignerWidget/MailerDesigner/ImportDesign";
import ExportDesign from "app/DealMachineCore/components/Mail/PostcardDesigner/PostcardDesignerWidget/MailerDesigner/ExportDesign";
import BypassTrial from "app/DealMachineCore/components/BypassTrial";

import ViewLeads from "app/DealMachineCore/components/ViewLeads";
import CreateAnAccount from "app/DealMachineCore/components/CreateAnAccount";
import SignatureOnboarding from "app/DealMachineCore/components/OnboardingPages/SignatureOnboarding";
import CreditCardOnboarding from "app/DealMachineCore/components/OnboardingPages/CreditCardOnboarding";
import StripeUpgrades from "app/DealMachineCore/components/OnboardingPages/StripeUpgrades";
import PlanDetails from "app/DealMachineCore/components/Settings/PlanSettings/PlanDetails";

import AnalyticsFilters from "app/DealMachineCore/components/AnalyticsFilters";
import Post from "app/DealMachineCore/components/Learn/Community/Post";
import CreatePost from "app/NativeComponents/components/CreatePost";
import MailSettings from "app/DealMachineCore/components/Settings/AppSettings/MailSettings";
import ForgotPassword from "app/DealMachineCore/components/Login/ForgotPassword";

import SelectDesignModal from "app/DealMachineCore/components/SelectDesignModal";
import WorkflowDesignerModal from "app/DealMachineCore/components/Workflows/WorkflowDesignerModal";

import PersonSearch from "app/DealMachineCore/components/PersonSearch";
import CallSession from "app/DealMachineCore/components/CallSession";
import CallLog from "app/DealMachineCore/components/CallLog";
import AddCallTrackingNumber from "app/DealMachineCore/components/Settings/AppSettings/CallTracking/AddCallTrackingNumber";
import EditCallTrackingNumber from "app/DealMachineCore/components/Settings/AppSettings/CallTracking/EditCallTrackingNumber";
import PurchasePlanPrompt from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/PurchasePlanPrompt";
import AddCardPrompt from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/AddCardPrompt";

//workspaces
import AddNewTeam from "app/Workspaces/components/WorkspacesDashboard/WorkspaceManageTeams/AddNewTeam";
import EditWorkspaceTeam from "app/Workspaces/components/WorkspacesDashboard/WorkspaceManageTeams/EditWorkspaceTeam";
import ManageWorkspaceTeamLicenses from "app/Workspaces/components/WorkspacesDashboard/WorkspaceManageTeams/ManageWorkspaceTeamLicenses";
import ManageWorkspaceTeamBudget from "app/Workspaces/components/WorkspacesDashboard/WorkspaceManageTeams/ManageWorkspaceTeamBudget";
import WorkspaceAppSettings from "app/DealMachineCore/components/Settings/WorkspaceAdminSettings/WorkspaceAppSettings";
import GlobalPostcards from "app/DealMachineCore/components/Settings/WorkspaceAdminSettings/GlobalPostcards";

class PushPopSidePanel extends Component {
  constructor(props) {
    super(props);
    this.renderPanel = this.renderPanel.bind(this);
    this.buildPanel = this.buildPanel.bind(this);
    this.getDataFromUrl = this.getDataFromUrl.bind(this);
  }

  getDataFromUrl(url) {
    // Extract everything after the hash #
    const hashPart = url.split("#")[1];
    if (!hashPart) return null;

    // Split by '~' to get each segment as a separate object
    const segments = hashPart.split("~");

    // Process each segment to extract the object, id, and extras
    const parsedObjects = segments.map(segment => {
      // Split by '!' to separate main part and extras
      const [mainPart, ...extraParts] = segment.split("!");
      const extras = extraParts.length ? extraParts : [];

      // Modify the regular expression to include hyphens in the object name
      const objectMatch = mainPart.match(/([a-zA-Z0-9-_]+)(?:\(id\)=([\d]+))?/);
      if (!objectMatch) return null;

      const object = objectMatch[1]; // The object name, allowing hyphens
      const id = objectMatch[2] || null; // The id, or null if not present

      return { object, id, extras };
    });

    return parsedObjects;
  }

  componentDidMount() {
    this.buildPanel();
    window.addEventListener("popstate", () => {
      this.buildPanel();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      token,
      user,
      start_pop_side_panel,
      start_pop_all_side_panel,
      side_panel_views
    } = this.props;
    if (token !== prevProps.token || user?.id !== prevProps.user?.id) {
      this.buildPanel();
    }
    if (
      start_pop_side_panel &&
      start_pop_side_panel !== prevProps.start_pop_side_panel
    ) {
      clearInterval(this._interval);

      if (
        side_panel_views.length > 0 &&
        side_panel_views[side_panel_views.length - 1].expanded_push
      ) {
        if (this.props.popSidePanelSuccess) {
          this.props.popSidePanelSuccess();
        }
        this.props.completePopSidePanel();
      } else {
        this._interval = setTimeout(() => {
          if (this.props.popSidePanelSuccess) {
            this.props.popSidePanelSuccess();
          }
          this.props.completePopSidePanel();
        }, 500);
      }
    }

    if (
      start_pop_all_side_panel &&
      start_pop_all_side_panel !== prevProps.start_pop_all_side_panel
    ) {
      clearInterval(this._pop_all_interval);
      this._pop_all_interval = setTimeout(() => {
        if (this.props.popAllSidePanelSuccess) {
          this.props.popAllSidePanelSuccess();
        }
        this.props.completepopAllSidePanels();
      }, 500);
    }

    if (
      JSON.stringify(side_panel_views) !==
      JSON.stringify(prevProps.side_panel_views)
    ) {
      let url_path_string = "";
      if (side_panel_views.length > 0) {
        for (let i = 0; i < side_panel_views.length; i++) {
          const url_path_object = side_panel_views[i].slug.replace(/_/g, "-");

          if (!!url_path_string) {
            url_path_string += "~" + url_path_object;
          } else {
            url_path_string = "#" + url_path_object;
          }
          if (side_panel_views[i].id) {
            url_path_string += "(id)=" + side_panel_views[i].id;
          }
          if (side_panel_views[i].overlay) {
            url_path_string += "!o";
          }
          if (side_panel_views[i].overlay_modal) {
            url_path_string += "!om";
          }
          if (side_panel_views[i].focus_modal) {
            url_path_string += "!fm";
          }
          if (side_panel_views[i].focus_side_panel) {
            url_path_string += "!fsp";
          }
          if (side_panel_views[i].expanded_side_panel) {
            url_path_string += "!esp";
          }
          if (side_panel_views[i].content_side_panel) {
            url_path_string += "!csp";
          }
          if (side_panel_views[i].locked) {
            url_path_string += "!l";
          }
        }

        if (
          url_path_string.length > 0 &&
          window?.location?.pathname?.length > 0
        ) {
          const search = window?.location?.search || "";
          const path = window?.location?.pathname + url_path_string + search;
          if (window?.location?.href !== window?.location.origin + path) {
            window.history.pushState(null, "", path);
          }
        }
      } else {
        const router_redirect_string =
          window?.location?.href.split("#")[1] || "";
        const search = window?.location?.search || "";
        const path = window?.location?.pathname + search;

        if (
          !!router_redirect_string &&
          window?.location.origin + path !== window?.location?.href
        ) {
          window.history.pushState(null, "", path);
        }
      }
    }
  }

  buildPanel() {
    if (
      !!this.props?.user &&
      !!this.props?.token &&
      this.props.user.finished_onboarding == 1 &&
      this.props.user.canceled == 0 &&
      !isMobilePhoneCheck()
    ) {
      let panels = [];

      // Handle deep links...
      const deep_link = this.getDataFromUrl(window?.location?.href);

      if (deep_link && deep_link.length > 0) {
        for (let i = 0; i < deep_link.length; i++) {
          const dl = deep_link[i];
          if (!!dl.object) {
            // Examples of deep link object values: "property", etc.:
            panels.push({
              slug: dl.object.replace(/-/g, "_"),
              id: !!dl.id ? dl.id : null,
              overlay:
                dl.object === "property" ||
                dl.object === "contact" ||
                dl.object === "company"
                  ? true
                  : dl.extras.includes("o"),
              overlay_modal: dl.extras.includes("om"),
              focus_modal: dl.extras.includes("fm"),
              focus_side_panel:
                dl.object === "property" ? true : dl.extras.includes("fsp"),
              expanded_side_panel: dl.extras.includes("esp"),
              content_side_panel: dl.extras.includes("csp"),
              locked: dl.extras.includes("l"),
              data: {
                expanded: true
              }
            });
          }
        }

        this.props.buildSidePanels(panels);
      }
    } else if (isMobilePhoneCheck()) {
      const deep_link = this.getDataFromUrl(window?.location?.href);

      if (!!deep_link?.object) {
        // Use overlay for "property" deep link objects (if unspecified), and default to overlay for others, if unspecified:
        if (deep_link.object === "property") {
          openUrl("dealmachine://property/" + deep_link.valueOf("value"), true);
        } else if (deep_link.object === "contact") {
          openUrl("dealmachine://contact/" + deep_link.valueOf("value"), true);
        } else if (deep_link.object === "company") {
          openUrl("dealmachine://company/" + deep_link.valueOf("value"), true);
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval);
    clearInterval(this._pop_all_interval);
  }
  renderView(item, index) {
    switch (item.slug) {
      default:
        break;
      case "create_an_account":
        return (
          <CreateAnAccount
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "forgot_password":
        return (
          <ForgotPassword
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "signature_onboarding":
        return (
          <SignatureOnboarding
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "stripe_upgrades":
        return (
          <StripeUpgrades
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "list_library":
        return (
          <ListLibrary
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_list":
        return (
          <EditList
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "new_list":
        return (
          <NewList
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "view_list_history_item":
        return (
          <ViewListHistoryItem
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "manually_add_lead":
        return (
          <ManuallyAddProperty
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "advanced_filters":
        return (
          <AdvancedFilters
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "lead_statuses":
        return (
          <LeadStatuses
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "lists":
        return (
          <Lists
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "tags":
        return (
          <Tags
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "lead_options":
        return (
          <LeadOptions
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "lead_owner":
        return (
          <LeadOwnerModal
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "tasks":
        return (
          <Tasks
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "create_task":
        return (
          <TaskCreate
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_task":
        return (
          <TaskEdit
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "skip_trace":
        return (
          <SkipTraceModal
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "start_mail_campaign":
        return (
          <StartMailerCampaignModal
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "import_list":
        return (
          <ImportList
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "export_leads":
        return (
          <ExportLeads
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "list_builder":
        return (
          <ListBuilder
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "list_stack_settings":
        return (
          <ListStackSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "list_stack_level_settings":
        return (
          <LevelSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "settings":
        return (
          <Settings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "notifications":
        return (
          <AccountActivity
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "activity_property_list":
        return (
          <ActivityPropertyList
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "help_center":
        return (
          <HelpCenter
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_profile":
        return (
          <EditProfile
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_basic_profile_information":
        return (
          <EditBasicProfileInformation
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_user_phone_number":
        return (
          <EditUserPhoneNumber
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_user_email_address":
        return (
          <EditUserEmailAddress
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_user_password":
        return (
          <EditUserPassword
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "verify_email":
        return (
          <VerifyEmail
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "verify_use_different_email":
        return (
          <VerifyUseDifferentEmail
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "verify_phone":
        return (
          <VerifyPhone
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "verify_use_different_phone":
        return (
          <VerifyUseDifferentPhone
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "add_marketing_credits":
        return (
          <AddMarketingCredits
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "mail_queue":
        return (
          <MailQueue
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "plan_settings":
        return (
          <PlanSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "plan_details":
        return (
          <PlanDetails
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "billing_settings":
        return (
          <BillingSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "marketing_invoice":
        return (
          <MarketingInvoice
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "marketing_credits":
        return (
          <MarketingCredits
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "app_settings":
        return (
          <AppSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "mail_settings":
        return (
          <MailSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "more_settings":
        return (
          <MoreSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "team":
        return (
          <Team
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "manage_lead_tags":
        return (
          <ManageLeadTags
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "new_tag":
        return (
          <NewTag
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "basic_automation":
        return (
          <BasicAutomation
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "dialer_settings":
        return (
          <DialerSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "dialer_licenses":
        return (
          <DialerLicenses
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "buy_dialer_license":
        return (
          <BuyDialerLicense
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "buy_voicemail_license":
        return (
          <BuyVoicemailLicense
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "edit_dialer_license":
        return (
          <EditDialerLicense
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "call_tracking":
        return (
          <CallTracking
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "create_call_tracking_number":
        return (
          <AddCallTrackingNumber
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "edit_call_tracking_number":
        return (
          <EditCallTrackingNumber
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "follow_up_queue":
        return (
          <FollowUpQueue
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "notification_settings":
        return (
          <NotificationSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "custom_statuses":
        return (
          <CustomStatuses
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "custom_fields":
        return (
          <CustomFields
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "new_lead_status":
        return (
          <NewLeadStatus
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "new_custom_field_group":
        return (
          <NewCustomFieldGroup
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "enter_promo_code":
        return (
          <EnterPromo
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "training_videos":
        return (
          <TrainingVideosBeta
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "manage_auto_reload":
        return (
          <ManageAutoReload
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "manage_payment_methods":
        return (
          <ManagePaymentMethods
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "add_card":
        return (
          <CardOnFile
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "invoices":
        return (
          <Invoices
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "invite_by_email":
        return (
          <InviteByEmail
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "team_member_page":
        return (
          <TeamMemberPage
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "recruit_drivers":
        return (
          <RecruitDrivers
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "photo_gallery":
        return (
          <PhotoGallery
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "property":
        return (
          <Property
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "contact":
        return (
          <Contact
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "company":
        return (
          <Company
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "real_estate_portfolio":
        return (
          <RealEstatePortfolio
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "street_view":
        return (
          <Street
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "routes":
        return (
          <Routes
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "view_route":
        return (
          <ViewRoute
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "map_options":
        return (
          <MapOptions
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "saved_searches":
        return (
          <SavedSearches
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "saved_search":
        return (
          <SavedSearch
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "more_filters":
      case "highlights":
        return (
          <Highlights
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_owner_address":
        return (
          <EditOwnerMailingAddress
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_owner_name":
        return (
          <EditOwnerName
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_mailing_address":
        return (
          <EditMailingAddress
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_phone_number":
        return (
          <EditPhoneNumber
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_email_address":
        return (
          <EditEmailAddress
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "add_mailing_address":
        return (
          <AddMailingAddress
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "add_phone_number":
        return (
          <AddPhoneNumber
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "add_email_address":
        return (
          <AddEmailAddress
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "add_note":
        return (
          <AddNote
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_note":
        return (
          <EditNote
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "api":
        return (
          <API
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "filter_comps":
        return (
          <FilterComps
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "comps_default_settings":
        return (
          <CompsDefaultSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "show_comps":
        return (
          <ExpandCompsProperties
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_property_address_display":
        return (
          <EditPropertyAddressDisplay
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "review_all_lead_data_updates":
        return (
          <ReviewAllLeadDataUpdates
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "view_lead_data_updates":
        return (
          <ViewLeadDataUpdates
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "unlock_feature_modal":
        return (
          <UnlockFeatureModal
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "start_trial":
        return (
          <IAPPaywall
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "fix_card_for_marketing_credits":
        return (
          <FixCardForMarketingCredits
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "accepted_latest_terms":
        return (
          <AcceptTerms
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "accepted_latest_affiliate_terms":
        return (
          <AcceptAffiliateTerms
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "bad_card":
        return (
          <ManagePaymentMethods
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "share_and_earn":
        return (
          <ShareAndEarn
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "link_lead_with_data":
        return (
          <LinkPropertyWithData
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "filter_mail_center":
        return (
          <FilterMailCenter
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "track_mail":
        return (
          <TrackMail
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "new_mail_sequence":
        return (
          <NewMailSequence
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "view_mail_sequence":
        return (
          <ViewMailSequence
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "postcard_designer_widget":
        return (
          <PostcardDesignerWidget
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "import_design":
        return (
          <ImportDesign
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "export_design":
        return (
          <ExportDesign
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "end_trial":
        return (
          <BypassTrial
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "view_leads":
        return (
          <ViewLeads
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "analytics_filters":
        return (
          <AnalyticsFilters
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "post":
        return (
          <Post
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "create_post":
        return (
          <CreatePost
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "select_design_modal":
        return (
          <SelectDesignModal
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "workflow_designer":
        return (
          <WorkflowDesignerModal
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "person_search":
        return (
          <PersonSearch
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "call_session":
        return (
          <CallSession
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "call_log":
        return (
          <CallLog
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "purchase_plan_prompt":
        return (
          <PurchasePlanPrompt
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
            no_special={true}
            no_free={true}
          />
        );

      case "add_card_prompt":
      case "credit_card_onboarding":
        return (
          <AddCardPrompt
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
        break;

      case "blackfridayspecial":
        return (
          <PurchasePlanPrompt
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
            special="blackfriday2024"
          />
        );

      case "add_new_team":
        return (
          <AddNewTeam
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
      case "edit_team":
        return (
          <EditWorkspaceTeam
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "manage_licenses":
        return (
          <ManageWorkspaceTeamLicenses
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "manage_budget":
        return (
          <ManageWorkspaceTeamBudget
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "admin_app_settings":
        return (
          <WorkspaceAppSettings
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );

      case "global_postcards":
        return (
          <GlobalPostcards
            panel_data={item?.data}
            panel_id={item?.id}
            panel_index={index}
          />
        );
    }
  }

  renderPanel({ item, i, next_item_expanded = false }) {
    const {
      colors,
      isMobile,
      start_pop_side_panel,
      start_pop_all_side_panel,
      side_panel_views
    } = this.props;

    let is_locked = false;
    for (let i = 0; i < side_panel_views.length; i++) {
      if (side_panel_views[i].locked) {
        is_locked = true;
        break;
      }
    }
    let last_item_is_expanded_side_panel =
      side_panel_views.length > 0
        ? side_panel_views[side_panel_views.length - 1]?.expanded_side_panel
        : false;

    if (item.overlay_modal) {
      return (
        <OverlayContainer
          no_overlay={true}
          key={"slide_out_view_" + i}
          onPress={
            !is_locked
              ? () => {
                  if (last_item_is_expanded_side_panel) {
                    this.props.popSidePanel();
                  } else {
                    this.props.popAllSidePanels();
                  }
                }
              : () => {}
          }
          animation_type={
            (i == side_panel_views.length - 1 && start_pop_side_panel) ||
            start_pop_all_side_panel
              ? "fadeOut"
              : "fadeIn"
          }
          style={
            isMobile
              ? {
                  maxWidth: 1200,
                  width: "100%",
                  overflow: "hidden",
                  height: "100%",
                  display: "flex",
                  backgroundColor: colors.card_color
                }
              : {
                  maxWidth: 1200,
                  width: "95vw",
                  overflow: "hidden",
                  height: "95vh",
                  display: "flex",
                  backgroundColor: colors.card_color
                }
          }
        >
          {this.renderView(item, i)}
        </OverlayContainer>
      );
    } else if (item.focus_modal) {
      return (
        <ModalContainer
          no_overlay={true}
          key={"slide_out_view_" + i}
          animation_type={
            (i == side_panel_views.length - 1 && start_pop_side_panel) ||
            start_pop_all_side_panel
              ? "fadeOutDown"
              : "fadeInUp"
          }
          style={
            isMobile
              ? {
                  maxWidth: 1200,
                  width: "100%",
                  overflow: "hidden",
                  height: "100%",
                  display: "flex",
                  backgroundColor: colors.card_color
                }
              : {
                  maxWidth: 1200,
                  width: "95vw",
                  overflow: "hidden",
                  height: "95vh",
                  display: "flex",
                  backgroundColor: colors.card_color
                }
          }
        >
          {this.renderView(item, i)}
        </ModalContainer>
      );
    } else if (item.focus_side_panel) {
      return (
        <RightPanelModal
          className="deal-right-panel-container-new focus"
          key={"slide_out_view_" + i}
          animation_type={
            item.expanded_push
              ? ""
              : (i == side_panel_views.length - 1 && start_pop_side_panel) ||
                start_pop_all_side_panel
              ? "slideOutRight"
              : "slideInRight"
          }
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: colors.card_color,
            maxWidth: next_item_expanded ? 900 : 1200,
            width: "90%"
          }}
          containerStyle={next_item_expanded ? { right: 500 } : {}}
          maxWidth={next_item_expanded ? 900 : 1200}
          width={next_item_expanded ? 900 : "90%"}
        >
          {this.renderView(item, i)}
        </RightPanelModal>
      );
    } else if (item.expanded_side_panel) {
      return (
        <RightPanelModal
          className="deal-right-panel-container-new expanded-side-panel"
          key={"slide_out_view_" + i}
          animation_type={
            item.expanded_push
              ? ""
              : (i == side_panel_views.length - 1 && start_pop_side_panel) ||
                start_pop_all_side_panel
              ? "slideOutRight"
              : "slideInRight"
          }
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: colors.card_color,
            maxWidth: 750,
            width: "90%"
          }}
          containerStyle={{}}
          maxWidth={750}
          width={750}
        >
          {this.renderView(item, i)}
        </RightPanelModal>
      );
    } else if (item.content_side_panel) {
      return (
        <RightPanelModal
          className="deal-right-panel-container-new content"
          key={"slide_out_view_" + i}
          animation_type={
            item.expanded_push
              ? ""
              : (i == side_panel_views.length - 1 && start_pop_side_panel) ||
                start_pop_all_side_panel
              ? "slideOutRight"
              : "slideInRight"
          }
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: colors.card_color,
            maxWidth: 767
          }}
          width={767}
        >
          {this.renderView(item, i)}
        </RightPanelModal>
      );
    } else {
      return (
        <RightPanelModal
          className="deal-right-panel-container-new"
          key={"slide_out_view_" + i}
          animation_type={
            item.expanded_push
              ? ""
              : (i == side_panel_views.length - 1 && start_pop_side_panel) ||
                start_pop_all_side_panel
              ? "slideOutRight"
              : "slideInRight"
          }
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: colors.card_color,
            maxWidth: next_item_expanded ? 400 : "100%"
          }}
          width={next_item_expanded ? 400 : "100%"}
          containerStyle={next_item_expanded ? { right: 500 } : {}}
        >
          {this.renderView(item, i)}
        </RightPanelModal>
      );
    }
  }

  render() {
    const {
      colors,
      start_pop_side_panel,
      start_pop_all_side_panel,
      side_panel_views
    } = this.props;

    /*
        for (let i = 0; i < side_panel_views.length; i++) {
          if (side_panel_views[i].overlay) {
            show_overlay = true;
            break;
          }
        }
        */
    let show_overlay = false;

    let is_locked = false;
    for (let i = 0; i < side_panel_views.length; i++) {
      if (side_panel_views[i].locked) {
        is_locked = true;
        break;
      }
    }

    let last_item_is_expanded_side_panel =
      side_panel_views.length > 0
        ? side_panel_views[side_panel_views.length - 1]?.expanded_side_panel
        : false;

    return side_panel_views.map((item, i) => {
      let prevItem = i - 1 >= 0 ? side_panel_views[i - 1] : item;
      let nextItem =
        i + 1 < side_panel_views.length ? side_panel_views[i + 1] : null;
      let next_item_expanded = false;
      let next_item_expaned_overlay = false;
      if (nextItem?.expanded_push) {
        next_item_expanded = true;
        next_item_expaned_overlay = true;
      }
      let disable_overlay = true;

      if (
        (item.overlay || next_item_expaned_overlay || item.focus_side_panel) &&
        (show_overlay == false ||
          item.overlay_modal != prevItem.overlay_modal ||
          item.focus_modal != prevItem.focus_modal ||
          item.focus_side_panel != prevItem.focus_side_panel ||
          item.content_side_panel != prevItem.content_side_panel ||
          (item.expanded_push != prevItem.expanded_push &&
            prevItem.expanded_push))
      ) {
        show_overlay = true;
        disable_overlay = false;
      }

      return (
        <ModalOverlay
          key={"slide_out_view_" + i}
          disabled={disable_overlay}
          onPress={
            !is_locked
              ? () => {
                  if (last_item_is_expanded_side_panel) {
                    this.props.popSidePanel();
                  } else {
                    this.props.popAllSidePanels();
                  }
                }
              : () => {}
          }
          animation_type={
            side_panel_views.length == 1 && start_pop_side_panel
              ? "fadeOut"
              : "fadeIn"
          }
        >
          {this.renderPanel({ item, i, next_item_expanded })}
        </ModalOverlay>
      );
    });
  }
}

const mapStateToProps = ({ auth, modal, native, settings }) => {
  const { user, token } = auth;
  const {
    start_pop_side_panel,
    start_pop_all_side_panel,
    side_panel_views,
    popSidePanelSuccess,
    popAllSidePanelSuccess
  } = modal;
  const { colors } = settings;
  const { isMobile } = native;

  return {
    user,
    token,
    colors,
    isMobile,
    start_pop_side_panel,
    popSidePanelSuccess,
    start_pop_all_side_panel,
    popAllSidePanelSuccess,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  completePopSidePanel,
  popAllSidePanels,
  popSidePanel,
  completepopAllSidePanels,
  startRedirect,
  buildSidePanels
})(PushPopSidePanel);
