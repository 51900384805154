import {
  LOGOUT,
  START_REDIRECT,
  SET_NAVBAR,
  SET_PLATFORM_TYPE,
  SET_TRACKING_EVENT,
  SET_DARK_MODE,
  UPDATE_FEATURE_TOGGLE,
  POP_ALL_SIDE_PANEL,
  BYPASS_MOBILE_REDIRECT
} from "app/DealMachineCore/types";

import GoogleAPI from "app/DealMachineCore/apis/GoogleMapsAPI";
import ReactGA from "react-ga4";
import { logMarketingEvent } from "app/NativeActions";
import { SSE } from "sse.js";
import moment from "moment-timezone";
import CryptoJS from "crypto-js";

const ip_api = GoogleAPI.ipAPI();
let currentAudio = null;
export const AppConfig = () => {
  const token = window.localStorage.getItem("token");

  return {
    saved_token: !!token ? token : null,
    api_url: process.env.REACT_APP_API_URL,
    website_url: process.env.REACT_APP_WEBSITE_URL,
    app_url: process.env.REACT_APP_URL,
    app_version: 13,
    user_version: 6,
    hubspot_id: "3944252",
    google_analytics: "UA-83632441-1",
    facebook_pixel: "107943933079807",
    contentful_space: "tbaxdqcpgx77",
    contentful_token:
      "f2f178e08a5546d8d6c3784466d236786181d83d601ea2b6dd8bbafb3b1a4782",
    device: "desktop",
    platform: "desktop", //maybe get browser here eventually
    google_api: "AIzaSyBKxAXFBe8TSxxLFX6jDKxZdTu8-rdv_6s",
    google_static_map_api: "AIzaSyCO9U4NJMj_oUhy-ELQZtf1GRUFNKME6EQ",
    google_static_map_signature: "VxNWMORqMwGcvX6GR5B1aC2-Zb8=",
    stripe_live: process.env.STRIPE_PUBLISHABLE_KEY
      ? process.env.STRIPE_PUBLISHABLE_KEY
      : "pk_live_518lD28HgBhqUAd6Pmnp7G91RJln8t1rEjSsHj0R55QGLk8EwmfiBN8RKtYvIwZ1ej7UK3eEpEvEzL7Ga8jx6j94r00ZsdJlO98",
    openai_key: process.env.REACT_APP_OPENAI_KEY
  };
};

export const playAudio = ({
    web_file,
    mobile_file,
    url_file,
    onEnd = () => {},
    onPrepared = () => {},
    loop = false,
    volume = 1.0
    }) => {
  // Stop any previously playing audio
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0; // Reset to the beginning
    currentAudio = null; // Clear the reference
  }

  // Create a new Audio instance
  let audio = null;
  if (url_file) {
    audio = new Audio(url_file);
  } else {
    audio = new Audio(web_file);
  }

  // Set audio properties
  audio.volume = volume;
  audio.loop = loop;

  // Start playing the audio
  audio.play().catch(err => {
    console.error("Error playing audio:", err);
  });

  // Handle when the audio ends
  audio.onended = () => {
    currentAudio = null; // Clear the reference when playback ends
    onEnd(); // Trigger the onEnd callback
  };

  // Trigger the onPrepared callback with the audio instance
  onPrepared(audio);

  // Store the current audio instance globally
  currentAudio = audio;
};

export const pauseAudio = ({ audio = null }) => {
  if (audio) {
    audio.pause();
  }
};

export const killAudio = ({ audio = null }) => {
  if (audio) {
    audio.pause();
  }
};
export const killAllAudio = () => {
  //when the id is not telnyxRemoteMedia
  const audioElements = document.querySelectorAll("audio");
  audioElements.forEach(audio => {
    if (audio.id !== "telnyxRemoteMedia") {
      audio.pause();
      audio.remove();
    }
  });
};

export const beginRecording = async ({
  onRecordingStart = () => {},
  onRecordingEnd = () => {}
}) => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  const mediaRecorder = new MediaRecorder(stream, { type: "audio/mpeg" });

  mediaRecorder.start();

  const audioChunks = [];
  mediaRecorder.addEventListener("dataavailable", event => {
    audioChunks.push(event.data);
  });

  mediaRecorder.addEventListener("stop", () => {
    const audioBlob = new Blob(audioChunks, { type: "audio/mpeg" });
    const audioUrl = URL.createObjectURL(audioBlob);

    //file name is recorder-timestamp.mp3
    const filename = "recording" + Date.now() + ".mp3";
    const audioFile = new File(audioChunks, filename, {
      type: "audio/mpeg"
    }); // Create a File from Blob

    onRecordingEnd({ filename, audioFile });
  });
  onRecordingStart(mediaRecorder);
};

export const endRecording = ({ mediaRecorder, onEndComplete }) => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    onEndComplete();
  }
};

//push
export const iconTurnMap = () => {
  return false;
};
export const sentryTracing = api => {
  return false;
};
export const checkApiVersion = () => {
  //no action needed. the web should always be up to date
};
export const getStripeToken = async card_token => {
  return {
    last4: card_token.card.last4,
    tokenId: card_token.id
  };
};

export const getData = async prop => {
  try {
    return window.localStorage.getItem(prop);
  } catch (error) {
    return null;
  }
};

export const saveData = async ({ prop, value, onSuccess = () => {} }) => {
  try {
    window.localStorage.setItem(prop, value);
    onSuccess();
  } catch (error) {}
};

export const removeData = async dispatch => {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
  if (dispatch) {
    dispatch({ type: LOGOUT });
    dispatch({ type: START_REDIRECT, payload: "/" });
  }
};

export const checkUserLocationPermission = (
  success = () => {},
  error = () => {}
) => {
  success();
};

export const displayIntercom = () => {
  window.Intercom("show");
};

export const openMobileSettings = () => {};

export const dismissMobileKeyboard = () => {
  //mobile only function...just leave blank
};

export const openUrl = (url, in_app) => {
  if (!!url) {
    if (in_app) {
      window.open(url, "_self");
    } else {
      window.open(url, "_blank");
    }
  }
};

export const openEmail = ({ email, subject }) => {
  if (!!email) {
    window.open("mailto:" + email + "?Subject=" + subject, "_blank");
  }
};
export const callPhoneNumber = number => {
  //mobile only function...just leave blank
  window.open("tel:" + number, "_self");
};
export const textPhoneNumber = number => {
  //mobile only function...just leave blank
  window.open("sms:" + number, "_self");
};
export const checkGalleryPermissions = () => {
  //mobile only function...just leave blank
};
export const checkCameraPermissions = () => {
  //mobile only function...just leave blank
};

export const checkLocationPermission = () => {
  //mobile only function...just leave blank
};

/*REDUX ACTIONS */

export const setNavbar = ({ navbar }) => {
  return {
    type: SET_NAVBAR,
    payload: { navbar }
  };
};

export const logout = () => {
  return dispatch => {
    removeData(dispatch);
  };
};

export const setPlatformType = type => {
  return {
    type: SET_PLATFORM_TYPE,
    payload: type
  };
};

export const setTrackingEvent = event => {
  return {
    type: SET_TRACKING_EVENT,
    payload: event
  };
};

export const combinePropertyArrays = (
  originalArray,
  newArray,
  array_length = 300
) => {
  var newCombinedArray = [];

  for (var i = 0; i < originalArray.length; i++) {
    var addItem = originalArray[i];
    var found = false;
    for (var j = 0; j < newArray.length; j++) {
      if (newArray[j].property_id == addItem.property_id) {
        found = true;
        addItem = {
          ...newArray[j],
          highlighted: addItem.highlighted
        };
      }
    }

    newCombinedArray.push(addItem);
  }

  for (var k = 0; k < newArray.length; k++) {
    var addNewItem = newArray[k];
    var found_new_item = false;

    for (var l = 0; l < newCombinedArray.length; l++) {
      if (newCombinedArray[l].property_id == addNewItem.property_id) {
        found_new_item = true;
      }
    }
    if (found_new_item != true) {
      newCombinedArray.push(addNewItem);
    }
  }

  if (newCombinedArray.length >= array_length && array_length != 0) {
    return newArray;
  } else {
    return newCombinedArray;
  }
};

export const combineArrays = (originalArray, newArray, array_length = 300) => {
  var newCombinedArray = [];

  for (var i = 0; i < originalArray.length; i++) {
    var addItem = originalArray[i];
    var found = false;
    for (var j = 0; j < newArray.length; j++) {
      if (parseInt(newArray[j].id) === parseInt(addItem.id)) {
        found = true;
        addItem = newArray[j];
      }
    }

    newCombinedArray.push(addItem);
  }

  for (var k = 0; k < newArray.length; k++) {
    var addNewItem = newArray[k];
    var found_new_item = false;

    for (var l = 0; l < newCombinedArray.length; l++) {
      if (newCombinedArray[l].id == addNewItem.id) {
        found_new_item = true;
      }
    }
    if (found_new_item != true) {
      newCombinedArray.push(addNewItem);
    }
  }

  if (newCombinedArray.length >= array_length) {
    return newArray;
  } else {
    return newCombinedArray;
  }
};

export const getMyLocation = ({ success = () => {}, onError = () => {} }) => {
  if ("geolocation" in navigator) {
    // check if geolocation is supported/enabled on current browser
    navigator.geolocation.getCurrentPosition(
      position => {
        // for when getting location is a success
        success({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      error_message => {
        // for when getting location results in an error
        onError();
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 1000 }
    );
  } else {
    onError();
  }
  /*
     }else{


        ip_api.ipLocation()
        .then(response => {
          if(response.problem != null){

          }else if(response.status){
            if(response.status != 200){
            }else{
              success({
                latitude: response.data.latitude,
                longitude: response.data.longitude
              });
            }
          }
        })

    }
    */
};

export const userDeviceLocalizationInfo = async () => {
  const timeout = (prom, time) => {
    let timer;
    return Promise.race([
      prom,
      new Promise(function (_r, rej) {
        return (timer = setTimeout(rej, time));
      })
    ]).finally(() => clearTimeout(timer));
  };
  const getIpApiInfo = async () => {
    const response = await fetch("https://ipapi.co/json/");
    return await response.json();
  };

  const moment_timezone_str = moment.tz.guess(true);
  const user_auth_api_session_device = {
    /* Database: `dealmachine`.`api_sessions`.`devicetype` */
    db_dm_api_user_device_ver: { mjr: 0, mnr: 0, rev: 1 },
    db_dm_api_user_devicetype: "desktop",
    js_moment_info_guess_zone: moment_timezone_str,
    js_moment_info_local_time: moment().format(),
    ie_tf_rfc_9110_user_agent: window.navigator.userAgent
  };

  try {
    const result = await timeout(getIpApiInfo(), 1300);
    user_auth_api_session_device.ip_api_network_ip_address = result.network;
    user_auth_api_session_device.ip_api_network_all_detail = result;
  } catch (error) {}

  return user_auth_api_session_device;
};

export const getDeviceLocation = ({
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  onLoading();

  if ("geolocation" in navigator) {
    // check if geolocation is supported/enabled on current browser
    navigator.geolocation.getCurrentPosition(
      position => {
        // for when getting location is a success
        onSuccess({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      error_message => {
        // for when getting location results in an error

        ip_api
          .ipLocation()
          .then(response => {
            if (response.problem != null) {
            } else if (response.status) {
              if (response.status != 200) {
                onError();
              } else {
                onSuccess({
                  latitude: response.data.latitude,
                  longitude: response.data.longitude
                });
              }
            }
          })
          .catch(onError());
      }
    );
  } else {
    ip_api.ipLocation().then(response => {
      if (response.problem != null) {
      } else if (response.status) {
        if (response.status != 200) {
          onError();
        } else {
          onSuccess({
            latitude: response.data.latitude,
            longitude: response.data.longitude
          });
        }
      }
    });
  }
};

export const getCurrentLocation = (props, success = () => {}) => {
  if ("geolocation" in navigator) {
    // check if geolocation is supported/enabled on current browser
    navigator.geolocation.getCurrentPosition(
      position => {
        // for when getting location is a success

        props.setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude
        });
        //props.reverseGeocode({location: position.coords.latitude+","+position.coords.longitude });
        props.toggleRegionChange(true);

        success(position.coords);
      },
      error_message => {
        // for when getting location results in an error

        ip_api.ipLocation().then(response => {
          if (response.problem != null) {
          } else if (response.status) {
            if (response.status != 200) {
            } else {
              props.setLocation({
                lat: response.data.latitude,
                long: response.data.longitude
              });
              //props.reverseGeocode({location: response.data.latitude+","+response.data.longitude });
              props.toggleRegionChange(true);

              success(response.data);
            }
          }
        });
      }
    );
  } else {
    ip_api.ipLocation().then(response => {
      if (response.problem != null) {
      } else if (response.status) {
        if (response.status != 200) {
        } else {
          props.setLocation({
            lat: response.data.latitude,
            long: response.data.longitude
          });
          //props.reverseGeocode({location: response.data.latitude+","+response.data.longitude });
          props.toggleRegionChange(true);

          success(response.data);
        }
      }
    });
  }
};

export const openMap = ({ latitude, longitude }) => {};

export const setDarkMode = toggle => {
  let dark_mode = toggle;
  if (!dark_mode) {
    dark_mode = "dark_mode_off";
  }

  saveData({ prop: "dark_mode", value: toggle });

  return {
    type: SET_DARK_MODE,
    payload: toggle
  };
};

export const requestExternalWritePerms = async () => {
  return false;
};

export const randomString = (len, charSet) => {
  charSet =
    charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

export const getScaleWithAnchor = ({ scale, width, height }) => {
  return null;
};
export const copyToClipBoard = text => {
  return navigator.clipboard.writeText(text);
};

export const triggerHapticFeedback = ({ type }) => {};

export const askLocationPermissionsThenContinue = ({
  onSuccess = () => {}
}) => {
  return dispatch => {
    onSuccess();
  };
};

export const askPhotoPermissionsThenContinue = ({
  onSuccess = () => {},
  onDenial = () => {}
}) => {
  return dispatch => {
    onSuccess();
    onDenial();
  };
};

export const getCookies = () => {
  const hubspot_cookie =
    document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) || null;

  //get first promoter cookie
  const first_promoter_ref =
    document.cookie.replace(
      /(?:(?:^|.*;\s*)_fprom_ref\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) || null;
  const first_promoter_cookie =
    document.cookie.replace(
      /(?:(?:^|.*;\s*)_fprom_tid\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) || null;

  return {
    hubspot_cookie,
    first_promoter_ref,
    first_promoter_cookie
  };
};

export const bypassMobileRedriect = toggle => {
  return {
    type: BYPASS_MOBILE_REDIRECT,
    payload: toggle
  };
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "windows";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "unknown";
};

export const openMaps = () => {};

export const checkDevicePermissions = () => {};

export const trackSignUpEvent = () => {
  //TRACK SIGNUP CONVERSION HERE

  //hubspot and database
  logMarketingEvent({ slug: "sign_up_v2" });

  //google analytics
  ReactGA.event("sign_up");

  //facebook
  if (window.fbq) {
    window.fbq("track", "SignUp");
  }

  //podsights
  if (window?.pdst) {
    window.pdst("lead", {
      value: 0,
      currency: "USD"
    });
  }

  //linkedin

  //branch
};

export const trackTrialEvent = () => {
  //TRACK SIGNUP CONVERSION HERE

  //hubspot and database
  logMarketingEvent({ slug: "started_trial_v2" });

  //google analytics
  ReactGA.event("purchase");

  //facebook
  if (window.fbq) {
    window.fbq("track", "Purchase");
  }

  //podsights
  if (window?.pdst) {
    window.pdst("purchase", {
      //value: 10.0, // price
      currency: "USD",
      //discount_code: user?.promo_code,

      // We will use this to match up everything in the backend.
      //order_id: '12322323232',

      // is this a new purchase or a purchase from a returning customer?
      is_new_customer: true,
      quantity: 1,

      line_items: []
    });
  }

  //linkedin

  //branch

  //this is the area where we can log any purchase events
  //podsights event purchse
};

let source = null;
export const sendMessageToOpenAI = ({
  messages = [],
  model = "gpt-4o",
  onLoading = () => {},
  onUpdate = () => {},
  onError = () => {},
  onSuccess = () => {},
  onReadyStateChange = () => {}
}) => {
  return dispatch => {
    let full_message = "";
    onLoading();
    let url = "https://api.openai.com/v1/chat/completions";

    const data = {
      model: model,
      messages: messages,
      stream: true,
      temperature: 0.7
    };

    let openai_key = AppConfig().openai_key;

    source = new SSE(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + openai_key
      },
      method: "POST",
      payload: JSON.stringify(data)
    });

    source.addEventListener("message", e => {
      if (e?.data != "[DONE]") {
        // Attempt to parse the entire string as an array
        let modifiedData = "[" + e.data.replace(/}{/g, "},{") + "]";
        try {
          let payloads = JSON.parse(modifiedData);
          payloads.forEach(payload => {
            let text = payload.choices[0]?.delta?.content;
            if (!!text) {
              full_message = full_message + text;
              onUpdate(text);
            }
          });
        } catch (error) {
          console.error("Error parsing JSON array:", error);
        }
      } else {
        source.close();
        onSuccess(full_message);
      }
    });

    source.addEventListener("readystatechange", e => {
      if (e.readyState >= 2) {
        onReadyStateChange();
      }
    });

    source.addEventListener("error", e => {
      let payload = JSON.parse(e?.data);
      onError(payload?.error?.message);
    });

    source.stream();
  };
};

export const stopOpenAI = ({ onSuccess = () => {} }) => {
  return dispatch => {
    if (source) {
      source.close();

      if (onSuccess) {
        onSuccess();
      }
    }
  };
};

export const catchRouteServiceUnavailable = () => {}; //Mobile only action

export const decryptData = (payload = "") => {
  // payload format: IV:Ciphertext
  if (!!payload) {
    const project_midnight = process.env.REACT_APP_PROJECT_MIDNIGHT;

    const parts = payload.split(":");
    if (parts.length !== 2) {
      console.error("Invalid payload format. Expected 'IV:Ciphertext'.");
      return payload; // Return the input as-is if the format is invalid
    }
    const ivBase64 = parts[0];
    const encryptedBase64 = parts[1];

    // Convert Base64 to WordArray
    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const encryptedData = CryptoJS.enc.Base64.parse(encryptedBase64);

    // Convert our string key to a WordArray
    const key = CryptoJS.enc.Utf8.parse(project_midnight);

    // Decrypt
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // Convert decrypted WordArray to UTF-8 string
    return CryptoJS.enc.Utf8.stringify(decrypted);
  }
  return payload;
};
