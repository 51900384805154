import {
  LOGOUT,
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  BILLING_FIELD_CHANGED,
  RESET_BILLING,
  SAVE_CARD,
  SAVE_CARD_SUCCESS,
  SAVE_CARD_FAIL,
  SELECT_PLAN,
  RESET_SELECTED_PLAN,
  CANCEL_PLAN,
  CANCEL_PLAN_SUCCESS,
  CANCEL_PLAN_FAIL,
  TOGGLE_PLAN_FREQUENCY,
  GET_INVOICES,
  LOAD_MORE_INVOICES,
  REFRESH_INVOICES,
  GET_INVOICES_SUCCESS,
  LOAD_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_MARKETING_CREDITS,
  LOAD_MORE_MARKETING_CREDITS,
  REFRESH_MARKETING_CREDITS,
  GET_MARKETING_CREDITS_SUCCESS,
  LOAD_MARKETING_CREDITS_SUCCESS,
  GET_MARKETING_CREDITS_FAIL,
  INIT_EDIT_CREDIT_PURCHASE,
  EDIT_CREDIT_PURCHASE,
  EDIT_CREDITS,
  EDIT_CREDITS_FAIL,
  EDIT_CREDITS_SUCCESS,
  TOGGLE_ONBOARDING,
  SELECT_DEFAULT_SENDING_OPTIONS,
  UPDATE_BILLING_ADDON,
  UPDATE_BILLING_ADDON_FAIL,
  UPDATE_BILLING_ADDON_SUCCESS,
  SET_TRACKING_EVENT,
  GET_PAUSE_PLAN_INFO,
  GET_PAUSE_PLAN_INFO_FAIL,
  GET_PAUSE_PLAN_INFO_SUCCESS,
  PAUSE_OR_CANCEL_PLAN,
  PAUSE_OR_CANCEL_PLAN_FAIL,
  PAUSE_OR_CANCEL_PLAN_SUCCESS,
  GET_DEAL_CREDITS,
  GET_BILLING_FAIL,
  GET_DEAL_CREDITS_SUCCESS,
  TRIGGER_DEAL_CREDIT_RELOAD,
  SET_FEATURE_MODAL,
  GET_PLAN_MODUALS,
  GET_PLAN_MODUALS_SUCCESS,
  GET_LEAD_LIMITS,
  GET_LEAD_LIMITS_SUCCESS,
  GET_CONVERSATION_LIMITS,
  GET_CONVERSATION_LIMITS_SUCCESS,
  GET_LIST_LEAD_LIMITS,
  GET_LIST_LEAD_LIMITS_SUCCESS,
  SET_BILLING_DETAILS,
  GET_ESTIMATED_PAYMENT,
  GET_ESTIMATED_PAYMENT_SUCCESS,
  GET_DRIVING_INFO_BANNER_SUCCESS,
  GET_LISTS_INFO_BANNER_SUCCESS,
  GET_MESSAGES_INFO_BANNER_SUCCESS,
  GET_CRM_INFO_BANNER_SUCCESS,
  SET_PAYMENT_PAGE,
  SET_PRICING_PAGE,
  SET_DEMO_PAGE,
  SET_CANCEL_PAGE,
  CHECK_CANADA_STATE,
  GET_BILLING_USER_CARDS,
  GET_BILLING_USER_CARDS_SUCCESS,
  UPDATE_PAYMENT_METHODS_ORDER_FAIL,
  UPDATE_PAYMENT_METHODS_ORDER_SUCCESS,
  ADD_ADDITIONAL_CARD,
  MARK_EDITING_CARD,
  DELETE_CARD_FAIL,
  DELETE_CARD_SUCCESS,
  MAKE_PRIMARY_CARD_FAIL,
  MAKE_PRIMARY_CARD_SUCCESS,
  TOGGLE_CREDIT_RELOAD,
  SET_FREE_TRIAL_INFO,
  GET_SOURCE_OF_TRUTH,
  GET_SOURCE_OF_TRUTH_FAIL,
  GET_SOURCE_OF_TRUTH_SUCCESS
} from "app/DealMachineCore/types";

import { appRedirect, removeData, getStripeToken } from "app/NativeActions";
import { store } from "app/store";

import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";
import API from "app/DealMachineCore/apis/DealMachineAPI";

const api = API.create();
const apiv2 = APIV2.create();

export const billingFieldChanged = ({ prop, value }) => {
  return {
    type: BILLING_FIELD_CHANGED,
    payload: { prop, value }
  };
};

export const selectPlan = ({ selected_plan, frequency, price }) => {
  return {
    type: SELECT_PLAN,
    payload: { selected_plan, frequency, price }
  };
};

export const togglePlanFrequency = toggle => {
  return {
    type: TOGGLE_PLAN_FREQUENCY,
    payload: toggle
  };
};

export const triggerConversionTrackingEvent = (action, callback) => {
  const { type, payload, event } = action;
  return dispatch => {
    dispatch({
      type: SET_TRACKING_EVENT,
      payload: event
    });
    if (callback) {
      callback();
    }
  };
};

export const setPaymentPage = ({
  selected_engines = [],
  plan_id = null,
  onSuccess = null,
  title = "",
  extra_fee = null,
  extra_fee_text = null,
  engine_slug = "",
  tier = null,
  frequency = ""
}) => {
  return {
    type: SET_PAYMENT_PAGE,
    payload: {
      selected_engines,
      plan_id,
      onSuccess,
      title,
      extra_fee,
      extra_fee_text,
      engine_slug,
      tier,
      frequency
    }
  };
};

export const setCancelPage = ({ engine_slug, title }) => {
  return {
    type: SET_CANCEL_PAGE,
    payload: { engine_slug, title }
  };
};

export const setPricingPage = ({
  title,
  tier = 2,
  engine_slug = null,
  frequency = "annually"
}) => {
  return {
    type: SET_PRICING_PAGE,
    payload: { title, tier, engine_slug, frequency }
  };
};

export const setDemoPage = ({ title, webinar_id }) => {
  return {
    type: SET_DEMO_PAGE,
    payload: { title, webinar_id }
  };
};

export const setBillingDetails = ({
  title,
  plan_module_title,
  slug,
  plan_module_info,
  show_limits = false,
  current_count = 0,
  current_limit = 0,
  limit_title = ""
}) => {
  return {
    type: SET_BILLING_DETAILS,
    payload: {
      title,
      plan_module_title,
      slug,
      plan_module_info,
      show_limits,
      current_count,
      current_limit,
      limit_title
    }
  };
};

export const resetBilling = () => {
  return {
    type: RESET_BILLING
  };
};

export const resetSelectedPlan = ({ plan, frequency, price, plans }) => {
  return {
    type: RESET_SELECTED_PLAN,
    payload: { plan, frequency, price, plans }
  };
};

export const addAdditionalCard = () => {
  return {
    type: ADD_ADDITIONAL_CARD
  };
};

const createStripeToken = async ({
  dispatch,
  token,
  type,
  card_token,
  params,
  stripe_price_ids = [],
  attempt_payment,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  onLoading();

  var c_token;
  if (card_token) {
    c_token = await getStripeToken(card_token);
  } else {
    c_token = await getStripeToken(params);
  }

  try {
    if (!c_token.error || c_token.error == "") {
      const last4 = c_token.last4;
      const card_token = c_token.tokenId;

      apiv2
        .manageSubscription({
          token,
          card_token,
          type,
          stripe_price_ids,
          attempt_payment
        })
        .then(response => {
          if (response.problem != null) {
            onError(response.problem);
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          } else if (response.data.error != false) {
            onError(response.data.error);
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            onSuccess(response.data.results);

            dispatch({
              type: GET_SOURCE_OF_TRUTH_SUCCESS,
              payload: response.data.results
            });
          }
        });
    } else {
      onError(c_token.error);

      dispatch({
        type: ERROR_MESSAGE,
        payload: { message: c_token.error, title: "Error" }
      });
    }
  } catch (error) {
    onError(error);
  }
};

export const addStripePaymentMethod = ({
  token,
  type,
  paymentMethodId,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2
      .addStripePaymentMethod({ token, type, paymentMethodId })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);

          dispatch({
            type: GET_SOURCE_OF_TRUTH_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const stripeCardAction = ({
  token,
  type,
  card_token = null,
  paymentMethodId = null,
  error,
  number,
  expMonth,
  expYear,
  cvc,
  attempt_payment = false,
  stripe_price_ids = [],
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    if (!card_token && !error && !paymentMethodId) {
      //native
      var int_number;
      if (number) {
        int_number = number.replace(/ /g, "");
      }
      const params = {
        // mandatory
        number: int_number,
        expMonth: parseInt(expMonth),
        expYear: parseInt(expYear),
        cvc: cvc
      };

      if (error) {
        onError(error);
      } else {
        if (!number) {
          setTimeout(function () {
            onError();
            dispatch({
              type: ERROR_MESSAGE,
              payload: {
                message: "Please enter a card number.",
                title: "Error"
              }
            });
          }, 10);
        } else if (!expMonth) {
          setTimeout(function () {
            onError();
            dispatch({
              type: ERROR_MESSAGE,
              payload: {
                message: "Please enter an expiration month.",
                title: "Error"
              }
            });
          }, 10);
        } else if (!expYear) {
          setTimeout(function () {
            onError();
            dispatch({
              type: ERROR_MESSAGE,
              payload: {
                message: "Please enter a expiration year.",
                title: "Error"
              }
            });
          }, 10);
        } else if (!cvc) {
          setTimeout(function () {
            onError();
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: "Please enter a CVC number.", title: "Error" }
            });
          }, 10);
        } else {
          createStripeToken({
            dispatch,
            token,
            type,
            params,
            stripe_price_ids,
            attempt_payment,
            onLoading,
            onError,
            onSuccess
          });
        }
      }
    } else {
      //web
      if (error) {
        onError(error.message);
        dispatch({
          type: ERROR_MESSAGE,
          payload: { message: error.message, title: "Error" }
        });
      } else {
        createStripeToken({
          dispatch,
          token,
          type,
          card_token,
          stripe_price_ids,
          attempt_payment,
          onLoading,
          onError,
          onSuccess
        });
      }
    }
  };
};

export const saveCard = ({
  token,
  card_token,
  error,
  number,
  expMonth,
  expYear,
  cvc,
  price,
  plan,
  frequency,
  type,
  device,
  slug,
  pause_all_mailing = null,
  accepted_terms,
  terms_type,
  address = null,
  email = null,
  selected_street,
  street_selected_tier,
  street_selected_frequency,

  selected_lists,
  lists_selected_tier,
  lists_selected_frequency,

  selected_messages,
  messages_selected_tier,
  messages_selected_frequency,
  replace_primary,
  expected_card_order,
  editing_card_id,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      switch (type) {
        case "confirm_payment_update_card":
          break;

        default:
          dispatch({ type: IS_LOADING, payload: true });

          break;
      }
    }

    dispatch({ type: SAVE_CARD });

    if (!card_token && !error) {
      //native
      var int_number;
      if (number) {
        int_number = number.replace(/ /g, "");
      }
      const params = {
        // mandatory
        number: int_number,
        expMonth: parseInt(expMonth),
        expYear: parseInt(expYear),
        cvc: cvc,
        name: address ? address.name : "",
        address_line1: address ? address.address : "",
        address_line2: address ? address.address2 : "",
        address_city: address ? address.city : "",
        address_state: address ? address.state : "",
        address_zip: address ? address.zip : "",
        email: email || ""
      };

      if (error) {
        if (onError) {
          onError();
        }
        dispatch({ type: SAVE_CARD_FAIL, payload: error.message });
        dispatch({
          type: ERROR_MESSAGE,
          payload: { message: error.message, title: "Error" }
        });
      } else {
        if (!number) {
          setTimeout(function () {
            if (onError) {
              onError();
            }
            dispatch({
              type: SAVE_CARD_FAIL,
              payload: "Please enter a card number."
            });
            dispatch({
              type: ERROR_MESSAGE,
              payload: {
                message: "Please enter a card number.",
                title: "Error"
              }
            });
          }, 10);
        } else if (!expMonth) {
          setTimeout(function () {
            if (onError) {
              onError();
            }
            dispatch({
              type: SAVE_CARD_FAIL,
              payload: "Please enter an expiration month."
            });
            dispatch({
              type: ERROR_MESSAGE,
              payload: {
                message: "Please enter an expiration month.",
                title: "Error"
              }
            });
          }, 10);
        } else if (!expYear) {
          setTimeout(function () {
            if (onError) {
              onError();
            }
            dispatch({
              type: SAVE_CARD_FAIL,
              payload: "Please enter a expiration year."
            });
            dispatch({
              type: ERROR_MESSAGE,
              payload: {
                message: "Please enter a expiration year.",
                title: "Error"
              }
            });
          }, 10);
        } else if (!cvc) {
          setTimeout(function () {
            if (onError) {
              onError();
            }
            dispatch({
              type: SAVE_CARD_FAIL,
              payload: "Please enter a CVC number."
            });
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: "Please enter a CVC number.", title: "Error" }
            });
          }, 10);
        } else {
          createToken(
            dispatch,
            token,
            null,
            params,
            type,
            price,
            plan,
            frequency,
            device,
            pause_all_mailing,
            accepted_terms,
            terms_type,
            slug,
            address,
            email,
            selected_street,
            street_selected_tier,
            street_selected_frequency,
            null, // street_extra_fee
            null, // street_extra_fee_text
            selected_lists,
            lists_selected_tier,
            lists_selected_frequency,
            null, // lists_extra_fee
            null, // lists_extra_fee_text

            selected_messages,
            messages_selected_tier,
            messages_selected_frequency,
            null, // messages_extra_fee
            null, // messages_extra_fee_text
            replace_primary,
            expected_card_order ? expected_card_order : 0,
            editing_card_id,
            onLoading,
            onError,
            onSuccess
          );
        }
      }
    } else {
      //web
      if (error) {
        if (onError) {
          onError();
        }
        dispatch({ type: SAVE_CARD_FAIL, payload: error.message });
        dispatch({
          type: ERROR_MESSAGE,
          payload: { message: error.message, title: "Error" }
        });
      } else {
        createToken(
          dispatch,
          token,
          card_token,
          null, // params
          type,
          price,
          plan,
          frequency,
          null, // device
          pause_all_mailing,
          accepted_terms,
          terms_type,
          slug,
          address,
          email,
          selected_street,
          street_selected_tier,
          street_selected_frequency,
          null, // street_extra_fee
          null, // street_extra_fee_text

          selected_lists,
          lists_selected_tier,
          lists_selected_frequency,
          null, // lists_extra_fee
          null, // lists_extra_fee_text
          selected_messages,
          messages_selected_tier,
          messages_selected_frequency,
          null, // messages_extra_fee
          null, // messages_extra_fee_text
          replace_primary,
          expected_card_order ? expected_card_order : 0,
          editing_card_id,
          onLoading,
          onError,
          onSuccess
        );
      }
    }
  };
};

export const cancelPlan = ({ token, canceled_reason }) => {
  return dispatch => {
    dispatch({ type: CANCEL_PLAN });
    dispatch({ type: IS_LOADING, payload: true });

    api.cancelPlan(token, canceled_reason).then(response => {
      if (response.problem != null) {
        cancelPlanFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        cancelPlanFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        cancelPlanSuccess(dispatch);
      }
    });
  };
};

const cancelPlanFail = (dispatch, error) => {
  dispatch({ type: CANCEL_PLAN_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const cancelPlanSuccess = dispatch => {
  dispatch({ type: IS_LOADING, payload: false });

  dispatch({ type: CANCEL_PLAN_SUCCESS });

  removeData(dispatch);
};

export const updatePlan = ({
  token,
  plan,
  frequency,
  type,
  pause_all_mailing,
  accepted_terms,
  terms_type,
  slug,

  selected_street = 0,
  street_selected_tier = 1,
  street_selected_frequency = "monthly",
  street_extra_fee = null,
  street_extra_fee_text = null,

  selected_lists = 0,
  lists_selected_tier = 1,
  lists_selected_frequency = "monthly",
  lists_extra_fee = null,
  lists_extra_fee_text = null,

  selected_messages = 0,
  messages_selected_tier = 1,
  messages_selected_frequency = "monthly",
  messages_extra_fee = null,
  messages_extra_fee_text = null,

  first_cancel_reason,
  second_cancel_reason,
  pause_mailers = false,

  onSuccess = null,
  onError = null,
  onLoading = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: SAVE_CARD });

    apiv2
      .saveCard(
        token,
        null,
        type,
        null,
        null,
        plan,
        frequency,
        null,
        pause_all_mailing,
        accepted_terms,
        terms_type,
        slug,
        null,
        null,
        selected_street,
        street_selected_tier,
        street_selected_frequency,
        street_extra_fee,
        street_extra_fee_text,
        selected_lists,
        lists_selected_tier,
        lists_selected_frequency,
        lists_extra_fee,
        lists_extra_fee_text,
        selected_messages,
        messages_selected_tier,
        messages_selected_frequency,
        messages_extra_fee,
        messages_extra_fee_text,
        first_cancel_reason,
        second_cancel_reason,
        pause_mailers
      )
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          saveCardFail(dispatch, response.problem, type);
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          saveCardFail(dispatch, response.data.error, type);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess();
          }
          saveCardSuccess(
            dispatch,
            response.data.results,
            type,
            slug,
            onSuccess
          );
        }
      });
  };
};

const createToken = async (
  dispatch,
  token,
  card_token,
  params,
  type,
  price,
  plan,
  frequency,
  device,
  pause_all_mailing = null,
  accepted_terms = 0,
  terms_type = "user",
  slug = "driving",
  address = null,
  email = null,
  selected_street = 0,
  street_selected_tier = 1,
  street_selected_frequency = "monthly",
  street_extra_fee = null,
  street_extra_fee_text = null,
  selected_lists = 0,
  lists_selected_tier = 1,
  lists_selected_frequency = "monthly",
  lists_extra_fee = null,
  lists_extra_fee_text = null,
  selected_messages = 0,
  messages_selected_tier = 1,
  messages_selected_frequency = "monthly",
  messages_extra_fee = null,
  messages_extra_fee_text = null,
  replace_primary = true,
  expected_card_order = 0,
  editing_card_id = null,
  onLoading = null,
  onError = null,
  onSuccess = null
) => {
  if (onLoading) {
    onLoading();
  }

  var c_token;
  if (card_token) {
    c_token = await getStripeToken(card_token); //await stripe.createTokenWithCard(params);
  } else {
    c_token = await getStripeToken(params);
  }

  try {
    if (!c_token.error || c_token.error == "") {
      const last4 = c_token.last4;
      const card_token_id = c_token.tokenId;

      apiv2
        .saveCard(
          token,
          card_token_id,
          type,
          last4,
          price,
          plan,
          frequency,
          device,
          pause_all_mailing,
          accepted_terms,
          terms_type,
          slug,
          address,
          email,
          selected_street,
          street_selected_tier,
          street_selected_frequency,
          street_extra_fee,
          street_extra_fee_text,
          selected_lists,
          lists_selected_tier,
          lists_selected_frequency,
          lists_extra_fee,
          lists_extra_fee_text,
          selected_messages,
          messages_selected_tier,
          messages_selected_frequency,
          messages_extra_fee,
          messages_extra_fee_text,
          null, // first_cancel_reason
          null, // second_cancel_reason
          null, // pause_mailers
          onSuccess, // onSuccess
          replace_primary,
          expected_card_order,
          editing_card_id
        )
        .then(response => {
          if (response.problem != null) {
            if (onError) {
              onError();
            }
            saveCardFail(dispatch, response.problem);
          } else if (response.data.error != false) {
            if (onError) {
              onError();
            }
            saveCardFail(dispatch, response.data.error);
            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            saveCardSuccess(
              dispatch,
              response.data.results,
              type == "onboarding" ? "purchase_onboarding" : type,
              slug,
              onSuccess
            );
          }
        });
    } else {
      saveCardFail(dispatch, c_token.error);
    }
  } catch (error) {
    dispatch({ type: SAVE_CARD_FAIL, payload: error.message });
    dispatch({
      type: ERROR_MESSAGE,
      payload: { message: error.message, title: "Error" }
    });
  }
};

const saveCardFail = (dispatch, error) => {
  dispatch({ type: SAVE_CARD_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const saveCardSuccess = (
  dispatch,
  results,
  type,
  slug = "driving",
  onSuccess = null
) => {
  dispatch({
    type: SAVE_CARD_SUCCESS,
    payload: results
  });

  if (results?.user && results?.user.canceled == 1) {
    appRedirect({
      dispatch,
      redirect: "canceled"
    });
  } else if (results?.source_of_truth) {
    dispatch({
      type: GET_SOURCE_OF_TRUTH_SUCCESS,
      payload: results
    });
  }

  if (
    type == "onboarding_add_card" ||
    type == "purchase_onboarding" ||
    type == "onboarding_select_plans" ||
    type == "select_plans"
  ) {
    //conversion code for marketing
    const device = store.getState().native.device;
    if (device == "desktop") {
      if (window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-848400355/vnaaCKPos7EBEOOfxpQD",
          transaction_id: ""
        });
      }

      if (window.fbq) {
        window.fbq("track", "Purchase");
      }
    }
  }

  if (onSuccess) {
    onSuccess();
  }

  switch (type) {
    default:
      //charge

      dispatch({
        type: SUCCESS_MESSAGE,
        payload: { message: "Thank you for your purchase.", title: "Success!" }
      });

      break;

    case "add_or_update_plan_module":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "Success! You now have access to these services!",
          title: "Success!"
        }
      });

      if (slug == "messages") {
        appRedirect({
          dispatch,
          redirect: "messages_completed_signup"
        });
      } else if (slug == "driving") {
        appRedirect({
          dispatch,
          redirect: "dashboard_completed_signup"
        });
      } else if (slug == "lists") {
        appRedirect({
          dispatch,
          redirect: "lists_completed_signup"
        });
      } else if (slug == "crm") {
        appRedirect({
          dispatch,
          redirect: "dashboard_completed_signup"
        });
      } else {
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { remove: "get-feature" }
        });
      }

      break;

    case "switch_plan":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "Success! You have switched your plan!",
          title: "Success!"
        }
      });
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { remove: "billing-details" }
      });
      break;

    case "cancel_plan":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You have canceled your plan.",
          title: "Success!"
        }
      });

      break;

    case "pause_plan":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You have enrolled in the paused plan.",
          title: "Success!"
        }
      });

      break;

    case "select_plans":
      dispatch({ type: IS_LOADING, payload: false });

      if (onSuccess) {
        onSuccess();
      } else {
        appRedirect({
          dispatch,
          redirect: "onboarding_complete_redirect",
          payload: {
            user: results.user
          }
        });
      }

      break;

    case "bypass_trial":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You have started paying for your plan!",
          title: "Success!"
        }
      });

      break;

    case "onboarding_select_plans":
      dispatch({ type: IS_LOADING, payload: false });
      appRedirect({
        dispatch,
        redirect: "dashboard_completed_signup"
      });

      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "saveCardOnboardingSuccess"
      });

      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "intercom_added_credit_card"
      });
      break;

    case "onboarding":
    case "purchase_onboarding":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "Success! You now have access to this feature!",
          title: "Success!"
        }
      });

      if (slug == "messages") {
        appRedirect({
          dispatch,
          redirect: "messages_completed_signup"
        });
      } else {
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { remove: "get-feature" }
        });
      }
      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "saveCardOnboardingSuccess"
      });

      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "intercom_added_credit_card"
      });

      break;

    case "update_plan_frequency":
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've successfully updated your payment frequency.",
          title: "Success!"
        }
      });

      break;

    case "reactivate":
    case "reactivate_and_update_card":
      appRedirect({ dispatch, redirect: "dashboard" });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've successfully reactivated your account.",
          title: "Success!"
        }
      });

      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "saveCardReactivateSuccess"
      });

      break;

    case "change_plan":
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { type: "billing" }
      });

      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've successfully changed your plan.",
          title: "Success!"
        }
      });
      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "saveCardChangePlanSuccess"
      });

      break;

    case "onboarding_add_card":
      dispatch({
        type: UPDATE_PAYMENT_METHODS_ORDER_SUCCESS,
        payload: results
      });

      dispatch({ type: IS_LOADING, payload: false });
      dispatch({
        type: TOGGLE_ONBOARDING,
        payload: false
      });
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { remove: "add-card" }
      });

      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message:
            "You've successfully added a card to your account. You can now continue to send mailers and skip trace your leads.",
          title: "Success!"
        }
      });

      break;

    case "confirm_payment_update_card":
      dispatch({
        type: UPDATE_PAYMENT_METHODS_ORDER_SUCCESS,
        payload: results
      });
      break;

    case "reactivate_card":
    case "update_card":
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { remove: "add-card" }
      });

      dispatch({
        type: UPDATE_PAYMENT_METHODS_ORDER_SUCCESS,
        payload: results
      });

      dispatch({
        type: TOGGLE_ONBOARDING,
        payload: false
      });

      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've successfully updated your card(s).",
          title: "Success!"
        }
      });

      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "saveCardReactivateCardSuccess"
      });

      break;

    case "update_plan":
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { type: "billing" }
      });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've successfully updated your plan.",
          title: "Success!"
        }
      });
      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "saveCardUpdatePlanSuccess"
      });

      break;

    case "popup":
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { type: "billing" }
      });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: { message: "Thank you for your purchase.", title: "Success!" }
      });

      break;
  }
};

export const getInvoices = ({
  token,
  type,
  begin,
  team,
  start_date,
  end_date
}) => {
  return dispatch => {
    switch (type) {
      case "onboarding":
      case "invoices":
      default:
        dispatch({ type: GET_INVOICES });

        break;

      case "load_more_invoices":
        dispatch({ type: LOAD_MORE_INVOICES });

        break;

      case "refresh_invoices":
        dispatch({ type: REFRESH_INVOICES });

        break;
    }

    api
      .getInvoices({ token, type, begin, team, start_date, end_date })
      .then(response => {
        if (response.problem != null) {
          getInvoicesFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          getInvoicesFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getInvoicesSuccess(
            dispatch,
            type,
            response.data.results.invoices,
            response.data.results.billing,
            response.data.results.total_spent,
            start_date,
            end_date
          );
        }
      });
  };
};

const getInvoicesFail = (dispatch, error) => {
  dispatch({ type: GET_INVOICES_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const getInvoicesSuccess = (
  dispatch,
  type,
  invoices,
  billing,
  total_spent,
  start_date,
  end_date
) => {
  switch (type) {
    case "onboarding":
    case "refresh_invoices":
    case "invoices":
    default:
      dispatch({
        type: GET_INVOICES_SUCCESS,
        payload: { invoices, billing, total_spent, start_date, end_date }
      });

      break;

    case "load_more_invoices":
      dispatch({
        type: LOAD_INVOICES_SUCCESS,
        payload: { invoices, billing, total_spent, start_date, end_date }
      });
      break;
  }
};

export const getMarketingCredits = ({
  token,
  type,
  begin,
  team,
  start_date,
  end_date,
  utc_offset,
  timezone
}) => {
  return dispatch => {
    switch (type) {
      case "onboarding":
      case "marketing_credits":
        dispatch({ type: GET_MARKETING_CREDITS });

        break;

      case "load_more_marketing_credits":
        dispatch({ type: LOAD_MORE_MARKETING_CREDITS });

        break;

      case "refresh_marketing_credits":
        dispatch({ type: REFRESH_MARKETING_CREDITS });

        break;
    }

    api
      .getMarketingCredits({
        token,
        type,
        begin,
        team,
        start_date,
        end_date,
        utc_offset,
        timezone
      })
      .then(response => {
        if (response.problem != null) {
          getMarketingCreditsFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          getMarketingCreditsFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getMarketingCreditsSuccess(
            dispatch,
            type,
            response.data.results.marketing_credits,
            response.data.results.billing,
            response.data.results.total_spent,
            start_date,
            end_date
          );
        }
      });
  };
};

const getMarketingCreditsFail = (dispatch, error) => {
  dispatch({ type: GET_MARKETING_CREDITS_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const getMarketingCreditsSuccess = (
  dispatch,
  type,
  marketing_credits,
  billing,
  total_spent,
  start_date,
  end_date
) => {
  switch (type) {
    case "onboarding":
    case "refresh_marketing_credits":
    case "marketing_credits":
    default:
      dispatch({
        type: GET_MARKETING_CREDITS_SUCCESS,
        payload: {
          marketing_credits,
          billing,
          total_spent,
          start_date,
          end_date
        }
      });

      break;

    case "load_more_marketing_credits":
      dispatch({
        type: LOAD_MARKETING_CREDITS_SUCCESS,
        payload: {
          marketing_credits,
          billing,
          total_spent,
          start_date,
          end_date
        }
      });
      break;
  }
};

export const initEditCreditPurchase = editCreditPurchase => {
  return {
    type: INIT_EDIT_CREDIT_PURCHASE,
    payload: editCreditPurchase
  };
};
export const creditPurchaseChanged = ({ prop, value }) => {
  return {
    type: EDIT_CREDIT_PURCHASE,
    payload: { prop, value }
  };
};

export const editCredits = ({
  token,
  type,
  payload,
  team_id = null,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    dispatch({ type: EDIT_CREDITS });
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    apiv2.editCredits({ token, type, team_id, payload }).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        editCreditsFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        editCreditsFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (!team_id) {
          dispatch({
            type: EDIT_CREDITS_SUCCESS,
            payload: response.data.results
          });
        }

        if (onSuccess) {
          onSuccess(response.data.results);
        } else {
          editCreditsSuccess(dispatch, type, response.data.results);
        }
      }
    });
  };
};

const editCreditsFail = (dispatch, error) => {
  dispatch({ type: EDIT_CREDITS_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const editCreditsSuccess = (dispatch, type, results) => {
  dispatch({ type: IS_LOADING, payload: false });

  switch (type) {
    default:
      break;

    case "purchase_credits":
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { type: "billing" }
      });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: { message: "Your purchase is complete!.", title: "Success!" }
      });

      break;

    case "set_default_credit_reload":
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { type: "billing" }
      });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message:
            "You've successfully updated your default Marketing Credit reload amount.",
          title: "Success!"
        }
      });
      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "setDefaultCreditsSuccess"
      });

      break;

    case "set_default_credit_reload_onboarding":
      if (results.user.set_resend_freq == 1) {
        dispatch({
          type: TOGGLE_ONBOARDING,
          payload: false
        });
        appRedirect({ dispatch, redirect: "main" });
        appRedirect({ dispatch, redirect: "dashboard" });
      } else {
        dispatch({ type: SELECT_DEFAULT_SENDING_OPTIONS, payload: true });
        appRedirect({ dispatch, redirect: "mailingOptionsOnboarding" });
      }

      dispatch({
        type: SET_TRACKING_EVENT,
        payload: "setDefaultCreditsOnboardingSuccess"
      });

      dispatch({ type: IS_LOADING, payload: false });

      break;
  }
};

export const updateBillingAddon = ({
  token,
  addon_id,
  slug,
  type,
  payload,
  onSuccess = null
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: UPDATE_BILLING_ADDON });

    apiv2.updateAddon({ token, addon_id, type, payload }).then(response => {
      if (response.problem != null) {
        updateBillingAddonFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        updateBillingAddonFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (onSuccess) {
          onSuccess();
        }
        updateBillingAddonSuccess(dispatch, type, slug, response.data.results);
      }
    });
  };
};

const updateBillingAddonFail = (dispatch, error) => {
  dispatch({ type: UPDATE_BILLING_ADDON_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const updateBillingAddonSuccess = (dispatch, type, slug, results) => {
  dispatch({
    type: UPDATE_BILLING_ADDON_SUCCESS,
    payload: {
      billing: results.billing,
      plans: results.plans,
      user: results.user,
      slug: slug,
      type: type
    }
  });

  switch (type) {
    case "purchase":
    default:
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message:
            "Thank you for your purchase. You now have access to this feature!",
          title: "Success!"
        }
      });

      break;

    case "cancel":
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message:
            "You've successfully canceled this feature. You will no longer have access to it.",
          title: "Success!"
        }
      });
      break;
  }
};

export const getBillingAddonId = (billing_addons, slug) => {
  for (var i = 0; i < billing_addons.length; i++) {
    if (billing_addons[i].slug == slug) {
      return billing_addons[i].id;
    }
  }
};

export const triggerBillingAddon = ({ props, feature_slug, callback }) => {
  let addon = null;
  let unlocked = false;
  const billing_addons = props.billing_addons;

  for (var i = 0; i < billing_addons.length; i++) {
    if (billing_addons[i].slug == feature_slug) {
      addon = billing_addons[i];

      if (
        billing_addons[i].team_has_addon == 1 ||
        billing_addons[i].included_in_team_plan == 1
      ) {
        unlocked = true;
      }
    }
  }

  if (unlocked == true || addon == null) {
    callback();
  } else {
    if (props.user.props.can_mimic_team_owner == 1) {
      if (props.platform == "ios") {
        props.setModal({
          title: 'You need the "' + addon.title + '" product.',
          description:
            'You need the "' +
            addon.title +
            '" product to use this feature. Please go to DealMachine on the web to manage your account.',
          image: addon.image,
          submit: "Dismiss",
          onPress: () => {},
          cancel: "",
          onCancel: () => {}
        });
        props.toggleModal({ show: true, type: "normal" });
      } else {
        //check if user is not an old user to prevent randomness
        if (
          props.user?.user_version >= 2 &&
          props.user.user_mail_version >= 2
        ) {
          props.setModal({
            title: addon.marketing_title,
            description: addon.marketing_text,
            image: addon.image,
            submit: addon.button_text_yes,
            onPress: () => {
              props.updateBillingAddon({
                token: props.token,
                type: "purchase",
                slug: addon.slug,
                addon_id: addon.id
              });
            },
            cancel: addon.button_text_no,
            onCancel: () => {}
          });
          props.toggleModal({ show: true, type: "normal" });
        } else {
          props.setModal({
            title: "You're on an older DealMachine plan",
            description:
              "Please talk to Customer Support about updating your plan. Once you're on a newer plan you can purchase this product.",
            image: "error",
            submit: "Dismiss",
            onPress: () => {},
            cancel: "",
            onCancel: () => {}
          });
          props.toggleModal({ show: true, type: "normal" });
        }
      }
    } else {
      props.setModal({
        title: "This feature is locked.",
        description:
          "You're team leader will need to purchase this feature to unlock it.",
        icon: "lock",
        submit: "Dismiss",
        onPress: () => {},
        cancel: "",
        onCancel: () => {}
      });
      props.toggleModal({ show: true, type: "normal" });
    }
  }
};

export const getPausePlanInfo = ({ token, module_type = "driving" }) => {
  return dispatch => {
    dispatch({ type: GET_PAUSE_PLAN_INFO });

    apiv2
      .getPausePlanInfo({
        token,
        module_type: module_type == "driving" ? "driving_v2" : module_type
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_PAUSE_PLAN_INFO_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_PAUSE_PLAN_INFO_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_PAUSE_PLAN_INFO_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const pauseOrCancelPlan = ({
  token,
  type,
  slug,
  url,
  module_type = "driving",
  canceled_reason
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: PAUSE_OR_CANCEL_PLAN });

    apiv2
      .pauseOrCancelPlan({ token, type, module_type, slug, canceled_reason })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: PAUSE_OR_CANCEL_PLAN_FAIL,
            payload: response.problem
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: PAUSE_OR_CANCEL_PLAN_FAIL,
            payload: response.data.error
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });
          dispatch({
            type: PAUSE_OR_CANCEL_PLAN_SUCCESS,
            payload: response.data.results
          });

          dispatch({
            type: SET_TRACKING_EVENT,
            payload: module_type + "_" + type
          });

          if (type == "pause") {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message: "You've successfully paused this plan",
                title: "Success!"
              }
            });
            appRedirect({ dispatch, redirect: "settings" });
          } else if (type == "cancel") {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message: "You've successfully canceled this plan",
                title: "Success!"
              }
            });
          } else if (type == "offer") {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message: "You've signed up for this offer",
                title: "Success!"
              }
            });
            appRedirect({ dispatch, redirect: "dashboard" });
            //openUrl(url);
          }

          // removeData(dispatch);
        }
      });
  };
};

export const triggerDealCreditReload = toggle => {
  return {
    type: TRIGGER_DEAL_CREDIT_RELOAD,
    payload: toggle
  };
};

export const getBilling = ({
  token,
  type,
  slug,
  require_tier,
  selected_street,
  street_selected_tier,
  street_selected_frequency,
  selected_lists,
  lists_selected_tier,
  lists_selected_frequency,
  selected_messages,
  messages_selected_tier,
  messages_selected_frequency,
  extra_fee,
  extra_fee_text,
  team_id,
  onLoading = null,
  onSuccess = null,
  onError = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      switch (type) {
        case "get_cards":
          dispatch({ type: GET_BILLING_USER_CARDS });
          break;
        case "deal_credits":
          dispatch({ type: GET_DEAL_CREDITS });
          break;
        case "plan_module_options":
          dispatch({ type: GET_PLAN_MODUALS });
          break;
        case "lead_limits":
          dispatch({ type: GET_LEAD_LIMITS });
          break;
        case "conversation_limits":
          dispatch({ type: GET_CONVERSATION_LIMITS });
          break;
        case "list_lead_limits":
          dispatch({ type: GET_LIST_LEAD_LIMITS });
          break;
        case "estimated_payment":
          dispatch({ type: GET_ESTIMATED_PAYMENT });
          break;

        default:
          break;
      }
    }

    apiv2
      .getBilling({
        token,
        type,
        slug,
        require_tier,
        selected_street,
        street_selected_tier,
        street_selected_frequency,
        selected_lists,
        lists_selected_tier,
        lists_selected_frequency,
        selected_messages,
        messages_selected_tier,
        messages_selected_frequency,
        extra_fee,
        extra_fee_text,
        team_id
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          } else {
            dispatch({ type: GET_BILLING_FAIL });
          }
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          } else {
            dispatch({ type: GET_BILLING_FAIL });
          }
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            switch (type) {
              case "get_cards":
                dispatch({
                  type: GET_BILLING_USER_CARDS_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "estimated_payment":
                dispatch({
                  type: GET_ESTIMATED_PAYMENT_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "deal_credits":
                dispatch({
                  type: GET_DEAL_CREDITS_SUCCESS,
                  payload: response.data.results
                });
                break;

              case "plan_module_options":
                dispatch({
                  type: GET_PLAN_MODUALS_SUCCESS,
                  payload: response.data.results
                });
                break;

              case "lead_limits":
                dispatch({
                  type: GET_LEAD_LIMITS_SUCCESS,
                  payload: response.data.results
                });
                break;

              case "conversation_limits":
                dispatch({
                  type: GET_CONVERSATION_LIMITS_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "list_lead_limits":
                dispatch({
                  type: GET_LIST_LEAD_LIMITS_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "driving_info_banner":
                dispatch({
                  type: GET_DRIVING_INFO_BANNER_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "lists_info_banner":
                dispatch({
                  type: GET_LISTS_INFO_BANNER_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "messages_info_banner":
                dispatch({
                  type: GET_MESSAGES_INFO_BANNER_SUCCESS,
                  payload: response.data.results
                });
                break;
              case "crm_info_banner":
                dispatch({
                  type: GET_CRM_INFO_BANNER_SUCCESS,
                  payload: response.data.results
                });
                break;
              default:
                break;
            }
          }
        }
      });
  };
};
export const checkIfOnAEnterprisePlan = ({ plan_modules = [] }) => {
  for (let i = 0; i < plan_modules.length; i++) {
    const plan_module = plan_modules[i];
    if (plan_module.tier > 2 || plan_module.enterprise == 1) {
      return true;
    }
  }
  return false;
};

export const getLimitsForAllPlan = (source_of_truth = {}) => {
  let limits = {
    number_of_custom_fields: 0,
    include_custom_statuses: false,
    tier: 0,
    api_access: false
  };

  if (source_of_truth) {
    if (
      (source_of_truth.number_of_custom_fields >
        limits.number_of_custom_fields &&
        limits.number_of_custom_fields != -1) ||
      source_of_truth.number_of_custom_fields == -1
    ) {
      limits.number_of_custom_fields = source_of_truth.number_of_custom_fields;
    }

    if (source_of_truth.subscription?.metadata?.custom_statuses) {
      limits.include_custom_statuses = true;
    }
  }

  limits.api_access = true;

  return limits;
};

export const checkIfUserHasModule = ({ plan_modules = [], user, slug }) => {
  if (user?.user_version >= 6) {
    const source_of_truth = store.getState().billing?.source_of_truth;
    const billing = store.getState().billing;
    const subscription = source_of_truth.subscription;
    const products = subscription.products;
    const metadata = subscription.metadata;
    let canceled_access = subscription.canceled_at ? true : false;
    let user_has_module = false;
    let product = [];
    for (let j = 0; j < products.length; j++) {
      if (products[j].product_slug == slug) {
        user_has_module = true;
        product = products[j];
      }
    }
    const is_on_trial = subscription.status == "is_trialing" ? true : false;
    let module_info = {
      has_module: canceled_access ? false : user_has_module ? true : false,
      team_has_module: canceled_access ? false : user_has_module ? true : false,
      user_has_module,
      require_training: true,
      is_trial_over: !is_on_trial,
      is_on_trial: is_on_trial,
      driving_for_dollars: subscription.metadata.driving_for_dollars,
      tier: product.product_tier,
      selected_plan: true,
      current_tier: product.product_tier,
      canceled: canceled_access,
      canceled_access,
      paused: subscription.is_paused,
      date_canceled: subscription.canceled_at,
      team_member_limit: metadata.number_of_team_members || 0,
      team_member_count:
        source_of_truth.current_limits.number_of_team_members.amount || 0,
      dealfinder_limit:
        source_of_truth.current_limits.number_of_team_members || 0,
      dealfinder_count:
        source_of_truth.current_limits.number_of_team_members.amount || 0,
      plan_frequency: product.interval == "year" ? "annually" : "monthly",
      slug: product.product_slug
    };
    return {
      has_module: canceled_access ? false : user_has_module ? true : false,
      team_has_module: canceled_access ? false : user_has_module ? true : false,
      user_has_module,
      require_training: true,
      is_trial_over: !is_on_trial,
      is_on_trial: is_on_trial,
      driving_for_dollars: subscription.metadata.driving_for_dollars,
      tier: product.product_tier,
      selected_plan: true,
      current_tier: product.product_tier,
      canceled: canceled_access,
      canceled_access,
      paused: subscription.is_paused,
      date_canceled: subscription.canceled_at,
      team_member_limit: metadata.number_of_team_members || 0,
      team_member_count:
        source_of_truth.current_limits.number_of_team_members.amount || 0,
      dealfinder_limit: metadata.number_of_team_members || 0,
      dealfinder_count:
        source_of_truth.current_limits.number_of_team_members.amount || 0,
      module_info: module_info,
      plan_frequency: product.interval == "year" ? "annually" : "monthly",
      slug: product.product_slug
    };
  } else {
    for (let i = 0; i < plan_modules.length; i++) {
      const plan_module = plan_modules[i];
      if (plan_module) {
        const {
          module_type,
          free_trial_info,
          approved_to_charge,
          require_training,
          canceled_access,
          tier,
          current_tier,
          plan_canceled,
          date_canceled,
          paused,
          team_member_limit,
          team_member_count,
          dealfinder_limit,
          dealfinder_count,
          plan_frequency
        } = plan_module;

        if (module_type === slug) {
          let user_has_module = false;
          for (let j = 0; j < user.plan_modules.length; j++) {
            if (user.plan_modules[j] == slug) {
              user_has_module = true;
            }
          }

          const { is_on_trial } = free_trial_info;
          return {
            has_module:
              is_on_trial == false && approved_to_charge == 0
                ? false
                : canceled_access == false
                ? false
                : user_has_module === true
                ? true
                : false,
            team_has_module:
              is_on_trial == false && approved_to_charge == 0
                ? false
                : canceled_access == false
                ? false
                : true,
            user_has_module,
            approved_to_charge,
            require_training,
            is_trial_over: !is_on_trial,
            is_on_trial: is_on_trial,
            tier,
            selected_plan: true,
            current_tier,
            canceled: plan_canceled,
            canceled_access,
            paused,
            date_canceled,
            team_member_limit: team_member_limit || 0,
            team_member_count: team_member_count || 0,
            dealfinder_limit: dealfinder_limit || 0,
            dealfinder_count: dealfinder_count || 0,
            module_info: plan_module,
            plan_frequency: plan_frequency || "monthly",
            slug
          };
        }
      }
    }
    return {
      has_module: false,
      user_has_module: false,
      team_has_module: false,
      tier: 0,
      selected_plan: false,
      module_info: null,
      canceled: null,
      approved_to_charge: false,
      require_training: false,
      canceled_access: false,
      date_canceled: null,
      paused: null,
      is_trial_over: null,
      is_on_trial: null,
      team_member_limit: 0,
      team_member_count: 0,
      dealfinder_limit: 0,
      dealfinder_count: 0,
      plan_frequency: "monthly",
      slug
    };
  }
  // return {
  //   has_module: false,
  //   user_has_module: false,
  //   team_has_module: false,
  //   tier: 0,
  //   selected_plan: false,
  //   module_info: null,
  //   canceled: null,
  //   approved_to_charge: false,
  //   require_training: false,
  //   canceled_access: false,
  //   date_canceled: null,
  //   paused: null,
  //   is_trial_over: null,
  //   is_on_trial: null,
  //   team_member_limit: 0,
  //   team_member_count: 0,
  //   dealfinder_limit: 0,
  //   dealfinder_count: 0,
  //   plan_frequency: "monthly",
  //   slug
  // };
};

export const setFeatureModal = ({ slug, require_tier }) => {
  return {
    type: SET_FEATURE_MODAL,
    payload: {
      slug,
      require_tier
    }
  };
};
export const checkIfUserHasMetadata = meta_slug => {
  const source_of_truth = store.getState().billing?.source_of_truth;
  if (source_of_truth?.subscription?.metadata) {
    for (const [key, value] of Object.entries(
      source_of_truth?.subscription?.metadata
    )) {
      if (key == meta_slug) {
        return true;
      }
    }
  }
  return false;
};
export const checkIfUserHasBillingAddon = ({ billing_addons = [], slug }) => {
  for (let i = 0; i < billing_addons.length; i++) {
    if (billing_addons[i].slug === slug) {
      if (
        billing_addons[i].team_has_addon == 1 ||
        billing_addons[i].included_in_team_plan == 1
      ) {
        return true;
      }
    }
  }

  return false;
};

export const checkCanadaState = (item, states) => {
  for (var i = 0; i < states.length; i++) {
    if (item == states[i].value) {
      if (states[i].key >= 56) {
        return {
          type: CHECK_CANADA_STATE,
          payload: true
        };
      } else {
        return {
          type: CHECK_CANADA_STATE,
          payload: false
        };
      }
    }
  }
  return {
    type: CHECK_CANADA_STATE,
    payload: false
  };
};

const updatePaymentMethodsOrderFail = (dispatch, error) => {
  dispatch({
    type: UPDATE_PAYMENT_METHODS_ORDER_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const updatePaymentMethodsOrderSuccess = (dispatch, results) => {
  dispatch({
    type: UPDATE_PAYMENT_METHODS_ORDER_SUCCESS,
    payload: { cards: results }
  });
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "Payment method order was successfully updated.",
      title: "Success!"
    }
  });
};

export const deleteCard = (token, card_id, type = "delete_card") => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    apiv2
      .deleteCard({ token, payload: { stripe_card_id: card_id }, type })
      .then(response => {
        if (response.problem != null) {
          deleteCardFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          deleteCardFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });
          deleteCardSuccess(dispatch, response.data.results);
        }
      });
  };
};

const deleteCardFail = (dispatch, error) => {
  dispatch({
    type: DELETE_CARD_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const deleteCardSuccess = (dispatch, results) => {
  dispatch({
    type: DELETE_CARD_SUCCESS,
    payload: { cards: results }
  });
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "The card was successfully deleted.",
      title: "Success!"
    }
  });
};

export const makePrimaryCard = (token, card_id, type = "make_primary_card") => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    apiv2
      .makePrimaryCard({ token, payload: { stripe_card_id: card_id }, type })
      .then(response => {
        if (response.problem != null) {
          makePrimaryCardFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          makePrimaryCardFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });
          makePrimaryCardSuccess(dispatch, response.data.results);
        }
      });
  };
};

const makePrimaryCardFail = (dispatch, error) => {
  dispatch({
    type: MAKE_PRIMARY_CARD_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const makePrimaryCardSuccess = (dispatch, results) => {
  dispatch({
    type: MAKE_PRIMARY_CARD_SUCCESS,
    payload: { cards: results }
  });
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "The card was successfully updated.",
      title: "Success!"
    }
  });
};

export const updatePaymentMethodsOrder = (
  token,
  order,
  type = "update_payment_methods_order"
) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    apiv2
      .updatePaymentMethodsOrder({ token, payload: { order }, type })
      .then(response => {
        if (response.problem != null) {
          updatePaymentMethodsOrderFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          updatePaymentMethodsOrderFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });
          updatePaymentMethodsOrderSuccess(dispatch, response.data.results);
        }
      });
  };
};

export const markEditingCard = card_id => {
  return dispatch => {
    dispatch({ type: MARK_EDITING_CARD, payload: { card_id } });
  };
};

export const toggleCreditReload = toggle => {
  return {
    type: TOGGLE_CREDIT_RELOAD,
    payload: toggle
  };
};

export const setFreeTrialInfo = plan_modules => {
  let plans_on_trial = [];
  for (var i = 0; i < plan_modules.length; i++) {
    if (plan_modules[i].free_trial_info.is_on_trial) {
      plans_on_trial.push(plan_modules[i]);
    }
  }
  return {
    type: SET_FREE_TRIAL_INFO,
    payload: plans_on_trial
  };
};

export const getSourceOfTruth = ({ token, no_loading = false }) => {
  return dispatch => {
    executeSourceOfTruth({ token, no_loading, dispatch });
  };
};

export const executeSourceOfTruth = ({
  token,
  no_loading = false,
  dispatch
}) => {
  if (dispatch && token) {
    if (!no_loading) {
      dispatch({ type: GET_SOURCE_OF_TRUTH });
    }
    apiv2.getSourceOfTruth({ token }).then(response => {
      if (response.problem != null) {
        dispatch({ type: GET_SOURCE_OF_TRUTH_FAIL });
      } else if (response.data.error != false) {
        dispatch({ type: GET_SOURCE_OF_TRUTH_FAIL });
      } else {
        dispatch({
          type: GET_SOURCE_OF_TRUTH_SUCCESS,
          payload: response.data.results
        });

        appRedirect({
          dispatch,
          redirect: "source_of_truth",
          payload: response.data.results
        });
      }
    });
  }
};

export const getStripeCheckoutSession = ({
  token,
  items = null,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2.getStripeCheckoutSession({ token, items }).then(response => {
      if (response.problem != null) {
        onError(response.problem);
      } else if (response.data.error != false) {
        onError(response.data.error);
      } else {
        onSuccess(response.data.results);
      }
    });
  };
};

export const manageSubscription = ({
  token,
  type,
  stripe_price_id,
  stripe_price_ids,
  stripe_sub_id,
  offer_type,
  offer_id,
  force_interval,
  offer_promo,
  event_slug,
  assign_to,
  old_assigned_user,
  seat_type,
  license_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  no_messaging = false
}) => {
  return dispatch => {
    onLoading();

    apiv2
      .manageSubscription({
        token,
        type,
        stripe_price_id,
        stripe_price_ids,
        stripe_sub_id,
        offer_type,
        offer_id,
        force_interval,
        offer_promo,
        event_slug,
        assign_to,
        old_assigned_user,
        seat_type,
        license_id
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          onError(response.data.error);
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess(response.data.results);

          dispatch({
            type: GET_SOURCE_OF_TRUTH_SUCCESS,
            payload: response.data.results
          });

          if (!no_messaging) {
            switch (type) {
              default:
                break;
              case "add_to_subscription":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "Your subscription has been updated.",
                    title: "Success!"
                  }
                });
                break;
              case "end_trial":
              case "cancel_sub":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "Your subscription has been updated.",
                    title: "Success!"
                  }
                });
                break;
            }
          }

          appRedirect({
            dispatch,
            redirect: "source_of_truth",
            payload: response.data.results,
            onSuccess: () => {}
          });
        }
      });
  };
};

export const getStripePriceInformation = ({
  token,
  stripe_price_ids = null,
  type = "stripe_price_ids",
  slug = "",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2
      .getStripePriceInformation({ token, stripe_price_ids, type, slug })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const makeInAppPurchase = ({
  token,
  iap_id,
  platform,
  adapty_id,
  access_level_id,
  is_active,
  is_lifetime,
  will_renew,
  expires_at,
  starts_at,
  activated_at,
  unsubscribed_at,
  renewed_at,
  billing_issue_detected_att,
  is_in_grace_period,
  active_introductory_offer_type,
  active_promotional_offer_type,
  active_promotional_offer_id,
  cancellation_reason,
  is_refund,
  receipt,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2
      .makeInAppPurchase({
        token,
        iap_id,
        platform,
        adapty_id,
        access_level_id,
        is_active,
        is_lifetime,
        will_renew,
        expires_at,
        starts_at,
        activated_at,
        unsubscribed_at,
        renewed_at,
        billing_issue_detected_att,
        is_in_grace_period,
        active_introductory_offer_type,
        active_promotional_offer_type,
        active_promotional_offer_id,
        cancellation_reason,
        is_refund,
        receipt
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          dispatch({
            type: GET_SOURCE_OF_TRUTH_SUCCESS,
            payload: response.data.results
          });

          onSuccess(response.data.results);
        }
      });
  };
};

export const getUpsellButton = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2
      .getUpsellButton({
        token
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getCorrectSpecial = ({
  token,
  special,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2
      .getCorrectSpecial({
        token,
        special
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const checkIfUserHasSubscription = (u = null, s = null) => {
  let user = u;
  let source_of_truth = s;
  if (!u || !s) {
    user = store.getState().auth.user;
  }
  if (!s) {
    source_of_truth = store.getState().billing.source_of_truth;
  }

  if (
    user &&
    source_of_truth &&
    source_of_truth?.has_subscription &&
    user?.finished_onboarding == 1 &&
    user?.canceled != 1 &&
    user?.paused_account != 1 &&
    user?.bad_card != 1 &&
    source_of_truth?.subscription?.status !== "canceled" &&
    source_of_truth?.subscription?.status !== "past_due" &&
    source_of_truth?.subscription?.status !== "unpaid" &&
    source_of_truth?.subscription?.status !== "incomplete" &&
    source_of_truth?.subscription?.status !== "incomplete_expired" &&
    !source_of_truth?.subscription?.is_paused
  ) {
    return true;
  }

  return false;
};

export const checkIfOverTotalLeadsLimit = (
  source_of_truth,
  all_feature_metadata,
  count = 0
) => {
  const total_number_of_leads_current_limit = parseInt(
    source_of_truth?.subscription?.metadata?.total_number_of_leads
  );
  let total_number_of_leads_count = parseInt(
    source_of_truth?.current_limits?.total_number_of_leads?.amount
  );
  if (
    total_number_of_leads_current_limit !== "unlimited" &&
    total_number_of_leads_count + count >= total_number_of_leads_current_limit
  ) {
    const monthly_limit_end_date =
      source_of_truth?.subscription?.monthly_limit_end_date;
    let result = {
      total_number_of_leads_count,
      total_number_of_leads_current_limit,
      monthly_limit_end_date,
      feature_upsell_info: null
    };

    for (let i = 0; i < all_feature_metadata?.length; i++) {
      if (
        all_feature_metadata[i].slug === "total_number_of_leads" &&
        all_feature_metadata[i].unlock_limit <=
          total_number_of_leads_count + count &&
        (all_feature_metadata[i].unlock_limit >
          result.feature_upsell_info?.unlock_limit ||
          result.feature_upsell_info == null)
      ) {
        result.feature_upsell_info = all_feature_metadata[i];
      }
    }

    return result;
  }

  return null;
};
