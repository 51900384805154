import apisauce from "apisauce";
import {
  AppConfig,
  createCallSession,
  getFingerPrintVisitorID,
  getVoiceClone,
  updateVoiceClone
} from "app/NativeActions";
import axios from "axios";

const create = (baseURL = AppConfig().api_url) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      // Here if necessary
    },
    timeout: 90000
  });

  let cancelTokenSource;
  let cancelLeadsCountTokenSource;
  const login = (
    email,
    password,
    device,
    oAuthToken,
    oAuthIdToken,
    apple_id,
    branch_recovery_token,
    visitor_token = null
  ) =>
    api.post("v2/login/", {
      email,
      password,
      oAuthToken,
      oAuthIdToken,
      apple_id,
      branch_recovery_token,
      device: AppConfig().device, //mobile, desktop
      platform: AppConfig().platform, //desktop //ios //android
      app_version: AppConfig().app_version
    });

  const getUser = (token, device, include_deal_credits = true) =>
    api.get("v2/user/", {
      token: token,
      device: AppConfig().device,
      platform: AppConfig().platform, //desktop //ios //android
      app_version: AppConfig().app_version,
      include_deal_credits
    });
  const getUserFromRecoveryToken = ({ token, type, device }) =>
    api.get("billing/get-user-from-token/", {
      token,
      type,
      device
    });

  const getUserFromEmailToken = ({ token, device }) =>
    api.get("marketing/get-user-from-email-token/", {
      token,
      device
    });
  const getSourceOfTruth = ({ token }) =>
    api.get("billing/source-of-truth/", {
      token
    });
  const manageSubscription = ({
    token,
    type,
    stripe_price_id,
    stripe_price_ids,
    stripe_sub_id,
    offer_type,
    offer_id,
    force_interval,
    offer_promo,
    event_slug,
    assign_to,
    old_assigned_user,
    seat_type,
    license_id,
    card_token,
    attempt_payment
  }) =>
    api.post("billing/manage-subscription/", {
      token,
      type,
      stripe_price_id,
      stripe_price_ids,
      stripe_sub_id,
      offer_type,
      offer_id,
      force_interval,
      offer_promo,
      card_token,
      event_slug,
      assign_to,
      old_assigned_user,
      seat_type,
      license_id,
      attempt_payment
    });
  const getStripeCheckoutSession = ({ token, items = null }) =>
    api.get("billing/checkout/", {
      token,
      items,
      type: "get_session",
      success_url:
        AppConfig().api_url +
        "billing/websockets/?token=" +
        token +
        "&type=mobile_success",
      cancel_url:
        AppConfig().api_url +
        "billing/websockets/?token=" +
        token +
        "&type=mobile_cancel"
    });

  const getStripePriceInformation = ({
    token,
    stripe_price_ids = null,
    slug = null,
    type
  }) =>
    api.get("billing/plan-information/", {
      token,
      stripe_price_ids,
      slug,
      type
    });

  const makeInAppPurchase = ({
    token,
    iap_id,
    platform,
    adapty_id,
    access_level_id,
    is_active,
    is_lifetime,
    will_renew,
    expires_at,
    starts_at,
    activated_at,
    unsubscribed_at,
    renewed_at,
    billing_issue_detected_att,
    is_in_grace_period,
    active_introductory_offer_type,
    active_promotional_offer_type,
    active_promotional_offer_id,
    cancellation_reason,
    is_refund,
    receipt
  }) =>
    api.post("billing/iap-success/", {
      token,
      iap_id,
      platform,
      adapty_id,
      access_level_id,
      is_active,
      is_lifetime,
      will_renew,
      expires_at,
      starts_at,
      activated_at,
      unsubscribed_at,
      renewed_at,
      billing_issue_detected_att,
      is_in_grace_period,
      active_introductory_offer_type,
      active_promotional_offer_type,
      active_promotional_offer_id,
      cancellation_reason,
      is_refund,
      receipt
    });

  const getCorrectSpecial = ({ token, special }) =>
    api.get("billing/get-special/", {
      token,
      special
    });

  const getUpsellButton = ({ token }) =>
    api.get("billing/upsell-button/", {
      token
    });

  const editUser = (token, type, payload) => {
    let endpoint = "v2/user/";

    switch (type) {
      case "user_settings":
        endpoint += "settings/";
        break;

      default:
        endpoint += "";
    }
    return api.post(endpoint, {
      token: token,
      type: type,
      payload: payload
    });
  };

  const setFcmToken = (token, fcm_token) => {
    let endpoint = "v2/user/";

    return api.post(endpoint, {
      token: token,
      type: "fcm_token",
      payload: { fcm_token: fcm_token }
    });
  };

  const analytics = ({
    token,
    type,
    start_date,
    end_date,
    date_option,
    filters
  }) =>
    api.get("v2/analytics/", {
      token: token,
      type: type,
      start_date: start_date,
      end_date: end_date,
      date_option: date_option
    });

  const signup = ({
    email,
    password,
    phone,
    check_phone,
    firstname,
    lastname,
    company,
    image,
    city,
    accepted_terms,
    affiliate_partner,
    team_id,
    promo,
    from_campaign,
    from_source,
    branch_id,
    device,
    platform,
    interested_in,
    google_analytics_client_id,
    referring_domain,
    utm_referrer,
    signupKey,
    oAuthToken,
    oAuthIdToken,
    apple_id,
    deals_last_12_mo_signup,
    first_promoter_ref,
    first_promoter_cookie,
    hubspot_cookie,
    hubspot_page_url,
    hubspot_page_title,
    ip_address,
    visitor_token
  }) =>
    api.post("v2/login/", {
      email: email,
      password: password,
      phone: phone,
      check_phone: check_phone,
      firstname: firstname,
      lastname: lastname,
      company: company,
      image,
      city: city,
      team_id: team_id,
      promo: promo,
      from_campaign: from_campaign,
      from_source: from_source,
      signupKey: signupKey,
      branch_id: branch_id,
      accepted_terms: accepted_terms,
      affiliate_partner: affiliate_partner,
      device,
      platform,
      user_version: AppConfig().user_version,
      app_version: AppConfig().app_version,
      interested_in,
      google_analytics_client_id,
      referring_domain,
      utm_referrer,
      oAuthToken,
      oAuthIdToken,
      apple_id,
      deals_last_12_mo_signup,
      first_promoter_ref,
      first_promoter_cookie,
      hubspot_cookie,
      hubspot_page_url,
      hubspot_page_title,
      ip_address,
      visitor_token
    });

  const exportLeads = ({
    token,
    select_all,
    total_count,
    new_filters,
    emails,
    list_id,
    list_history_id,
    search,
    search_type,
    deal_ids,
    selected_columns,
    include_all_columns,
    export_type,
    include_likely_owners,
    include_family,
    include_likely_renters,
    include_potential_property_owners,
    scrub_dnc,
    scrub_landline,
    scrub_wireless,
    deduplicate,
    remove_items_without_phone_numbers,
    export_file_name,
    property_flags,
    property_flags_and_or
  }) =>
    api.post("v2/list/", {
      token,
      type: "export_v2",
      select_all,
      total_count,
      new_filters,
      emails,
      list_id,
      search,
      search_type,
      list_history_id,
      lead_ids: deal_ids,
      selected_columns,
      include_all_columns,
      property_flags,
      property_flags_and_or,
      export_type,
      include_likely_owners,
      include_family,
      include_likely_renters,
      include_potential_property_owners,
      scrub_dnc,
      scrub_landline,
      scrub_wireless,
      deduplicate,
      remove_items_without_phone_numbers,
      export_file_name
    });

  const getExportActualCount = ({
    token,
    select_all,
    total_count,
    new_filters,
    list_id,
    list_history_id,
    search,
    search_type,
    deal_ids,

    property_flags,
    property_flags_and_or
  }) =>
    api.post("v2/list/", {
      token,
      type: "export_actual_count",
      select_all,
      total_count,
      new_filters,
      list_id,
      search,
      search_type,
      list_history_id,
      lead_ids: deal_ids,
      property_flags,
      property_flags_and_or
    });

  const listProperties = ({
    token,
    type,
    sort_by,
    limit,
    begin,
    route_id,
    list_id,
    filter_lists,
    filters = {},
    lead_ids
  }) =>
    api.post("v2/list/", {
      token,
      sort_by,
      limit,
      begin,
      route_id,
      list_id,
      type,
      lead_ids,
      filter_list_ids: filter_lists
        ? filter_lists
            .map(list => {
              return list.id;
            })
            .join(",")
        : filters?.list_ids
        ? filters.list_ids
            .map(list => {
              return list.id;
            })
            .join(",")
        : "",

      deal_status_ids: filters.deal_status_ids,
      mail_template_ids: filters.mail_template_ids,
      campaign_ids: filters.campaign_ids,
      campaign_step: filters.campaign_step,
      campaign_status: filters.campaign_status,
      lead_source: filters.lead_source,
      added_by_ids: filters.added_by_ids,
      lead_owner_ids: filters.lead_owner_ids,
      added_type: filters.added_type,
      filter_tag_ids: filters.tag_ids,
      min_date_added: filters.min_date_added,
      max_date_added: filters.max_date_added,
      date_added_dropdown: filters.date_added_dropdown,
      min_last_exported_date: filters.min_last_exported_date,
      max_last_exported_date: filters.max_last_exported_date,
      last_exported_date_dropdown: filters.last_exported_date_dropdown,
      times_mailed_min: filters.times_mailed_min,
      times_mailed_max: filters.times_mailed_max,
      did_skip_trace: filters.did_skip_trace,
      skip_traced_by: filters.skip_traced_by,
      min_date_skip_traced: filters.min_date_skip_traced,
      max_date_skip_traced: filters.max_date_skip_traced,
      scheduled_to_send_min: filters.scheduled_to_send_min,
      scheduled_to_send_max: filters.scheduled_to_send_max,
      include_image: filters.include_image,
      usps_address: filters.usps_address,
      sending_queue: filters.sending_queue,

      owner_status: filters.owner_status,
      filter_property_address: filters.filter_property_address,
      filter_property_city: filters.filter_property_city,
      filter_property_fips: filters.filter_property_fips,
      filter_property_state: filters.filter_property_state,
      filter_property_zip: filters.filter_property_zip,
      filter_owner_name: filters.filter_owner_name,
      filter_owner_address: filters.filter_owner_address,
      filter_owner_city: filters.filter_owner_city,
      filter_owner_state: filters.filter_owner_state,
      filter_owner_zip: filters.filter_owner_zip,
      min_saleprice: filters.min_saleprice,
      max_saleprice: filters.max_saleprice,
      saleprice_empty: filters.saleprice_empty,
      min_saledate: filters.min_saledate,
      max_saledate: filters.max_saledate,
      saledate_empty: filters.saledate_empty,
      saledate_dropdown: filters.saledate_dropdown,
      saledate_dropdown_empty: filters.saledate_dropdown_empty,

      min_total_value: filters.min_total_value,
      max_total_value: filters.max_total_value,
      total_value_empty: filters.total_value_empty,
      min_mortgage_amount: filters.min_mortgage_amount,
      max_mortgage_amount: filters.max_mortgage_amount,
      mortgage_amount_empty: filters.mortgage_amount_empty,

      min_building_size: filters.min_building_size,
      max_building_size: filters.max_building_size,
      building_size_empty: filters.building_size_empty,
      min_lot_acreage: filters.min_lot_acreage,
      max_lot_acreage: filters.max_lot_acreage,
      lot_acreage_empty: filters.lot_acreage_empty,
      min_units_count: filters.min_units_count,
      max_units_count: filters.max_units_count,
      units_count_empty: filters.units_count_empty,
      min_year_built: filters.min_year_built,
      max_year_built: filters.max_year_built,
      year_built_empty: filters.year_built_empty,
      min_bedrooms: filters.min_bedrooms,
      max_bedrooms: filters.max_bedrooms,
      bedrooms_empty: filters.bedrooms_empty,
      min_bathrooms: filters.min_bathrooms,
      max_bathrooms: filters.max_bathrooms,
      bathrooms_empty: filters.bathrooms_empty,

      min_equity_percent: filters.min_equity_percent,
      max_equity_percent: filters.max_equity_percent,
      equity_percent_empty: filters.equity_percent_empty,
      vacancy_type: filters.vacancy_type,
      tax_delinquency: filters.tax_delinquency,
      preforeclosure_type: filters.preforeclosure_type,

      custom_fields: filters.custom_fields
    });

  const getLeads = ({
    token,
    type,
    sort_by,
    limit,
    begin,
    search,
    search_type,
    filters,
    old_filters = null,
    route_id,
    lead_ids,
    list_id,
    property_flags,
    property_flags_and_or,
    get_updated_data = false,
    list_history_id = null,
    noCancel = false
  }) => {
    // Cancel the previous request if it's still in progress
    if (type !== "count" && !noCancel) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled due to new request.");
      }
      // Create a new cancel token source
      cancelTokenSource = axios.CancelToken.source();
    } else if (!noCancel) {
      if (cancelLeadsCountTokenSource && !noCancel) {
        cancelLeadsCountTokenSource.cancel(
          "Operation canceled due to new request."
        );
      }
      cancelLeadsCountTokenSource = axios.CancelToken.source();
    }

    return api
      .post(
        "v2/leads/",
        {
          token,
          type,
          sort_by,
          limit,
          begin,
          search,
          search_type,
          filters,
          old_filters,
          route_id,
          lead_ids,
          list_id,
          property_flags,
          property_flags_and_or,
          get_updated_data,
          list_history_id
        },
        noCancel
          ? {}
          : type !== "count"
          ? { cancelToken: cancelTokenSource.token }
          : { cancelToken: cancelLeadsCountTokenSource.token }
      )
      .then(response => {
        if (response.ok) {
          return response;
        } else {
          throw new Error(response.problem);
        }
      })
      .catch(error => {
        if (error == "Error: CANCEL_ERROR") {
          console.log("Request canceled:", error.message);
          // Return a resolved promise to avoid unhandled rejection
          return Promise.resolve();
        } else {
          console.error("Error:", error.message);
          throw error;
        }
      })
      .finally(() => {
        // Reset the cancel token source after the request is done
        if (type !== "count") {
          cancelTokenSource = null;
        } else {
          cancelLeadsCountTokenSource = null;
        }
      });
  };

  const updateListSettings = ({ token, type, user_list_columns }) =>
    api.post("v2/leads/", {
      token,
      type,
      user_list_columns
    });

  const willHighlight = filters => {
    for (let key in filters) {
      if (filters[key] && filters[key] !== "") {
        return "true";
      }
    }
    return "false";
  };

  const mapProperties = ({
    token,
    bounds,
    property_bounds,
    limit,
    mobile,
    heatmap,
    map_type,
    routes_only,
    route_plan,
    dont_include_properties,
    dont_include_deals,
    dont_include_route_sections,
    filters = {},
    route_id = null
  }) =>
    api.get("v2/map/", {
      token: token,
      map_type: map_type,
      routes_only: routes_only,
      route_plan: route_plan,
      limit: limit,
      mobile: mobile,
      heatmap: heatmap,
      exclude_all_info: true,
      westLng: bounds.westLng,
      southLat: bounds.southLat,
      eastLng: bounds.eastLng,
      northLat: bounds.northLat,
      centerLat: bounds.centerLat,
      centerLng: bounds.centerLng,
      bounds: bounds,
      property_bounds: property_bounds,
      route_id: route_id,

      highlight: willHighlight(filters),
      owner_status: filters.owner_status,
      usps_address: filters.usps_address,
      filter_property_address: filters.filter_property_address,
      filter_property_city: filters.filter_property_city,
      filter_property_fips: filters.filter_property_fips,
      filter_property_state: filters.filter_property_state,
      filter_property_zip: filters.filter_property_zip,
      filter_owner_name: filters.filter_owner_name,
      filter_owner_address: filters.filter_owner_address,
      filter_owner_city: filters.filter_owner_city,
      filter_owner_state: filters.filter_owner_state,
      filter_owner_zip: filters.filter_owner_zip,
      min_saleprice: filters.min_saleprice,
      max_saleprice: filters.max_saleprice,
      saleprice_empty: filters.saleprice_empty,
      min_saledate: filters.min_saledate,
      max_saledate: filters.max_saledate,
      saledate_empty: filters.saledate_empty,
      saledate_dropdown: filters.saledate_dropdown,
      saledate_dropdown_empty: filters.saledate_dropdown_empty,

      min_total_value: filters.min_total_value,
      max_total_value: filters.max_total_value,
      total_value_empty: filters.total_value_empty,
      min_mortgage_amount: filters.min_mortgage_amount,
      max_mortgage_amount: filters.max_mortgage_amount,
      mortgage_amount_empty: filters.mortgage_amount_empty,

      min_building_size: filters.min_building_size,
      max_building_size: filters.max_building_size,
      building_size_empty: filters.building_size_empty,
      min_lot_acreage: filters.min_lot_acreage,
      max_lot_acreage: filters.max_lot_acreage,
      lot_acreage_empty: filters.lot_acreage_empty,
      min_units_count: filters.min_units_count,
      max_units_count: filters.max_units_count,
      units_count_empty: filters.units_count_empty,
      min_year_built: filters.min_year_built,
      max_year_built: filters.max_year_built,
      year_built_empty: filters.year_built_empty,
      min_bedrooms: filters.min_bedrooms,
      max_bedrooms: filters.max_bedrooms,
      bedrooms_empty: filters.bedrooms_empty,
      min_bathrooms: filters.min_bathrooms,
      max_bathrooms: filters.max_bathrooms,
      bathrooms_empty: filters.bathrooms_empty,
      min_equity_percent: filters.min_equity_percent,
      max_equity_percent: filters.max_equity_percent,
      equity_percent_empty: filters.equity_percent_empty,
      vacancy_type: filters.vacancy_type,
      tax_delinquency: filters.tax_delinquency,
      preforeclosure_type: filters.preforeclosure_type,
      custom_fields: JSON.stringify(filters.custom_fields),
      list_ids: filters?.list_ids,
      dont_include_properties,
      dont_include_deals,
      dont_include_route_sections
    });
  const completePlannedProperty = ({ token, prev_id, planned_route_id }) =>
    api.get("v2/next-planned-property/", {
      token: token,
      type: "completed",
      prev_id: prev_id,
      planned_route_id: planned_route_id
    });

  const nextPlannedProperty = ({
    token,
    prev_id,
    task_id,
    planned_route_id,
    dont_include_properties,
    dont_include_deals,
    dont_include_route_sections,
    dont_include,
    filters = {},
    new_filters,
    list_id,
    user_location = {},
    location_type = false,
    zip = false,
    state = false,
    city = false,
    drawing_coordinates = false
  }) =>
    api.post("v2/next-planned-property/", {
      token: token,
      prev_id: prev_id,
      task_id: task_id,
      planned_route_id: planned_route_id,
      user_location: user_location,
      centerLat: user_location?.latitude,
      centerLng: user_location?.longitude,
      owner_status: filters.owner_status,
      type: "next",
      new_filters,
      list_id,
      filter_property_address: filters.filter_property_address,
      filter_property_city: filters.filter_property_city,
      filter_property_state: filters.filter_property_state,
      filter_property_zip: filters.filter_property_zip,
      filter_owner_name: filters.filter_owner_name,
      filter_owner_address: filters.filter_owner_address,
      filter_owner_city: filters.filter_owner_city,
      filter_owner_state: filters.filter_owner_state,
      filter_owner_zip: filters.filter_owner_zip,
      min_saleprice: filters.min_saleprice,
      max_saleprice: filters.max_saleprice,
      saleprice_empty: filters.saleprice_empty,
      min_saledate: filters.min_saledate,
      max_saledate: filters.max_saledate,
      saledate_empty: filters.saledate_empty,
      saledate_dropdown: filters.saledate_dropdown,
      saledate_dropdown_empty: filters.saledate_dropdown_empty,

      min_total_value: filters.min_total_value,
      max_total_value: filters.max_total_value,
      total_value_empty: filters.total_value_empty,
      min_mortgage_amount: filters.min_mortgage_amount,
      max_mortgage_amount: filters.max_mortgage_amount,
      mortgage_amount_empty: filters.mortgage_amount_empty,

      min_building_size: filters.min_building_size,
      max_building_size: filters.max_building_size,
      building_size_empty: filters.building_size_empty,
      min_lot_acreage: filters.min_lot_acreage,
      max_lot_acreage: filters.max_lot_acreage,
      lot_acreage_empty: filters.lot_acreage_empty,
      min_units_count: filters.min_units_count,
      max_units_count: filters.max_units_count,
      units_count_empty: filters.units_count_empty,
      min_year_built: filters.min_year_built,
      max_year_built: filters.max_year_built,
      year_built_empty: filters.year_built_empty,
      min_bedrooms: filters.min_bedrooms,
      max_bedrooms: filters.max_bedrooms,
      bedrooms_empty: filters.bedrooms_empty,
      min_bathrooms: filters.min_bathrooms,
      max_bathrooms: filters.max_bathrooms,
      bathrooms_empty: filters.bathrooms_empty,
      min_equity_percent: filters.min_equity_percent,
      max_equity_percent: filters.max_equity_percent,
      equity_percent_empty: filters.equity_percent_empty,
      vacancy_type: filters.vacancy_type,
      tax_delinquency: filters.tax_delinquency,
      preforeclosure_type: filters.preforeclosure_type,
      list_ids: filters.list_ids,
      dont_include_properties,
      dont_include_deals,
      dont_include_route_sections,
      dont_include,
      location_type: location_type,
      zip: zip,
      state: state,
      city: city,
      drawing_coordinates: drawing_coordinates
    });
  const nextPlannedPropertyV2 = ({
    token,
    prev_id,
    task_id,
    planned_route_id,
    dont_include_properties,
    dont_include_deals,
    dont_include_route_sections,
    dont_include,
    filters = {},
    new_filters,
    list_id,
    user_location = {},
    location_type = false,
    zip = false,
    state = false,
    city = false,
    drawing_coordinates = false,
    property_flags,
    property_types,
    property_flags_and_or,
    value_range_min,
    value_range_max,
    price_type,
    beds_min,
    baths_min,
    use_beds_exact,
    search_locations
  }) =>
    api.post("v2/next-planned-property-v2/", {
      token: token,
      prev_id: prev_id,
      task_id: task_id,
      planned_route_id: planned_route_id,
      user_location: user_location,
      centerLat: user_location?.latitude,
      centerLng: user_location?.longitude,
      owner_status: filters.owner_status,
      type: "next",
      new_filters,
      list_id,
      filter_property_address: filters.filter_property_address,
      filter_property_city: filters.filter_property_city,
      filter_property_state: filters.filter_property_state,
      filter_property_zip: filters.filter_property_zip,
      filter_owner_name: filters.filter_owner_name,
      filter_owner_address: filters.filter_owner_address,
      filter_owner_city: filters.filter_owner_city,
      filter_owner_state: filters.filter_owner_state,
      filter_owner_zip: filters.filter_owner_zip,
      min_saleprice: filters.min_saleprice,
      max_saleprice: filters.max_saleprice,
      saleprice_empty: filters.saleprice_empty,
      min_saledate: filters.min_saledate,
      max_saledate: filters.max_saledate,
      saledate_empty: filters.saledate_empty,
      saledate_dropdown: filters.saledate_dropdown,
      saledate_dropdown_empty: filters.saledate_dropdown_empty,

      min_total_value: filters.min_total_value,
      max_total_value: filters.max_total_value,
      total_value_empty: filters.total_value_empty,
      min_mortgage_amount: filters.min_mortgage_amount,
      max_mortgage_amount: filters.max_mortgage_amount,
      mortgage_amount_empty: filters.mortgage_amount_empty,

      min_building_size: filters.min_building_size,
      max_building_size: filters.max_building_size,
      building_size_empty: filters.building_size_empty,
      min_lot_acreage: filters.min_lot_acreage,
      max_lot_acreage: filters.max_lot_acreage,
      lot_acreage_empty: filters.lot_acreage_empty,
      min_units_count: filters.min_units_count,
      max_units_count: filters.max_units_count,
      units_count_empty: filters.units_count_empty,
      min_year_built: filters.min_year_built,
      max_year_built: filters.max_year_built,
      year_built_empty: filters.year_built_empty,
      min_bedrooms: filters.min_bedrooms,
      max_bedrooms: filters.max_bedrooms,
      bedrooms_empty: filters.bedrooms_empty,
      min_bathrooms: filters.min_bathrooms,
      max_bathrooms: filters.max_bathrooms,
      bathrooms_empty: filters.bathrooms_empty,
      min_equity_percent: filters.min_equity_percent,
      max_equity_percent: filters.max_equity_percent,
      equity_percent_empty: filters.equity_percent_empty,
      vacancy_type: filters.vacancy_type,
      tax_delinquency: filters.tax_delinquency,
      preforeclosure_type: filters.preforeclosure_type,
      list_ids: filters.list_ids,
      dont_include_properties,
      dont_include_deals,
      dont_include_route_sections,
      dont_include,
      location_type: location_type,
      zip: zip,
      state: state,
      city: city,
      drawing_coordinates: drawing_coordinates,
      property_flags,
      property_types,
      property_flags_and_or,
      value_range_min,
      value_range_max,
      price_type,
      beds_min,
      baths_min,
      use_beds_exact,
      search_locations
    });

  const getProperty = ({ token, property_id, deal_id, type }) =>
    api.get("v2/property/", {
      token: token,
      deal_id: deal_id,
      property_id: property_id,
      type: type
    });

  const matchProperty = ({ token, type = "match_property", property_id }) =>
    api.post("v2/property/", {
      token: token,
      type: type,
      property_id: property_id
    });

  const getMorePropertyInfo = ({
    token,
    type,
    owner_hash,
    contact_id,
    company_number,
    jurisdiction_code
  }) =>
    api.get("v2/property-info/", {
      token,
      type,
      owner_hash,
      contact_id,
      company_number,
      jurisdiction_code
    });

  const reverseGeocode = ({ token, coordinate, no_property = false }) =>
    api.get("v2/reverse-geocode/", {
      token: token,
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
      no_property
    });

  const autocomplete = ({ token, search, limit, type }) =>
    api.get("v2/autocomplete/", {
      token: token,
      search: search,
      limit: limit,
      type: type,
      include_external_provider: true
    });

  const autocompleteMap = ({
    token,
    search,
    centerLat = null,
    centerLng = null,
    types = null
  }) =>
    api.get("v2/autocomplete-map/", {
      token,
      search,
      centerLat,
      centerLng,
      types
    });

  const getRecentSearch = ({ token, search_type }) =>
    api.get("v2/recent-search/", {
      token,
      search_type
    });
  const saveRecentSearch = ({
    token,
    search_type,
    full_text,
    search_text,
    property_id,
    lead_id,
    result_type,
    data,
    latitude,
    longitude,
    location_type
  }) =>
    api.post("v2/recent-search/", {
      token,
      type: "save_recent_search",
      search_type,
      full_text,
      search_text,
      property_id,
      lead_id,
      result_type,
      data,
      latitude,
      longitude,
      location_type
    });

  const addDeal = ({
    token,
    property,
    route_id,
    address,
    address2,
    city,
    state,
    zip,
    devicetype = "mobile"
  }) =>
    api.post("v2/add-deal/", {
      token: token,
      property: property,
      route_id: route_id,
      address: address,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
      devicetype: devicetype
    });

  const getAllStatuses = ({ token }) =>
    api.get("v2/deal-status/", {
      token: token
    });

  const getPropertyFIPS = ({ token, state, search, begin, limit }) =>
    api.get("v2/property-fips/", {
      token: token,
      state,
      search,
      begin,
      limit
    });

  const getPropertyCities = ({ token, state, search, begin, limit }) =>
    api.get("v2/property-cities/", {
      token: token,
      state,
      search,
      begin,
      limit
    });

  const getPropertyZips = ({ token, state, search, begin, limit }) =>
    api.get("v2/property-zips/", {
      token: token,
      state,
      search,
      begin,
      limit
    });

  const getPropertyComps = ({
    token,
    property_id,
    sale_date_min,
    sale_date_max,
    range_miles = 3,
    sqft_min = 100,
    sqft_max = 1000,
    price_min = 100,
    limit = 100,
    bedroom_min = null,
    bedroom_max = null,
    bathroom_min = null,
    bathroom_max = null
  }) =>
    api.get(
      `v2/comps/?property_id=${property_id}&sale_date_min=${sale_date_min}&sale_date_max=${sale_date_max}&range_miles=${range_miles}&sqft_min=${
        sqft_min || 0
      }&sqft_max=${
        sqft_max || 100000
      }&price_min=${price_min}&limit=${limit}&bedroom_min=${
        bedroom_min || ""
      }&bedroom_max=${bedroom_max || ""}&bathroom_min=${
        bathroom_min || ""
      }&bathroom_max=${bathroom_max || ""}`,
      {
        token: token
      }
    );

  const getComps = ({
    token,
    property_id,
    latitude,
    longitude,
    sale_date_min,
    sale_date_max,
    range_miles = 3,
    sqft_min = null,
    sqft_max = null,
    price_min = 100,
    limit = 100,
    bedroom_min = null,
    bedroom_max = null,
    bathroom_min = null,
    bathroom_max = null,
    year_built_min = null,
    year_built_max = null,
    sort_by,
    source_type,
    dominant_source,
    drawing_coordinates = null
  }) =>
    api.post("v2/get-comps/", {
      token: token,
      property_id,
      latitude,
      longitude,
      sale_date_min,
      sale_date_max,
      range_miles,
      sqft_min,
      sqft_max,
      price_min,
      limit,
      bedroom_min,
      bedroom_max,
      bathroom_min,
      bathroom_max,
      year_built_min,
      year_built_max,
      sort_by,
      source_type,
      dominant_source,
      drawing_coordinates
    });

  const getAffiliateDashboard = ({ token }) =>
    api.get("v2/affiliate/dashboard/", {
      token: token
    });

  /* route tracking */
  const startTrackedRoute = ({
    token,
    type = "start_route",
    drive_type = "free_drive"
  }) =>
    api.post("v2/routes/", {
      token,
      type,
      drive_type
    });

  const getTrackedRoutes = ({
    token,
    begin,
    route_id,
    filters = {},
    filter_user,
    sort_by,
    search,
    start_date,
    end_date
  }) =>
    api.get("v2/routes/", {
      token,
      begin,
      route_id,
      filter_team_member: filters?.route_team_member,
      filter_start_date: filters?.start_date,
      filter_end_date: filters?.end_date,
      filter_user,
      sort_by,
      search,
      start_date,
      end_date
    });

  const restoreCurrentRoute = ({ token }) =>
    api.get("v2/routes/", {
      token,
      type: "restore_tracking_route"
    });

  const updateTrackedRoute = ({
    token,
    type = "update_route",
    route_id,
    total_miles,
    coordinates
  }) =>
    api.post("v2/routes/", {
      token,
      type,
      route_id,
      total_miles,
      coordinates
    });

  const stopTrackedRoute = ({
    token,
    type = "stop_route",
    route_id,
    total_miles,
    coordinates
  }) =>
    api.post("v2/routes/", {
      token,
      type,
      route_id,
      total_miles,
      coordinates
    });

  const removeTrackedRoute = ({
    token,
    type = "remove_route",
    route_id,
    route_type
  }) =>
    api.post("v2/routes/", {
      token,
      type,
      route_id,
      route_type
    });

  const startPlannedRoute = ({ token, route_id, type = "start_route" }) =>
    api.post("v2/planned-routes/", {
      token,
      type,
      route_id
    });

  const stopPlannedRoute = ({ token, planned_route_id, type = "stop_route" }) =>
    api.post("v2/planned-routes/", {
      token,
      type,
      planned_route_id
    });

  const updatePlannedRoute = ({
    token,
    type = "update_route",
    route_id,
    planned_route_id,
    coordinates
  }) =>
    api.post("v2/planned-routes/", {
      token,
      type,
      route_id,
      planned_route_id,
      coordinates
    });

  const getPausePlanInfo = ({ token, module_type }) =>
    api.get("v2/cancel-module/", {
      token,
      module_type
    });

  const pauseOrCancelPlan = ({
    token,
    type,
    module_type,
    slug,
    canceled_reason
  }) =>
    api.post("v2/cancel-module/", {
      token,
      type,
      canceled_reason,
      module_type,
      slug
    });

  const getTutorial = ({ token, tutorial_slug }) =>
    api.get("v2/tutorial/", {
      token,
      tutorial_slug
    });

  const completeTutorial = ({ token, tutorial_slug }) =>
    api.post("v2/tutorial/", {
      token,
      type: "complete_tutorial",
      tutorial_slug
    });

  const getLists = ({
    token,
    search,
    sort_by,
    filter,
    no_count = false,
    type,
    list_id,
    begin,
    limit
  }) =>
    api.get("v2/update-list/", {
      token,
      search,
      sort_by,
      filter,
      no_count,
      type,
      list_id,
      begin,
      limit
    });

  const updateList = ({
    token,
    type,
    title,
    list_id,
    list_ids,
    location_type,
    zip,
    city,
    state,
    drawing_coordinates,
    filters,
    list_type,
    smart_list,
    start_marketing,
    list_preset,
    list_filters,
    list_area_type,
    list_area,
    list_area_2,
    list_geo_fence,
    estimated_count
  }) =>
    api.post("v2/update-list/", {
      token,
      type,
      title,
      list_id,
      list_ids,
      location_type,
      zip,
      city,
      state,
      drawing_coordinates,
      filters,
      list_type,
      smart_list,
      start_marketing,
      list_preset,
      list_filters,
      list_area_type,
      list_area,
      list_area_2,
      list_geo_fence,
      estimated_count
    });

  const updateLead = ({
    token,
    type,
    select_all,
    total_count,
    filter_lists,
    filters = {},
    new_filters,
    using_old_filters,
    list_id,
    search,
    deal_ids,
    deal_status,
    deal_status_slug,
    list_ids,
    selected_columns,
    tag_ids,
    campaign_id,
    mail_template_id,
    resend_freq,
    resend_limit,
    start_mailers,
    new_list_name = null,
    new_tag_name = null,
    closed_date,
    purchase_profit,
    purchase_price,
    purchase_exit_strategy,
    offer_accepted_date,
    purchase_notes,
    resale_price,
    closing_fees,
    other_expenses,
    credit = 0,
    credit_update_type,
    custom_field_id,
    value,
    property_data_id,
    assign_to_id,
    accept_new_owner = 0,
    list_history_id = null,
    get_updated_data,
    property_flags,
    property_flags_and_or,
    call_results
  }) =>
    api.post("v2/update-lead/", {
      token,
      type,
      select_all,
      total_count,
      deal_ids,
      deal_status,
      deal_status_slug,
      list_ids,
      selected_columns,
      tag_ids,
      campaign_id,
      mail_template_id,
      resend_freq,
      resend_limit,
      start_mailers,
      new_list_name,
      new_tag_name,
      closed_date,
      purchase_profit,
      purchase_price,
      purchase_exit_strategy,
      offer_accepted_date,
      purchase_notes,
      resale_price,
      closing_fees,
      other_expenses,
      credit,
      credit_update_type,
      custom_field_id,
      value,
      assign_to_id,
      accept_new_owner,
      list_history_id,
      list_id,
      search,
      get_updated_data,
      property_flags,
      property_flags_and_or,
      new_filters,
      using_old_filters,
      filter_list_ids: filter_lists
        ? filter_lists
            .map(list => {
              return list.id;
            })
            .join(",")
        : filters?.list_ids
        ? filters.list_ids
        : "",
      deal_status_ids: filters.deal_status_ids,
      mail_template_ids: filters.mail_template_ids,
      campaign_ids: filters.campaign_ids,
      campaign_step: filters.campaign_step,
      campaign_status: filters.campaign_status,
      lead_source: filters.lead_source,
      added_by_ids: filters.added_by_ids,
      lead_owner_ids: filters.lead_owner_ids,
      added_type: filters.added_type,

      filter_tag_ids: filters.tag_ids,
      min_date_added: filters.min_date_added,
      max_date_added: filters.max_date_added,
      date_added_dropdown: filters.date_added_dropdown,
      min_last_exported_date: filters.min_last_exported_date,
      max_last_exported_date: filters.max_last_exported_date,
      last_exported_date_dropdown: filters.last_exported_date_dropdown,
      times_mailed_min: filters.times_mailed_min,
      times_mailed_max: filters.times_mailed_max,
      did_skip_trace: filters.did_skip_trace,
      skip_traced_by: filters.skip_traced_by,
      min_date_skip_traced: filters.min_date_skip_traced,
      max_date_skip_traced: filters.max_date_skip_traced,
      scheduled_to_send_min: filters.scheduled_to_send_min,
      scheduled_to_send_max: filters.scheduled_to_send_max,
      include_image: filters.include_image,
      usps_address: filters.usps_address,
      sending_queue: filters.sending_queue,
      owner_status: filters.owner_status,
      filter_property_address: filters.filter_property_address,
      filter_property_city: filters.filter_property_city,
      filter_property_state: filters.filter_property_state,
      filter_property_zip: filters.filter_property_zip,
      filter_property_fips: filters.filter_property_fips,
      filter_owner_name: filters.filter_owner_name,
      filter_owner_address: filters.filter_owner_address,
      filter_owner_city: filters.filter_owner_city,
      filter_owner_state: filters.filter_owner_state,
      filter_owner_zip: filters.filter_owner_zip,
      min_saleprice: filters.min_saleprice,
      max_saleprice: filters.max_saleprice,
      saleprice_empty: filters.saleprice_empty,
      min_saledate: filters.min_saledate,
      max_saledate: filters.max_saledate,
      saledate_empty: filters.saledate_empty,
      saledate_dropdown: filters.saledate_dropdown,
      saledate_dropdown_empty: filters.saledate_dropdown_empty,

      min_total_value: filters.min_total_value,
      max_total_value: filters.max_total_value,
      total_value_empty: filters.total_value_empty,
      min_mortgage_amount: filters.min_mortgage_amount,
      max_mortgage_amount: filters.max_mortgage_amount,
      mortgage_amount_empty: filters.mortgage_amount_empty,

      min_building_size: filters.min_building_size,
      max_building_size: filters.max_building_size,
      building_size_empty: filters.building_size_empty,
      min_lot_acreage: filters.min_lot_acreage,
      max_lot_acreage: filters.max_lot_acreage,
      lot_acreage_empty: filters.lot_acreage_empty,
      min_units_count: filters.min_units_count,
      max_units_count: filters.max_units_count,
      units_count_empty: filters.units_count_empty,
      min_year_built: filters.min_year_built,
      max_year_built: filters.max_year_built,
      year_built_empty: filters.year_built_empty,
      min_bedrooms: filters.min_bedrooms,
      max_bedrooms: filters.max_bedrooms,
      bedrooms_empty: filters.bedrooms_empty,
      min_bathrooms: filters.min_bathrooms,
      max_bathrooms: filters.max_bathrooms,
      bathrooms_empty: filters.bathrooms_empty,
      min_equity_percent: filters.min_equity_percent,
      max_equity_percent: filters.max_equity_percent,
      equity_percent_empty: filters.equity_percent_empty,
      vacancy_type: filters.vacancy_type,
      tax_delinquency: filters.tax_delinquency,
      preforeclosure_type: filters.preforeclosure_type,
      property_data_id,
      custom_fields: filters.custom_fields,
      call_results
    });

  const bulkUpdateLeads = ({
    token,
    type,
    select_all,
    total_count,
    filters,
    list_id,
    search,
    search_type,
    lead_ids,
    deal_status,
    deal_status_slug,
    list_ids,
    selected_columns,
    tag_ids,
    campaign_id,
    mail_template_id,
    resend_freq,
    resend_limit,
    start_mailers,
    new_list_name = null,
    new_tag_name = null,
    custom_field_id,
    value,
    property_data_id,
    assign_to_id,
    accept_new_owner = 0,
    list_history_id = null,
    get_updated_data,
    property_flags,
    property_flags_and_or,
    credit_update_type,
    credit
  }) =>
    api.post("v2/bulk-update-leads/", {
      token,
      type,
      select_all,
      total_count,
      lead_ids,
      deal_status,
      deal_status_slug,
      list_ids,
      selected_columns,
      tag_ids,
      campaign_id,
      mail_template_id,
      resend_freq,
      resend_limit,
      start_mailers,
      new_list_name,
      new_tag_name,
      custom_field_id,
      value,
      assign_to_id,
      accept_new_owner,
      list_history_id,
      list_id,
      search,
      search_type,
      get_updated_data,
      property_flags,
      property_flags_and_or,
      filters,
      property_data_id,
      credit_update_type,
      credit
    });

  const undoAction = (token, change_group_id) =>
    api.post("v2/undo/", {
      token: token,
      change_group_id: change_group_id
    });

  const getFilters = ({ token, search, begin, limit }) =>
    api.get("v2/filters/", {
      token,
      search,
      begin,
      limit
    });

  const updateFilter = ({
    token,
    type,
    title,
    description,
    use_for_dealfinders,
    filter_json,
    filter_id
  }) =>
    api.post("v2/filters/", {
      token,
      type,
      title,
      description,
      use_for_dealfinders,
      filter_json,
      filter_id
    });

  const getOwnerInfo = ({ token, deal_id }) =>
    api.get("v2/owner/", {
      token,
      deal_id
    });

  const updateOwnerInfo = ({
    token,
    type,
    deal_id,
    owner_name,
    address_label,
    name,
    address,
    owner_mailing_address_id,
    owner_phone_number_id,
    owner_email_address_id,
    address2,
    city,
    state,
    zip,
    send_to_address,
    phone_label,
    phone_number,
    bad_phone,
    email_label,
    email_address
  }) =>
    api.post("v2/owner/", {
      token,
      type,
      deal_id,
      owner_mailing_address_id,
      owner_phone_number_id,
      owner_email_address_id,
      owner_name,
      address_label,
      name,
      address,
      address2,
      city,
      state,
      zip,
      send_to_address,
      phone_label,
      phone_number,
      bad_phone,
      email_label,
      email_address
    });

  const getBilling = ({
    token,
    type,
    slug,
    require_tier,
    selected_street,
    street_selected_tier,
    street_selected_frequency,
    selected_lists,
    lists_selected_tier,
    lists_selected_frequency,
    selected_messages,
    messages_selected_tier,
    messages_selected_frequency,
    extra_fee,
    extra_fee_text,
    team_id,

    invoice_type,
    mailer_campaign_id,
    lead_id,
    number_of_leads,
    number_of_addresses
  }) =>
    api.get("v2/billing/", {
      token,
      type,
      slug,
      require_tier,
      selected_street,
      street_selected_tier,
      street_selected_frequency,
      selected_lists,
      lists_selected_tier,
      lists_selected_frequency,
      selected_messages,
      messages_selected_tier,
      messages_selected_frequency,
      extra_fee,
      extra_fee_text,
      team_id,

      invoice_type,
      mailer_campaign_id,
      lead_id,
      number_of_leads,
      number_of_addresses
    });

  const getTeamMembers = ({
    token,
    type,
    begin = 0,
    search = "",
    username_search = ""
  }) =>
    api.get("v2/team/", {
      token,
      type,
      begin,
      search,
      username_search
    });

  const updateTeamMembers = ({
    token,
    type,
    team_member_id,
    member_type,
    module_type,
    email,
    can_approve_mail,
    can_enhanced_search,
    can_see_all_deals,
    can_edit_templates,
    can_export_data,
    can_send_message,
    can_mimic_team_owner,
    can_edit_custom_fields,
    can_build_lists,
    can_see_dashboard
  }) =>
    api.post("v2/team/", {
      token,
      type,
      team_member_id,
      member_type,
      module_type,
      email,
      can_approve_mail,
      can_enhanced_search,
      can_see_all_deals,
      can_edit_templates,
      can_export_data,
      can_send_message,
      can_mimic_team_owner,
      can_edit_custom_fields,
      can_build_lists,
      can_see_dashboard
    });

  const addStripePaymentMethod = ({ token, type, paymentMethodId }) =>
    api.post("billing/add-stripe-payment-method/", {
      token,
      type,
      paymentMethodId
    });

  const saveCard = (
    token,
    card_token,
    type,
    last4,
    price,
    plan_id,
    plan_frequency,
    device,
    pause_all_mailing,
    accepted_terms,
    terms_type,
    slug,
    address = null,
    email = null,

    selected_street = 0,
    street_selected_tier = 1,
    street_selected_frequency = "monthly",
    street_extra_fee = null,
    street_extra_fee_text = null,

    selected_lists = 0,
    lists_selected_tier = 1,
    lists_selected_frequency = "monthly",
    lists_extra_fee = null,
    lists_extra_fee_text = null,

    selected_messages = 0,
    messages_selected_tier = 1,
    messages_selected_frequency = "monthly",
    messages_extra_fee = null,
    messages_extra_fee_text = null,

    first_cancel_reason,
    second_cancel_reason,
    pause_mailers = false,
    onSuccess = null,
    replace_primary = true,
    expected_card_order = 0,
    editing_card_id = null
  ) =>
    api.post("v2/update-plan/", {
      token: token,
      type: type,
      payload: {
        card_token: card_token,
        last4: last4,
        plan_id: plan_id,
        plan_frequency: plan_frequency,
        device: AppConfig().device,
        pause_all_mailing: pause_all_mailing,
        accepted_terms,
        terms_type,
        slug,
        address,
        email,
        selected_street,
        street_selected_tier,
        street_selected_frequency,
        street_extra_fee,
        street_extra_fee_text,
        selected_lists,
        lists_selected_tier,
        lists_selected_frequency,
        lists_extra_fee,
        lists_extra_fee_text,
        selected_messages,
        messages_selected_tier,
        messages_selected_frequency,
        messages_extra_fee,
        messages_extra_fee_text,
        first_cancel_reason,
        second_cancel_reason,
        pause_mailers,
        replace_primary,
        expected_card_order,
        editing_card_id
      }
    });

  const updateAddon = ({ token, addon_id, type, payload }) =>
    api.post("v2/purchase-addon/", {
      token: token,
      addon_id: addon_id,
      type: type,
      payload: payload
    });
  const editCredits = ({ token, type, team_id, payload }) =>
    api.post("v2/credits/", {
      token,
      type,
      team_id,
      payload
    });

  const dashboardActivity = (token, search, begin, filter_by) =>
    api.get("v2/dashboard-activity/", {
      token: token,
      search: search,
      begin: begin,
      filter_by: filter_by
    });
  const getDashboardStats = (
    token,
    type,
    filter_user,
    filter_team,
    workspace_id,
    filter_date,
    filter_start_date,
    filter_end_date,
    filter_design,
    filter_mail_sequence,
    include_properties,
    include_call_statuses,
    slugs
  ) =>
    api.get("v2/dashboard-stats/", {
      token,
      type,
      filter_user,
      filter_team,
      workspace_id,
      filter_date,
      filter_start_date,
      filter_end_date,
      filter_design,
      filter_mail_sequence,
      include_properties,
      include_call_statuses,
      slugs
    });

  const updateNewDashboard = (token, type, slug) =>
    api.post("v2/dashboard-stats/", {
      token,
      type,
      slug
    });

  const getMailers = ({
    token,
    min_date_mailed,
    max_date_mailed,
    mailer_campaign_id,
    campaign_ids,
    template_ids,
    begin = 0
  }) =>
    api.get("v2/mailers/", {
      token,
      min_date_mailed,
      max_date_mailed,
      mailer_campaign_id,
      campaign_ids,
      template_ids,
      begin
    });

  const getMailersFailed = ({ token, begin = 0 }) =>
    api.get("v2/mailers/", {
      token,
      begin
    });

  const getMultiSendNumbers = ({
    token,
    type = "multisend_numbers",
    begin,
    limit,
    lead_ids,
    filter_lists,
    filters = {}
  }) =>
    api.get("v2/conversations/", {
      token,
      type,
      begin,
      limit,
      lead_ids,
      filter_list_ids: filter_lists
        ? filter_lists
            .map(list => {
              return list.id;
            })
            .join(",")
        : filters?.list_ids
        ? filters.list_ids
            .map(list => {
              return list.id;
            })
            .join(",")
        : "",

      deal_status_ids: filters.deal_status_ids,
      mail_template_ids: filters.mail_template_ids,
      campaign_ids: filters.campaign_ids,
      campaign_step: filters.campaign_step,
      campaign_status: filters.campaign_status,
      lead_source: filters.lead_source,
      added_by_ids: filters.added_by_ids,
      lead_owner_ids: filters.lead_owner_ids,
      added_type: filters.added_type,
      filter_tag_ids: filters.tag_ids,
      min_date_added: filters.min_date_added,
      max_date_added: filters.max_date_added,
      date_added_dropdown: filters.date_added_dropdown,
      min_last_exported_date: filters.min_last_exported_date,
      max_last_exported_date: filters.max_last_exported_date,
      last_exported_date_dropdown: filters.last_exported_date_dropdown,
      times_mailed_min: filters.times_mailed_min,
      times_mailed_max: filters.times_mailed_max,
      did_skip_trace: filters.did_skip_trace,
      skip_traced_by: filters.skip_traced_by,
      min_date_skip_traced: filters.min_date_skip_traced,
      max_date_skip_traced: filters.max_date_skip_traced,
      scheduled_to_send_min: filters.scheduled_to_send_min,
      scheduled_to_send_max: filters.scheduled_to_send_max,
      include_image: filters.include_image,
      usps_address: filters.usps_address,
      sending_queue: filters.sending_queue,

      owner_status: filters.owner_status,
      filter_property_address: filters.filter_property_address,
      filter_property_city: filters.filter_property_city,
      filter_property_state: filters.filter_property_state,
      filter_property_zip: filters.filter_property_zip,
      filter_owner_name: filters.filter_owner_name,
      filter_owner_address: filters.filter_owner_address,
      filter_owner_city: filters.filter_owner_city,
      filter_owner_state: filters.filter_owner_state,
      filter_owner_zip: filters.filter_owner_zip,
      min_saleprice: filters.min_saleprice,
      max_saleprice: filters.max_saleprice,
      saleprice_empty: filters.saleprice_empty,
      min_saledate: filters.min_saledate,
      max_saledate: filters.max_saledate,
      saledate_empty: filters.saledate_empty,
      saledate_dropdown: filters.saledate_dropdown,
      saledate_dropdown_empty: filters.saledate_dropdown_empty,

      min_total_value: filters.min_total_value,
      max_total_value: filters.max_total_value,
      total_value_empty: filters.total_value_empty,
      min_mortgage_amount: filters.min_mortgage_amount,
      max_mortgage_amount: filters.max_mortgage_amount,
      mortgage_amount_empty: filters.mortgage_amount_empty,

      min_building_size: filters.min_building_size,
      max_building_size: filters.max_building_size,
      building_size_empty: filters.building_size_empty,
      min_lot_acreage: filters.min_lot_acreage,
      max_lot_acreage: filters.max_lot_acreage,
      lot_acreage_empty: filters.lot_acreage_empty,
      min_units_count: filters.min_units_count,
      max_units_count: filters.max_units_count,
      units_count_empty: filters.units_count_empty,
      min_year_built: filters.min_year_built,
      max_year_built: filters.max_year_built,
      year_built_empty: filters.year_built_empty,
      min_bedrooms: filters.min_bedrooms,
      max_bedrooms: filters.max_bedrooms,
      bedrooms_empty: filters.bedrooms_empty,
      min_bathrooms: filters.min_bathrooms,
      max_bathrooms: filters.max_bathrooms,
      bathrooms_empty: filters.bathrooms_empty,

      min_equity_percent: filters.min_equity_percent,
      max_equity_percent: filters.max_equity_percent,
      equity_percent_empty: filters.equity_percent_empty,
      vacancy_type: filters.vacancy_type,
      tax_delinquency: filters.tax_delinquency,
      preforeclosure_type: filters.preforeclosure_type
    });

  const getConversations = ({
    token,
    type,
    begin = 0,
    search = "",
    limit = 25,
    sort_by,
    filter_type,
    conversation_id,
    lead_id,
    lead_phone_number
  }) =>
    api.get("v2/conversations/", {
      token,
      type,
      begin,
      limit,
      sort_by,
      filter_type,
      search,
      conversation_id,
      lead_id,
      lead_phone_number
    });
  const getTasks = ({
    token,
    type,
    begin = 0,
    limit = 25,
    sort_by,
    filter_by,
    filter_user
  }) =>
    api.get("v2/tasks/", {
      token,
      type,
      begin,
      limit,
      sort_by,
      filter_by,
      filter_user
    });
  const loadListTask = ({ token, type = "list", list_id, user_location }) =>
    api.get("v2/tasks/", {
      token,
      type,
      list_id,
      user_location
    });

  const getTaskCount = ({ token, type = "count" }) =>
    api.get("v2/tasks/", {
      token,
      type
    });
  const getTask = ({ token, type = "single", task_id, user_location }) =>
    api.get("v2/tasks/", {
      token,
      type,
      task_id,
      user_location
    });
  const saveTask = (
    token,
    type,
    title,
    assigned_user,
    date_due,
    house,
    notes,
    list_id
  ) =>
    api.post("v2/tasks/", {
      token,
      type,
      title,
      assigned_user,
      date_due,
      house,
      notes,
      list_id
    });
  const editTask = (
    token,
    task_id,
    type,
    title,
    assigned_user,
    date_due,
    house,
    assignee_notes,
    notes
  ) =>
    api.post("v2/tasks/", {
      token,
      task_id,
      type,
      title,
      assigned_user,
      date_due,
      house,
      assignee_notes,
      notes
    });
  const completeTask = ({ token, type = "complete", task_id }) =>
    api.post("v2/tasks/", {
      token,
      type,
      task_id
    });
  const updateConversation = ({
    token,
    type,
    conversation_id,
    message_id,
    lead_id,
    message_text,
    media,
    to_phone_number,
    to_phone_number_id,
    from_phone_number
  }) =>
    api.post("v2/conversations/", {
      token,
      type,
      conversation_id,
      message_id,
      lead_id,
      message_text,
      media,
      to_phone_number,
      to_phone_number_id,
      from_phone_number
    });

  const updateTeamPhoneNumber = ({
    token,
    type,
    area_code,
    forwarding_number,
    team_phone_number_group_id,
    phone_label,
    default_number
  }) =>
    api.post("v2/phone-numbers/", {
      token,
      type,
      area_code,
      forwarding_number,
      team_phone_number_group_id,
      phone_label,
      default_number
    });

  const getTeamPhoneNumbers = ({ token }) =>
    api.get("v2/phone-numbers/", {
      token
    });

  const getCalendar = ({
    token,
    type,
    start_date,
    end_date,
    begin,
    limit,
    mailer_campaign_id = null,
    campaign_ids = null,
    template_ids = null
  }) =>
    api.get("v2/calendar/", {
      token,
      type,
      start_date,
      end_date,
      begin,
      limit,
      mailer_campaign_id,
      campaign_ids,
      template_ids
    });
  const getDashboardSettings = ({ token, type, search, limit }) =>
    api.get("v2/dashboard/", {
      token,
      type,
      search,
      limit
    });

  const updateDashboard = ({
    token,
    type,
    current_step,
    goals,
    phone_number,
    mentor_name
  }) =>
    api.post("v2/dashboard/", {
      token,
      type,
      current_step,
      goals,
      phone_number
    });

  const getSavedResponses = ({ token, type, response_type, search }) =>
    api.get("v2/saved-responses/", {
      token,
      type,
      response_type,
      search
    });

  const updateSavedResponse = ({
    token,
    type,
    saved_response_id,
    response_text,
    starting_response
  }) =>
    api.post("v2/saved-responses/", {
      token,
      type,
      saved_response_id,
      response_text,
      starting_response
    });
  const getBulkEdits = ({ token }) =>
    api.get("v2/bulk-edits/", {
      token
    });

  const getMailFailedDeals = ({ token, begin = 0 }) =>
    api.get("v2/mail-failed/", {
      token,
      begin
    });

  const getSendingQueue = ({ token, type, begin = 0 }) =>
    api.get("v2/sending-queue/", {
      token,
      type,
      begin
    });

  const getDemos = ({ type, webinar_id }) =>
    api.get("v2/demos/", {
      type,
      webinar_id
    });

  const updateDemo = ({
    type,
    webinar_id,
    occurrence_id,
    user_id,
    firstname,
    lastname,
    email,
    phone
  }) =>
    api.post("v2/demos/", {
      type,
      webinar_id,
      occurrence_id,
      user_id,
      firstname,
      lastname,
      email,
      phone
    });

  const getMailerCampaigns = ({
    token,
    type,
    mailer_campaign_id,
    lead_id,
    begin,
    limit,
    search,
    sort_by,
    filter_type,
    exclude_repeating_mail
  }) =>
    api.get("v2/mailer-campaigns/", {
      token,
      type,
      mailer_campaign_id,
      lead_id,
      begin,
      limit,
      search,
      sort_by,
      filter_type,
      exclude_repeating_mail
    });

  const getMailerTemplates = ({
    token,
    type,
    mailer_template_id,
    lead_id,
    begin,
    limit,
    load_all = false,
    search,
    sort_by,
    filter_type,
    global_templates
  }) =>
    api.get("v2/mailer-templates/", {
      token,
      type,
      mailer_template_id,
      lead_id,
      begin,
      limit,
      load_all,
      search,
      sort_by,
      filter_type,
      global_templates
    });

  const getMailerSignatures = ({
    token,
    type = "mailer_signatures",
    mailer_signature_id,
    lead_id,
    begin,
    limit,
    search,
    sort_by,
    filter_type
  }) =>
    api.get("v2/mailer-templates/", {
      token,
      type,
      mailer_signature_id,
      lead_id,
      begin,
      limit,
      search,
      sort_by,
      filter_type
    });

  const getMailerDesignTemplates = ({
    token,
    type = "mailer_design_templates",
    template_type
  }) =>
    api.get("v2/mailer-templates/", {
      token,
      type,
      template_type
    });

  const updateMailerCampaigns = ({
    token,
    mailer_campaign_id,
    type,
    title,
    on_start_campaign,
    on_start_campaign_status,
    on_pause_campaign,
    on_pause_campaign_status,
    on_end_campaign,
    on_end_campaign_status,
    campaign_steps,
    repeat_mail_sequence
  }) =>
    api.post("v2/mailer-campaigns/", {
      token,
      mailer_campaign_id,
      type,
      title,
      on_start_campaign,
      on_start_campaign_status,
      on_pause_campaign,
      on_pause_campaign_status,
      on_end_campaign,
      on_end_campaign_status,
      campaign_steps,
      repeat_mail_sequence
    });

  const updateMailerTemplate = ({
    token,
    type,
    mailer_template_id,
    template_title,
    template_system_type,
    template_type,
    front_layers,
    back_layers,
    section_a,
    section_b,
    section_c,
    section_d,
    signature_id,
    signature_sign_off,
    return_address,
    return_address2,
    return_address_city,
    return_address_state,
    return_address_zip,
    signature_name,
    signature_phone,
    call_tracking_number_id,
    signature_email,
    signature_image,
    html_template_id,
    primary_color,
    secondary_color,
    custom_image,
    include_custom_image,
    global_templates
  }) =>
    api.post("v2/mailer-templates/", {
      token,
      type,
      mailer_template_id,
      template_title,
      template_system_type,
      template_type,
      front_layers,
      back_layers,
      section_a,
      section_b,
      section_c,
      section_d,
      signature_id,
      signature_sign_off,
      return_address,
      return_address2,
      return_address_city,
      return_address_state,
      return_address_zip,
      signature_name,
      signature_phone,
      call_tracking_number_id,
      signature_email,
      signature_image,
      html_template_id,
      primary_color,
      secondary_color,
      custom_image,
      include_custom_image,
      global_templates
    });

  const updateMailerSignature = ({
    token,
    type,
    mailer_signature_id,
    title,
    sign_off,
    address,
    address2,
    city,
    state,
    zip,
    name,
    other_contact,
    email,
    phone,
    signature_image,
    disclaimer
  }) =>
    api.post("v2/mailer-templates/", {
      token,
      type,
      mailer_signature_id,
      title,
      sign_off,
      address,
      address2,
      city,
      state,
      zip,
      name,
      other_contact,
      email,
      phone,
      signature_image,
      disclaimer
    });

  const getSalesPagesBuyerMessages = ({ token }) =>
    api.get("v2/sales-pages/buyer-messages/", {
      token
    });

  const getSalesPagesInventory = ({ token }) =>
    api.get("v2/sales-pages/inventory/", {
      token
    });

  const getSalesPageByID = ({ token, id }) =>
    api.get("v2/sales-pages/inventory/", {
      token,
      id
    });

  const publishSalesPage = ({ token, sales_page_id, value }) =>
    api.put(`v2/sales-pages/?token=${token}`, {
      id: sales_page_id,
      payload: {
        published: value
      }
    });

  const createSalesPage = ({
    token,
    house,
    property_headline,
    property_details,
    pricing_information,
    custom_slug,
    for_sale_by,
    show_property_address
  }) =>
    api.post(`v2/sales-pages/?token=${token}`, {
      payload: {
        house,
        custom_slug,
        property_headline,
        property_details,
        pricing_information,
        for_sale_by,
        show_property_address
      }
    });

  const updateSalesPage = ({
    token,
    sales_page_id,
    customURL,
    headline,
    propertyDetails,
    pricingInfo,
    contactEmail,
    contactName,
    contactPhone,
    contactCompany,
    published,
    forSaleBy,
    showPropertyAddress
  }) => {
    const payload = {
      custom_slug: customURL,
      property_headline: headline,
      property_details: propertyDetails,
      pricing_information: pricingInfo,
      contact_name: contactName,
      contact_phone: contactPhone,
      contact_email: contactEmail,
      contact_company: contactCompany,
      for_sale_by: forSaleBy,
      show_property_address: showPropertyAddress
    };

    if (published !== null) {
      payload.published = published;
    }

    return api.put(`v2/sales-pages/?token=${token}`, {
      id: sales_page_id,
      payload
    });
  };

  const getPublicSalesPage = async ({ token, slug }) => {
    let visitorID;
    try {
      visitorID = await getFingerPrintVisitorID();
    } catch (e) {}
    return api.get(
      `v2/sales-pages/public/?token=${token}&slug=${slug}&fingerprint=${visitorID}`
    );
  };

  const sendSalesPageMessageAsBuyer = ({
    sales_page_id,
    name,
    email,
    phone,
    message
  }) =>
    api.post("v2/sales-pages/public/", {
      sales_page_id,
      name,
      email,
      phone,
      message
    });

  const getCustomFields = ({ token, type }) =>
    api.get("v2/custom-fields/", {
      token,
      type
    });

  const updateCustomFields = ({
    token,
    type,
    custom_field_group_id,
    custom_field_id,
    custom_fields,
    title,
    field_type,
    field_options
  }) =>
    api.post("v2/custom-fields/", {
      token,
      type,
      custom_field_group_id,
      custom_field_id,
      custom_fields,
      title,
      field_type,
      field_options
    });
  const getLeadStatuses = ({ token, type }) =>
    api.get("v2/lead-status/", {
      token,
      type
    });

  const updateLeadStatus = ({
    token,
    type,
    lead_status_id,
    title,
    status_type,
    lead_statuses
  }) =>
    api.post("v2/lead-status/", {
      token,
      type,
      lead_status_id,
      title,
      status_type,
      lead_statuses
    });

  const getWorkflows = ({
    token,
    type,
    workflow_id,
    workflow_enrollment_log_id,
    lead_id,
    begin,
    limit,
    search,
    sort_by,
    filter_type,
    filters,
    category,
    property_db_only
  }) =>
    api.get("v2/workflows/", {
      token,
      type,
      workflow_id,
      workflow_enrollment_log_id,
      lead_id,
      begin,
      limit,
      search,
      sort_by,
      filter_type,
      filters,
      category,
      property_db_only
    });
  const updateWorkflow = ({
    token,
    type,
    workflow_id,
    workflow_title,
    lead_id,
    category,
    template_type,
    enrollment_triggers,
    workflow_steps,
    only_enroll_manually,
    workflow_on,
    slug,
    automation_on,
    automation_data,
    workflow_ids,
    selected_all
  }) =>
    api.post("v2/workflows/", {
      token,
      type,
      workflow_id,
      workflow_title,
      lead_id,
      category,
      template_type,
      enrollment_triggers,
      workflow_steps,
      only_enroll_manually,
      workflow_on,
      slug,
      automation_on,
      automation_data,
      workflow_ids,
      selected_all
    });

  const getPropertyImages = ({ token, lead_id }) =>
    api.get("v2/property-images/", {
      token,
      lead_id
    });
  const updatePropertyImage = ({ token, lead_id, property_image_id, type }) =>
    api.post("v2/property-images/", {
      token,
      lead_id,
      property_image_id,
      type
    });
  const updatePaymentMethodsOrder = ({ token, payload, type }) => {
    return api.post("v2/billing/", {
      token: token,
      payload: payload,
      type: type
    });
  };

  const deleteCard = ({ token, payload, type }) => {
    return api.post("/v2/billing/", {
      token,
      payload,
      type
    });
  };

  const makePrimaryCard = ({ token, payload, type }) => {
    return api.post("/v2/billing/", {
      token,
      payload,
      type
    });
  };

  const getApiInfo = ({ token }) =>
    api.get("v2/api/", {
      token
    });

  const updateApiInfo = ({ token, type }) =>
    api.post("v2/api/", {
      token,
      type
    });

  const getSavedFilters = ({ token, filter_type }) =>
    api.get("v2/advanced-filters/", {
      token,
      filter_type
    });
  const updateSavedFilters = ({
    token,
    type,
    advanced_filter_id,
    system_type,
    title,
    filter_type,
    default_filter = 0,
    filters,
    property_flags,
    property_types,
    property_flags_and_or,
    value_range_min,
    value_range_max,
    price_type,
    beds_min,
    baths_min,
    use_beds_exact
  }) =>
    api.post("v2/advanced-filters/", {
      token,
      type,
      advanced_filter_id,
      system_type,
      title,
      filter_type,
      default_filter,
      filters,
      property_flags,
      property_types,
      property_flags_and_or,
      value_range_min,
      value_range_max,
      price_type,
      beds_min,
      baths_min,
      use_beds_exact
    });
  const getListBuilderTemplates = ({ token }) =>
    api.get("v2/list-builder/", {
      token,
      type: "list_builder_templates"
    });

  const getRecentPrompts = ({ token, search, begin, limit }) =>
    api.get("v2/list-builder/", {
      token,
      type: "get_recent_prompts",
      search,
      begin,
      limit
    });
  const getCurrentAIBuildingList = ({ token }) =>
    api.get("v2/list-builder/", {
      token,
      type: "current_ai_building_list"
    });
  const getHighlightTemplates = ({ token }) =>
    api.get("v2/main-map/", {
      token,
      type: "highlight_templates"
    });

  const estimatedListCount = ({
    token,
    type,
    list_filters,
    location_type,
    zip,
    city,
    fips,
    state,
    drawing_coordinates
  }) =>
    api.post("v2/list-builder/", {
      token,
      type,
      list_filters,
      location_type,
      zip,
      city,
      fips,
      state,
      drawing_coordinates
    });

  const buildList = ({
    token,
    title,
    list_filters,
    location_type,
    zip,
    city,
    fips,
    state,
    drawing_coordinates,
    estimated_count
  }) =>
    api.post("v2/list-builder/", {
      token,
      title,
      type: "build_list",
      using_new_filters: 1,
      list_type: "build_list",
      list_area_type: location_type,
      list_area:
        location_type == "city"
          ? city
          : location_type == "county"
          ? fips
          : location_type == "zip"
          ? zip
          : "",
      list_area_2:
        location_type == "city" || location_type == "county" ? state : "",
      list_geo_fence: drawing_coordinates,
      list_filters,
      estimated_count
    });

  const buildListv2 = ({
    token,
    title,
    list_filters,
    type,
    list_id,
    location_type,
    zip,
    city,
    fips,
    state,
    drawing_coordinates,
    estimated_count,
    property_flags,
    property_types,
    property_flags_and_or,
    value_range_min,
    value_range_max,
    price_type,
    beds_min,
    baths_min,
    use_beds_exact = false,
    search_locations,
    prompt,
    variance,
    attached_property_ids,
    use_vision = false
  }) =>
    api.post("v2/list-builder/", {
      token,
      title,
      type,
      list_id,
      using_new_filters: 1,
      list_type: "build_list",
      list_area_type: location_type,
      list_area:
        location_type === "city"
          ? city
          : location_type === "county" || location_type === "fips"
          ? fips
          : location_type === "zip"
          ? zip
          : zip,
      list_area_2: state,
      list_geo_fence: drawing_coordinates,
      list_filters,
      estimated_count,
      property_flags,
      property_types,
      property_flags_and_or,
      value_range_min,
      value_range_max,
      price_type,
      beds_min,
      baths_min,
      use_beds_exact,
      search_locations,
      prompt,
      variance,
      attached_property_ids,
      use_vision
    });

  const getListsOnMap = ({ token, bounds }) =>
    api.post("v2/list-builder/", {
      token: token,
      type: "lists_on_map",
      westLng: bounds.westLng,
      southLat: bounds.southLat,
      eastLng: bounds.eastLng,
      northLat: bounds.northLat,
      centerLat: bounds.centerLat,
      centerLng: bounds.centerLng
    });
  const getLeadsOnMapForListBuilder = ({ token, bounds, dont_include }) =>
    api.post("v2/list-builder/", {
      token: token,
      type: "leads_on_map_for_list_builder",
      westLng: bounds.westLng,
      southLat: bounds.southLat,
      eastLng: bounds.eastLng,
      northLat: bounds.northLat,
      centerLat: bounds.centerLat,
      centerLng: bounds.centerLng,
      dont_include
    });

  const getHighlightsOnMapForListBuilder = ({
    token,
    filters,
    bounds,
    dont_include
  }) =>
    api.post("v2/list-builder/", {
      token: token,
      filters,
      type: "highlights_on_map_for_list_builder",
      westLng: bounds.westLng,
      southLat: bounds.southLat,
      eastLng: bounds.eastLng,
      northLat: bounds.northLat,
      centerLat: bounds.centerLat,
      centerLng: bounds.centerLng,
      dont_include
    });

  const getPropertiesOnMap = ({
    token,
    type = "properties_on_map",
    bounds,
    route_id,
    filters,
    load_type,
    highlight,
    dont_include,
    limit,
    page,
    sort_by,
    drawing_coordinates,
    property_flags = null,
    property_types = null,
    property_flags_and_or,

    value_range_min,
    value_range_max,
    price_type,
    baths_min,
    beds_min,
    use_beds_exact,
    include_owners,
    include_residents,
    viewing_type,
    zip,
    search_locations
  }) =>
    api.post("v2/main-map/", {
      token: token,
      highlight,
      filters,
      load_type,
      dont_include,
      route_id,
      type,
      westLng: bounds.westLng,
      southLat: bounds.southLat,
      eastLng: bounds.eastLng,
      northLat: bounds.northLat,
      centerLat: bounds.centerLat,
      centerLng: bounds.centerLng,
      distance: bounds.distance,
      limit,
      page,
      sort_by,
      drawing_coordinates,
      property_flags,
      property_types,
      property_flags_and_or,

      value_range_min,
      value_range_max,
      price_type,

      baths_min,
      beds_min,
      use_beds_exact,
      include_owners,
      include_residents,
      viewing_type,
      zip,
      search_locations
    });

  const getRoutesOnMap = ({
    token,
    bounds,
    route_id,
    dont_include_route_sections
  }) =>
    api.post("v2/main-map/", {
      token: token,
      dont_include_route_sections,
      type: "routes_on_map",
      route_id,
      westLng: bounds?.westLng,
      southLat: bounds?.southLat,
      eastLng: bounds?.eastLng,
      northLat: bounds?.northLat,
      centerLat: bounds?.centerLat,
      centerLng: bounds?.centerLng
    });

  const searchDealMachine = ({
    token,
    search,
    type,
    address_range,
    address_predir,
    address_name,
    address_suffix,
    address_postdir,
    address_suite_name,
    address_suite_range,
    city,
    state,
    zip
  }) =>
    api.get("v2/search/", {
      token: token,
      search,
      type,
      address_range,
      address_predir,
      address_name,
      address_suffix,
      address_postdir,
      address_suite_name,
      address_suite_range,
      city,
      state,
      zip
    });
  const updateStackedLists = ({
    token,
    list_ids,
    list_stacking_level_1,
    list_stacking_level_2,
    list_stacking_level_3
  }) =>
    api.post("v2/list-builder/", {
      token,
      type: "update_stacked_lists",
      list_ids,
      list_stacking_level_1,
      list_stacking_level_2,
      list_stacking_level_3
    });

  const getLeadActivity = ({ token, lead_id }) =>
    api.get("v2/lead-activity/", {
      token,
      lead_id
    });

  const getTeamReferralInfo = ({ token }) =>
    api.get("v2/promo/", {
      token
    });
  const getLeadUpdates = ({ token, type, begin, limit }) =>
    api.get("v2/get-lead-updates/", {
      token,
      type,
      begin,
      limit
    });
  const getMLSImages = ({ token, property_id }) =>
    api.get("v2/mls-images/", {
      token,
      property_id
    });

  const getUserFromAppleAuth = ({ appleToken }) =>
    api.post("v2/verify-appleid/", {
      appleToken: appleToken
    });

  const getChatSystemMessages = ({
    token,
    chat_type,
    message_type,
    property_id,
    property_address,
    max_allowable_offer,
    estimated_value
  }) =>
    api.get("alma/system/", {
      token,
      chat_type,
      message_type,
      property_id,
      property_address,
      max_allowable_offer,
      estimated_value
    });
  const getChatPrompts = ({ token, chat_type, property_id }) =>
    api.get("alma/prompts/", {
      token,
      chat_type,
      property_id
    });

  const getAlmaChat = ({
    token,
    chat_type,
    type,
    prompt,
    property_id,
    property_address,
    centerLat,
    centerLng
  }) =>
    api.get("alma/chat/", {
      token,
      type,
      prompt,
      chat_type,
      property_id,
      property_address,
      centerLat,
      centerLng
    });

  const updateAlmaChat = ({
    token,
    type,
    chat_type,
    property_id,
    property_address,
    chat_id,
    message_id,
    feedback,
    role,
    content,
    messages_json,
    display_content,
    prompt_slug,
    answer_slug,
    answer_group,
    follow_up_slug
  }) =>
    api.post("alma/chat/", {
      token,
      chat_type,
      type,
      property_id,
      property_address,
      chat_id,
      message_id,
      feedback,
      role,
      content,
      messages_json,
      display_content,
      prompt_slug,
      answer_slug,
      answer_group,
      follow_up_slug
    });

  const getPropertiesFromPrompt = ({ token, prompt }) =>
    api.get("alma/api/properties/", {
      token,
      prompt
    });

  const getFormattedDataForOpenAI = ({ token, data_type, property_ids }) =>
    api.get("alma/data/", {
      token,
      data_type,
      property_ids
    });

  const getContacts = ({
    token,
    type,
    person_id,
    property_id,
    limit,
    begin,
    sort_by,
    firstname,
    lastname,
    middlename,
    firstname2,
    lastname2,
    middlename2,
    firstname3,
    lastname3,
    middlename3,
    firstname4,
    lastname4,
    middlename4,
    firstname5,
    lastname5,
    middlename5,

    address_zipcode,
    address_state
  }) =>
    api.get("v2/contacts/", {
      token,
      type,
      person_id,
      property_id,
      limit,
      begin,
      sort_by,
      firstname,
      lastname,
      middlename,
      firstname2,
      lastname2,
      middlename2,
      firstname3,
      lastname3,
      middlename3,
      firstname4,
      lastname4,
      middlename4,
      firstname5,
      lastname5,
      middlename5,
      address_zipcode,
      address_state
    });

  const getLinkedCompanies = ({
    token,
    type,
    company_number,
    jurisdiction_code,
    limit,
    begin
  }) =>
    api.get("v2/companies/", {
      token,
      type,
      company_number,
      jurisdiction_code,
      limit,
      begin
    });

  const updateContacts = ({
    token,
    type,
    person_id,
    property_id,
    matching_type
  }) =>
    api.post("v2/contacts/", {
      token,
      type,
      person_id,
      property_id,
      matching_type
    });

  const getCompanies = ({
    token,
    company_name,
    company_address,
    company_city,
    company_state,
    company_zip
  }) =>
    api.get("v2/contacts/", {
      token,
      type: "company_search",
      company_name,
      company_address,
      company_city,
      company_state,
      company_zip
    });

  const getCompanyPeople = ({ token, company_number, jurisdiction_code }) =>
    api.get("v2/contacts/", {
      token,
      type: "company_people_search",
      company_number,
      jurisdiction_code
    });

  const getTwilioToken = ({ token }) =>
    api.get("phone/twilio/auth/", {
      token
    });

  const getTelnyxToken = ({ token, force_new }) =>
    api.get("phone/telnyx/auth/", {
      token,
      force_new
    });

  const callControl = ({
    token,
    type,
    call_id,
    to_phone_number,
    from_phone_number
  }) =>
    api.post("phone/telnyx/call-control/", {
      token,
      type,
      call_id,
      to_phone_number,
      from_phone_number
    });

  const phoneCall = ({
    token,
    type,
    dialer_provider,
    property_id,
    contact_id,
    call_session_id,
    call_id,
    provider_id,
    provider,
    to_phone_number,
    from_phone_number,
    transcription,
    phone_owner,
    phone_type,
    do_not_call_flag,
    call_results,
    edited_note,
    practice_mode,
    onboarding,
    error_message
  }) =>
    api.post("phone/phone-call/", {
      token,
      type,
      dialer_provider,
      property_id,
      contact_id,
      call_session_id,
      call_id,
      provider_id,
      provider,
      to_phone_number,
      from_phone_number,
      transcription,
      phone_owner,
      phone_type,
      do_not_call_flag,
      call_results,
      edited_note,
      practice_mode,
      onboarding,
      error_message
    });

  const getPhoneCall = ({ token, type, to_phone_number, call_id, sort_by }) =>
    api.get("phone/phone-call/", {
      token,
      type,
      to_phone_number,
      call_id,
      sort_by
    });

  const getQueuedNumbers = ({
    token,
    type,
    property_id,
    contact_id,
    exclude_numbers,
    include_numbers
  }) =>
    api.get("phone/queue/", {
      token,
      type,
      property_id,
      contact_id,
      exclude_numbers,
      include_numbers
    });

  const getVoiceClone = ({ token, type }) =>
    api.get("phone/voice-clone/", {
      token,
      type
    });

  const updateVoiceClone = ({
    token,
    type,
    dialer_provider,
    call_id,
    contact_id,
    property_id,
    callback_number,
    generated_text,
    recording_id
  }) =>
    api.post("phone/voice-clone/", {
      token,
      type,
      dialer_provider,
      call_id,
      contact_id,
      property_id,
      callback_number,
      generated_text,
      recording_id
    });

  const getCleanAIData = ({ token, type, contact_id, property_id }) =>
    api.post("phone/openai/clean-data/", {
      token,
      type,
      contact_id,
      property_id
    });
  const dialerConversationCleanUp = ({
    token,
    contact_id,
    property_id,
    message,
    conversation,
    type
  }) =>
    api.post("phone/openai/conversation-clean-up/", {
      token,
      contact_id,
      property_id,

      message,
      conversation,
      type
    });
  const dialerConversationPredict = ({
    token,
    contact_id,
    property_id,
    conversation,
    type
  }) =>
    api.post("phone/openai/conversation-predict/", {
      token,
      contact_id,
      property_id,
      conversation,
      type
    });

  const dialerConversationSummarize = ({
    token,
    call_id,
    contact_id,
    property_id,
    conversation,
    answered_by,
    type
  }) =>
    api.post("phone/openai/conversation-summarize/", {
      token,
      call_id,
      contact_id,
      property_id,
      conversation,
      answered_by,
      type
    });

  const createCallSession = ({
    token,
    type,
    select_all,
    total_count,
    new_filters = null,
    filters = {},
    list_id,
    list_history_id,
    search,
    search_type,
    lead_ids,
    property_flags,
    property_flags_and_or,
    call_session_type,
    follow_up_queue_user,
    follow_up_queue
  }) =>
    api.post("phone/call-session/", {
      token,
      type,
      select_all,
      total_count,
      new_filters,
      filters,
      list_id,
      list_history_id,
      search,
      search_type,
      lead_ids,
      property_flags,
      property_flags_and_or,
      call_session_type,
      follow_up_queue_user,
      follow_up_queue
    });

  const getCallSession = ({ token, type, call_session_id, limit, begin }) =>
    api.get("phone/call-session/", {
      token,
      type,
      call_session_id,
      limit,
      begin
    });
  const updateCallSession = ({
    token,
    type,
    call_session_id,
    call_id,
    to_phone_number,
    contact_id,
    property_id,
    skipped_contact_id,
    skipped_property_id
  }) =>
    api.get("phone/call-session/", {
      token,
      type,
      call_session_id,
      call_id,
      to_phone_number,
      contact_id,
      property_id,
      skipped_contact_id,
      skipped_property_id
    });

  const getDialerStats = ({
    token,
    type,
    filter_user,
    filter_date,
    filter_start_date,
    filter_end_date,
    utc_offset,
    timezone,
    search,
    limit,
    begin
  }) =>
    api.get("phone/dialer-stats/", {
      token,
      type,
      filter_user,
      filter_date,
      filter_start_date,
      filter_end_date,
      utc_offset,
      timezone,
      search,
      limit,
      begin
    });

  const getCallTrackingNumbers = ({ token, type }) =>
    api.get("phone/call-tracking/", {
      token,
      type
    });

  const updateCallTrackingNumbers = ({
    token,
    type,
    call_tracking_number_id,
    phone_label,
    callback_type,
    callback_number,
    default_tracking_number,
    areacode
  }) =>
    api.post("phone/call-tracking/", {
      token,
      type,
      call_tracking_number_id,
      phone_label,
      callback_type,
      callback_number,
      areacode,
      default_tracking_number
    });

  const getCashBuyers = ({ token, centerLng, centerLat, zip }) =>
    api.get("v2/cash-buyers/", {
      token,
      centerLng,
      centerLat,
      zip
    });

  const getWorkspaceTeams = ({ token }) =>
    api.get("workspaces/teams/", {
      token
    });

  const generateWorkspaceTeamName = ({ token, firstname, lastname }) =>
    api.post("workspaces/teams/", {
      token,
      type: "generate_team_name",
      firstname,
      lastname
    });

  const addWorkspaceTeam = ({
    token,
    firstname,
    lastname,
    email,
    team_name,
    should_team_handle_funds,
    does_team_have_allowance,
    monthly_allowance_cents
  }) =>
    api.post("workspaces/teams/", {
      token,
      type: "add_team",
      firstname,
      lastname,
      email,
      team_name,
      should_team_handle_funds,
      does_team_have_allowance,
      monthly_allowance_cents
    });

  const editWorkspaceTeam = ({ token, team_id, team_name, team_email, type }) =>
    api.post("workspaces/teams/", {
      token,
      type,
      team_id,
      team_name,
      team_email
    });

  const getWorkspaceSettings = ({ token }) =>
    api.get("workspaces/settings/", {
      token
    });

  const editWorkspaceSettings = ({
    token,
    type,
    team_id,
    setting_key,
    setting_value
  }) =>
    api.post("workspaces/settings/", {
      token,
      type,
      team_id,
      setting_key,
      setting_value
    });

  const resendWorkspaceTeamInvitation = ({ token, team_id }) =>
    api.post("workspaces/teams/", {
      token,
      type: "resend_invitation",
      team_id
    });

  const accessWorkspaceTeam = ({ token, team_id, device_type }) =>
    api.post("workspaces/access/", {
      token,
      type: "access_team",
      team_id,
      device_type
    });

  const getWorkspaceTeamLicenses = ({ token, type, team_id }) =>
    api.get("workspaces/licenses/", {
      token,
      type,
      team_id
    });

  const editWorkspaceTeamLicenses = ({
    token,
    type,
    team_id,
    seat_type,
    license_id,
    assign_to,
    old_assigned_user
  }) =>
    api.post("workspaces/licenses/", {
      token,
      type,
      team_id,
      seat_type,
      license_id,
      assign_to,
      old_assigned_user
    });

  return {
    login,
    getUser,
    getUserFromRecoveryToken,
    getUserFromEmailToken,
    getSourceOfTruth,
    manageSubscription,
    getStripeCheckoutSession,
    getStripePriceInformation,
    makeInAppPurchase,
    editUser,
    setFcmToken,
    signup,
    getTasks,
    listProperties,
    getLeads,
    getProperty,
    matchProperty,
    getMorePropertyInfo,
    updateListSettings,
    mapProperties,
    nextPlannedProperty,
    nextPlannedPropertyV2,
    completePlannedProperty,
    reverseGeocode,
    autocomplete,
    autocompleteMap,
    addDeal,
    getAllStatuses,
    getPropertyFIPS,
    getPropertyCities,
    getPropertyZips,
    getPropertyComps,
    getAffiliateDashboard,
    getUserFromAppleAuth,
    getTrackedRoutes,
    startTrackedRoute,
    updateTrackedRoute,
    stopTrackedRoute,
    removeTrackedRoute,

    startPlannedRoute,
    updatePlannedRoute,
    stopPlannedRoute,

    getPausePlanInfo,
    pauseOrCancelPlan,

    getTutorial,
    completeTutorial,

    getLists,
    updateList,

    updateLead,
    bulkUpdateLeads,
    undoAction,

    getFilters,
    updateFilter,

    getOwnerInfo,
    updateOwnerInfo,

    getBilling,
    saveCard,
    addStripePaymentMethod,

    getTeamMembers,
    updateTeamMembers,

    updateAddon,
    editCredits,

    dashboardActivity,
    getMailers,
    getMailersFailed,

    getConversations,
    updateConversation,
    updateTeamPhoneNumber,
    getTeamPhoneNumbers,

    getCalendar,
    getDashboardSettings,
    updateDashboard,
    getMultiSendNumbers,
    getSavedResponses,
    updateSavedResponse,

    analytics,
    getBulkEdits,
    getDemos,
    updateDemo,
    getMailFailedDeals,
    getSendingQueue,
    getMailerCampaigns,
    updateMailerCampaigns,
    getMailerTemplates,
    updateMailerTemplate,
    getMailerSignatures,
    updateMailerSignature,
    getMailerDesignTemplates,

    getSalesPagesBuyerMessages,
    getSalesPagesInventory,
    getSalesPageByID,
    publishSalesPage,
    createSalesPage,
    updateSalesPage,
    getPublicSalesPage,
    sendSalesPageMessageAsBuyer,

    saveTask,
    loadListTask,
    getTask,
    completeTask,
    editTask,
    getTaskCount,

    getCustomFields,
    updateCustomFields,
    getLeadStatuses,
    updateLeadStatus,
    getWorkflows,
    updateWorkflow,
    getPropertyImages,
    updatePropertyImage,
    updatePaymentMethodsOrder,
    deleteCard,
    makePrimaryCard,
    getApiInfo,
    updateApiInfo,

    getSavedFilters,
    updateSavedFilters,

    exportLeads,
    getExportActualCount,
    estimatedListCount,
    getListBuilderTemplates,
    getRecentPrompts,
    getCurrentAIBuildingList,
    getListsOnMap,
    buildList,
    buildListv2,
    getLeadsOnMapForListBuilder,
    getHighlightsOnMapForListBuilder,
    searchDealMachine,
    updateStackedLists,

    getPropertiesOnMap,
    getRoutesOnMap,
    getHighlightTemplates,
    restoreCurrentRoute,
    getRecentSearch,
    saveRecentSearch,

    getLeadActivity,
    getComps,
    getTeamReferralInfo,

    getLeadUpdates,
    getMLSImages,

    getChatSystemMessages,
    getChatPrompts,
    getAlmaChat,
    updateAlmaChat,

    getPropertiesFromPrompt,
    getFormattedDataForOpenAI,
    getContacts,
    getLinkedCompanies,
    getCompanies,
    updateContacts,
    getCompanyPeople,
    getTwilioToken,
    getTelnyxToken,
    callControl,
    phoneCall,
    getPhoneCall,
    dialerConversationCleanUp,
    getCleanAIData,
    dialerConversationPredict,
    dialerConversationSummarize,
    getQueuedNumbers,
    createCallSession,
    getCallSession,
    updateCallSession,
    getDialerStats,
    getVoiceClone,
    updateVoiceClone,
    getCallTrackingNumbers,
    updateCallTrackingNumbers,
    getDashboardStats,
    updateNewDashboard,
    getCashBuyers,
    getCorrectSpecial,
    getUpsellButton,

    getWorkspaceTeams,
    generateWorkspaceTeamName,
    addWorkspaceTeam,
    editWorkspaceTeam,
    getWorkspaceSettings,
    editWorkspaceSettings,
    resendWorkspaceTeamInvitation,
    accessWorkspaceTeam,
    getWorkspaceTeamLicenses,
    editWorkspaceTeamLicenses
  };
};

export default {
  create
};
