import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import { Spinner, Row } from "app/NativeComponents/common";

import Dashboard from "app/DealMachineCore/components/Dashboard";
import MailCenter from "app/DealMachineCore/components/Mail/MailCenter";
import PostcardDesigner from "app/DealMachineCore/components/Mail/PostcardDesigner";
import MailSequences from "app/DealMachineCore/components/Mail/MailSequences";

import MainMap from "app/DealMachineCore/components/MainMap";
import Workflows from "app/DealMachineCore/components/Workflows";
import Connect from "app/DealMachineCore/components/Connect";
import Dialer from "app/DealMachineCore/components/Dialer";

import Leads from "app/DealMachineCore/components/Leads";
import RedirectToApp from "app/NativeComponents/components/RedirectToApp";

import TeamInvite from "app/Workspaces/components/WorkspaceOnboarding/TeamInvite";

import WorkspacesDashboard from "app/Workspaces/components/WorkspacesDashboard";
import WorkspaceSettings from "app/Workspaces/components/WorkspaceSettings";
import WorkspaceDialer from "app/Workspaces/components/WorkspaceDialer";
import WorkspaceMail from "app/Workspaces/components/WorkspaceMail";
class AppRoutes extends Component {
  render() {
    if (
      this.props.user?.team_type === "workspace" &&
      !this.props.active_workspace_team
    ) {
      return (
        <>
          <Route exact path={"/"} component={WorkspacesDashboard} />
          <Route exact path={"/dashboard"} component={WorkspacesDashboard} />

          <Route exact path={"/login"} component={RedirectToApp} />
          <Route exact path={"/sign-up"} component={RedirectToApp} />
          <Route exact path={"/forgot-password"} component={RedirectToApp} />
          <Route path={"/map"} component={RedirectToApp} />
          <Route path={"/account-settings"} component={WorkspaceSettings} />
          <Route path={"/dialer"} component={WorkspaceDialer} />
          <Route path={"/mail-center"} component={WorkspaceMail} />
          <Route path={"/team-invite"} component={TeamInvite} />
        </>
      );
    }

    return (
      <>
        {/* Deal Routes */}
        <Route exact path={"/"} component={MainMap} />
        <Route exact path={"/login"} component={RedirectToApp} />
        <Route exact path={"/sign-up"} component={RedirectToApp} />
        <Route exact path={"/forgot-password"} component={RedirectToApp} />
        <Route path={"/team-invite"} component={TeamInvite} />

        <Route path={"/map"} component={MainMap} />
        <Route path={"/a/map"} component={MainMap} />
        <Route path={"drive"} component={MainMap} />
        <Route path={"/a/drive"} component={MainMap} />

        <Route path={"/dashboard"} component={Dashboard} />
        <Route path={"/a/dashboard"} component={Dashboard} />

        {/* leads beta feature flag goes here */}
        <Route path={"/leads"} component={Leads} />
        <Route path={"/a/leads"} component={Leads} />

        {/* Mail */}
        <Route path={"/mail-center"} component={MailCenter} />
        <Route path={"/a/mail-center"} component={MailCenter} />
        <Route path={"/postcards"} component={PostcardDesigner} />
        <Route path={"/a/postcards"} component={PostcardDesigner} />
        <Route path={"/mail-sequences"} component={MailSequences} />
        <Route path={"/a/mail-sequences"} component={MailSequences} />

        <Route path={"/connect"} component={Connect} />
        <Route path={"/a/connect"} component={Connect} />

        <Route path={"/dialer"} component={Dialer} />
        <Route path={"/a/dialer"} component={Dialer} />

        {/* Workflows*/}
        <Route path={"/workflows"} component={Workflows} />
      </>
    );
  }
}

const mapStateToProps = ({ auth, feature_toggle, workspaces }) => {
  const { init, user, token } = auth;
  const { mapBeta } = feature_toggle;
  const { active_workspace_team } = workspaces;
  return { init, user, token, mapBeta, active_workspace_team };
};

export default withRouter(connect(mapStateToProps, {})(AppRoutes));
