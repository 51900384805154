import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  GhostButton,
  SelectItem,
  InlineButton
} from "app/NativeComponents/snippets";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import {
  pushSidePanel,
  popSidePanel,
  changeTab,
  displayIntercom,
  openUrl,
  triggerIntercom
} from "app/NativeActions";

class HelpCenter extends Component {
  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("help");
    }
  }

  render() {
    const { device, colors, team_link, user, triggerIntercom } = this.props;
    return (
      <Container
        title={"Help Center"}
        page_slug={"help_center"}
        page_type={"slide_panel"}
      >
        {device == "desktop" ? (
          <NewHeader
            title={"Help & Support"}
            leftButton={{
              icon: "close",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
        ) : (
          <MainHeader title={"Help & Support"} />
        )}
        <KeyboardView style={{ flex: 1 }}>
          <Wrapper style={{ padding: 25 }}>
            <Wrapper style={{ paddingBottom: 25 }}>
              <Copy>
                We have many ways to assist you at DealMachine, including
                world-class live support and comprehensive training videos.
                That's why we boast a rating of 4.8 out of 5 stars.
              </Copy>
            </Wrapper>
            <Wrapper style={{ alignItems: "center" }}>
              <GhostButton
                id={"intercom_button"}
                onPress={() => {
                  displayIntercom();
                }}
                button_type={"full"}
                icon="question-answer"
                primary={true}
              >
                Open Live Support
              </GhostButton>
            </Wrapper>
          </Wrapper>
          <SelectItem
            selected={true}
            select_type="none"
            icon="open-in-new"
            onPress={() => {
              openUrl("https://www.youtube.com/c/DealMachine/playlists");
            }}
          >
            DealMachine Tutorials & REI Content
          </SelectItem>
          <SelectItem
            selected={true}
            select_type="none"
            icon="open-in-new"
            onPress={() => {
              openUrl("https://updates.dealmachine.com");
            }}
          >
            Release Notes
          </SelectItem>
          <SelectItem
            selected={true}
            select_type="none"
            icon="open-in-new"
            onPress={() => {
              openUrl("https://dealmachine.featureupvote.com");
            }}
          >
            Request a Feature
          </SelectItem>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ settings, native, auth, team_link }) => {
  const { user_dealfinder_page } = team_link;
  const { user } = auth;
  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    colors,
    device,
    isMobile,
    platform,
    user,
    team_link
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  popSidePanel,
  changeTab,
  triggerIntercom
})(HelpCenter);
