import React, { Component } from "react";
import { connect } from "react-redux";
import NavigationService from "app/Router/NavigationService";
import { popSidePanel } from "app/NativeActions";
import {
  Container,
  KeyboardView,
  Wrapper,
  Title
} from "app/NativeComponents/common";
import { InlineTabs, NewHeader } from "app/NativeComponents/snippets";

import Body from "./Body";

class AccountActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_by: "all",
      selected_tab: "all"
    };
  }
  componentDidMount() {
    if (this.props.device == "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }
  }

  renderBody() {
    return (
      <Container style={{ flex: 1 }}>
        <NewHeader
          title="Notifications"
          leftButton={{
            icon: this.props.isMobile ? "arrow-back" : "close",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />
        <Wrapper
          style={{
            margin: 10,
            marginBottom: 0
          }}
        >
          <InlineTabs
            tab_type="underline"
            selected_tab={this.state.selected_tab}
            tabs={[
              {
                onPress: () => {
                  this.setState({
                    selected_tab: "all",
                    filter_by: "all"
                  });
                },
                title: "All",
                slug: "all",
                disabled: this.props.team_activity_loading
              },
              {
                onPress: () => {
                  this.setState({
                    selected_tab: "updates",
                    filter_by: "updates"
                  });
                },
                title: "Updates",
                slug: "updates",
                disabled: this.props.team_activity_loading
              },
              {
                onPress: () => {
                  this.setState({
                    selected_tab: "activity",
                    filter_by: "activity"
                  });
                },
                title: "Activity",
                slug: "activity",
                disabled: this.props.team_activity_loading
              }
            ]}
          />
        </Wrapper>
        <KeyboardView style={{ height: "100%" }}>
          {this.props?.user?.can_see_dashboard == 1 ? (
            <Body filter_by={this.state.filter_by} />
          ) : (
            <Wrapper
              style={{
                alignSelf: "center",
                justifyContent: "center",
                padding: 20
              }}
            >
              <Title
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  padding: 20
                }}
              >
                You do not have permission to view account notifications.
              </Title>
            </Wrapper>
          )}
        </KeyboardView>
      </Container>
    );
  }
  render() {
    return this.renderBody();
  }
}

const mapStateToProps = ({ auth, settings, native, activity }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { show_team_activity_modal, team_activity_loading } = activity;
  return {
    token,
    user,
    isMobile,
    colors,
    device,
    show_team_activity_modal,
    team_activity_loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel
})(AccountActivity);
